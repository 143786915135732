import axios from "axios";
import Cookies from "universal-cookie";

export default class ClientApi {
  url = "https://nas.nantesbikesolutions.com:8082/";
  urlBackUp = "https://failover.nantesbikesolutions.com:8082/";
  //url = "http://localhost:8092/"
  //urlBackUp = "http://localhost:8092/"
  cookies = new Cookies()
  token = this.cookies?.get("token-billing-nbs");

  login(user, secret) {
    const request = {
      login: user,
      password: secret,
    }

    return this.postAxiosWithBodyNoHeaders("admin/authenticate/", request)
  }

  async validateUrl() {
    var responseMainURL
    var responseBackupURL
    
    try {
      responseMainURL = await axios.get(this.url.concat("availability/"))
      if (responseMainURL !== undefined && responseMainURL.status === 200) {
        this.cookies.set("token-url-nbs", this.url);
      }
    }
    catch(error) {
      try {
        responseBackupURL = await axios.get(this.urlBackUp.concat("availability/"))
        if (responseBackupURL !== undefined && responseBackupURL.status === 200) {
          this.cookies.set("token-url-nbs", this.urlBackUp);
        }
      }
      catch(error) {}
    }
    
    
    if (responseMainURL === undefined && responseBackupURL === undefined) {
      alert("Pas de serveur de dispo")
    }
  }

  async postAxiosNoBody(url) {
    return await this.postAxiosWithBody(url, {})
  }

  async postAxiosWithBody(url, body) {

    return await axios.post(
      this.cookies.get("token-url-nbs").concat(url),
      body,
      {
        headers: { Authorization: "Basic ".concat(this.token) }
      }
    )

  }

  async postAxiosWithBodyNoHeaders(url, body) {

    return await axios.post(
      this.cookies.get("token-url-nbs").concat(url),
      body
    )
  }

  async getAxios(url) {

    return await axios.get(this.cookies.get("token-url-nbs").concat(url),
      {
        headers: { Authorization: "Basic ".concat(this.token) }
      })
  }

  async getAxiosNoHeaders(url) {
    return await axios.get(this.cookies.get("token-url-nbs").concat(url))
  }
}
