import { Component } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import ClientCustomerApi from "./swagger/ClientCustomerApi";

export default class CustomerRequest extends Component {

    clientApi = new ClientCustomerApi();

    state = {
        temporaryCode: "",
        customer: {},
        bike: {},
        bikeDysfunction: "",
        dropInfo: ""
    };

    componentDidMount() {
        this.setState({
            customer: this.props.customer,
            bike: this.props.bikes.length > 0 ? this.props.bikes[0].bike : {}
        });
    }

    handleSelectBike = (event) => {
        var aBike = {
            id: "",
            gender: "",
            brand: "",
            model: "",
            type: "",
            practice: "",
            color: "",
            ebike: false,
        }

        if (event.target.value !== "") {
            this.state.customer.bikes.forEach(bike => {
                if (parseInt(event.target.value) === bike.id) {
                    aBike = bike
                }
            })
        }

        this.setState({
            bike: aBike
        })
    }


    handleDropInfo = (event) => {
        this.setState({ dropInfo: event.target.value })
    }

    handleChangeGender = (event) => {
        let customer = this.state.customer;
        customer.genre = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeName = (event) => {
        let customer = this.state.customer;
        customer.name = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeSurname = (event) => {
        let customer = this.state.customer;
        customer.surname = event.target.surname;
        this.setState({ customer: customer });
    }

    handleChangeAddress = (event) => {
        let customer = this.state.customer;
        customer.addressFirstLine = event.target.addressFirstLine;
        this.setState({ customer: customer });
    }

    handleChangeZipCode = (event) => {
        let customer = this.state.customer;
        customer.zipCode = event.target.zipCode;
        this.setState({ customer: customer });
    }

    handleChangeCity = (event) => {
        let customer = this.state.customer;
        customer.city = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeMail = (event) => {
        let customer = this.state.customer;
        customer.mail = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangePhoneNumber = (event) => {
        let customer = this.state.customer;
        customer.phoneNumber = event.target.value;
        this.setState({ customer: customer });
    }

    // BIKE
    handleChangeBikeGender = (event) => {
        let bike = this.state.bike;
        bike.gender = event.target.value;
        this.setState({ bike: bike });
    }

    handleChangeBikeEBike = (event) => {
        let bike = this.state.bike;
        bike.ebike = event.target.value;
        this.setState({ bike: bike });
    }

    handleChangeBikeBrand = (event) => {
        let bike = this.state.bike;
        bike.brand = event.target.value;
        this.setState({ bike: bike });
    }

    handleChangeBikePractice = (event) => {
        let bike = this.state.bike;
        bike.practice = event.target.value;
        this.setState({ bike: bike });
    }

    handleChangeBikeModele = (event) => {
        let bike = this.state.bike;
        bike.model = event.target.value;
        this.setState({ bike: bike });
    }

    handleChangeBikeColor = (event) => {
        let bike = this.state.bike;
        bike.color = event.target.value;
        this.setState({ bike: bike });
    }

    // Dysfunction
    handleChangeDysfunction = (event) => {
        this.setState({
            bikeDysfunction: event.target.value,
        })
    }

    submitRequest = (event) => {
        event.preventDefault()
        this.clientApi.submitPrefilledForm(this.state)
            .then(res => {

            })
            .catch(error => {
                alert("Erreur lors du traitement : " + error.message)
            })

    }

    render() {
        var selectBikeOptions = []

        if (this.state.customer.bikes !== undefined) {
            this.state.customer.bikes.forEach(bike => {

                selectBikeOptions.push(<option
                    value={bike.id}
                    selected={this.state.customer.bikes.length === 1}>
                    {bike.brand} {bike.model} {bike.gender} {bike.practice} {bike.color} {JSON.parse(bike.ebike) ? "Electrique" : ""}
                </option>)
            })
        }

        selectBikeOptions.push(<option defaultValue value="">- Nouveau Vélo -</option>)

        return (
            <Container fluid="lg">

                <Form onSubmit={this.submitRequest}>
                    <Card>
                        <Card.Header>
                            Informations personnelles
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg>
                                    <Card.Title>Civilité</Card.Title>
                                    <Row>
                                        <Col className="small-margin-top-bottom">
                                            <Form.Control as="select"
                                                className="form-select"
                                                required
                                                value={this.state.customer.genre}
                                                onChange={this.handleChangeGender} >
                                                <option value="1">Monsieur</option>
                                                <option value="2">Madame</option>
                                            </Form.Control>
                                        </Col>
                                        <Col className="small-margin-top-bottom">
                                            <Form.Control as="input"
                                                placeholder="prénom"
                                                required
                                                value={this.state.customer.name}
                                                onChange={this.handleChangeName} />
                                        </Col>
                                        <Col className="small-margin-top-bottom">
                                            <Form.Control as="input"
                                                placeholder="nom"
                                                required
                                                value={this.state.customer.surname}
                                                onChange={this.handleChangeSurname} />
                                        </Col>
                                    </Row>
                                    <Card.Title>Adresse</Card.Title>
                                    <Row>
                                        <Col className="mb-1">
                                            <Form.Control as="input"
                                                placeholder="numéro, rue"
                                                required
                                                value={this.state.customer.addressFirstLine}
                                                onChange={this.handleChangeAddress} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-1">
                                            <Form.Control as="input"
                                                placeholder="code postal"
                                                required
                                                value={this.state.customer.zipCode}
                                                onChange={this.handleChangeZipCode} />
                                        </Col>
                                        <Col className="mb-1">
                                            <Form.Control as="input"
                                                placeholder="ville"
                                                required
                                                value={this.state.customer.city}
                                                onChange={this.handleChangeCity} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg >
                                    <Card.Title>Contact</Card.Title>
                                    <Form.Control as="input"
                                        placeholder="e-mail"
                                        className="mb-1"
                                        required
                                        value={this.state.customer.mail}
                                        onChange={this.handleChangeMail} />
                                    <Form.Control as="input"
                                        placeholder="numéro de téléphone"
                                        className="mb-1"
                                        required
                                        value={this.state.customer.phoneNumber}
                                        onChange={this.handleChangePhoneNumber} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            Informations sur le vélo
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>Sélectionner le vélo que vous voulez réparer</Card.Title>
                            <Form.Control as="select"
                                className="form-select"
                                value={this.state.idBike}
                                onChange={this.handleSelectBike}>
                                {selectBikeOptions}
                            </Form.Control>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                                <Row>
                                    <Col lg>
                                        <Card.Title>Quand déposerais-je mon vélo ?</Card.Title>
                                        <Form.Control as="select"
                                            className="form-select"
                                            required
                                            value={this.state.dropInfo}
                                            onChange={this.handleDropInfo}>

                                            <option defaultValue value="">
                                                - Sélectionner -
                                            </option>
                                            <option value="le vélo est déjà à l'atelier">le vélo est déjà à l&apos;atelier</option>
                                            <option value="je dépose le vélo aujourd'hui">je dépose le vélo aujourd&apos;hui</option>
                                            <option value="je dépose le vélo demain">je dépose le vélo demain</option>
                                            <option value="je dépose le vélo dans la semaine">je dépose le vélo dans la semaine</option>
                                        </Form.Control>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Card.Title>Caractéristiques</Card.Title>
                                <Row>
                                    <Col lg>
                                        <Form.Label>Éléctrique ?</Form.Label>
                                        <Form.Control as="select"
                                            className="form-select" required
                                            value={this.state.bike?.ebike}
                                            onChange={this.handleChangeBikeEBike}>
                                            <option value={false}>Non</option>
                                            <option value={true}>Oui</option>
                                        </Form.Control>
                                    </Col>
                                    <Col lg>
                                        <Form.Label>Genre</Form.Label>
                                        <Form.Control as="select"
                                            className="form-select"
                                            required
                                            value={this.state.bike?.gender}
                                            onChange={this.handleChangeBikeGender} >
                                            <option defaultValue value="">- Selectionner -</option>
                                            <option value="Homme">Homme</option>
                                            <option value="Femme">Femme</option>
                                            <option value="Enfant">Enfant</option>
                                            <option value="Mixte">Mixte</option>
                                        </Form.Control>
                                    </Col>
                                    <Col lg>
                                        <Form.Label>Pratique du vélo</Form.Label>
                                        <Form.Control as="select"
                                            className="form-select"
                                            required
                                            value={this.state.bike?.practice}
                                            onChange={this.handleChangeBikePractice}>
                                            <option defaultValue value="">- Selectionner -</option>
                                            <option value="Ville">Ville</option>
                                            <option value="VTC">V.T.C.</option>
                                            <option value="VTT">V.T.T.</option>
                                            <option value="Route">Route</option>
                                            <option value="BMX">B.M.X.</option>
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg>
                                        <Form.Label>Marque</Form.Label>
                                        <Form.Control
                                            type="input"
                                            required
                                            value={this.state.bike?.brand}
                                            onChange={this.handleChangeBikeBrand} />
                                        (Indiquer &quot;inconnu&quot; si rien n&apos;apparaît)
                                    </Col>
                                    <Col lg>
                                        <Form.Label>Modèle</Form.Label>
                                        <Form.Control
                                            type="input"
                                            required
                                            value={this.state.bike?.model}
                                            onChange={this.handleChangeBikeModele} />
                                        (Indiquer &quot;inconnu&quot; si rien n&apos;apparaît)
                                    </Col>
                                    <Col lg>
                                        <Form.Label>Couleur(s)</Form.Label>
                                        <Form.Control type="input"
                                            required
                                            value={this.state.bike?.color}
                                            onChange={this.handleChangeBikeColor} />
                                    </Col>
                                </Row>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Card.Title>Dysfonctionnements constatés</Card.Title>
                                <Form.Control as="textarea" required
                                    value={this.state.bikeDysfunction}
                                    onChange={this.handleChangeDysfunction}></Form.Control>
                            </ListGroup.Item>
                        </ListGroup>

                    </Card>

                    <Card>
                        <Card.Body>
                            <div className="float-end">
                                <Button type="submit" >Envoyer la demande</Button>
                            </div>
                        </Card.Body>
                    </Card>

                </Form>
            </Container >
        );
    }
}