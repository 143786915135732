export default class DateUtils {

  toMonthYearDate(dateToMap) {
    return dateToMap !== undefined && dateToMap !== null ? new Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "2-digit"
    }).format(new Date(dateToMap)) : ""
  }

  toReadableDate(dateToMap) {
    return dateToMap !== undefined && dateToMap !== null ? new Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }).format(new Date(dateToMap)) : ""
  }

  isDateDelayBetweenMinAndMax(date, delayMin, delayMax) {
    return Date.now() - delayMin * 24 * 3600 * 1000 >= new Date(date).getTime()
      && Date.now() - delayMax * 24 * 3600 * 1000 <= new Date(date).getTime()
  }
}