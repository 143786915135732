import { Component } from "react";
import { Card, Col, Container, Modal, Row, Form, Button, Table, InputGroup, Alert } from "react-bootstrap";
import BikeSelector from "./BikeSelector";
import Picture from "./Picture";
import Products from "./Products";
import BikingGif from "../images/biking.gif";
import trashsvg from "../images/trash-fill.svg";
import BackButton from "./utils/BackButton";
import Cookies from "universal-cookie";
import ClientBillingApi from "./swagger/ClientBillingApi";

export default class BillEdition extends Component {
    mechanicIdCookie = new Cookies().get("mechanic");

    swagger = new ClientBillingApi()

    componentDidMount() {
        this.swagger.retrieveMechanic(this.mechanicIdCookie)
            .then(res => {
                this.setState({
                    mechanic: res.data
                })
            })
            .catch(error => alert(error))

        this.swagger.getFolderById(this.props.match.params.fileId)
            .then(res => {

                var isDisabled = res.data.bill.sendDate !== null

                this.setState({
                    bill: res.data.bill,
                    duration: res.data.prod?.duration,
                    difficulties: res.data.prod?.difficulty,
                    fileNumber: this.props.match.params.fileId,
                    isDisabled: isDisabled
                })

                this.swagger.retrieveMechanics()
                    .then(res => {

                        res.data.forEach(mec => {
                            if (mec.id === parseInt(this.mechanicIdCookie)) {
                                var bill = this.state?.bill
                                bill.mechanic = mec
                                this.setState({ bill: bill })
                            }
                        })

                        this.setState({ mechanics: res.data })
                    })
                    .catch(error => alert(error))
            })
            .catch(error => alert(error))


    }

    handleChangeValue = (value, name) => {
        if (this.state.value !== value || this.state.name !== name) {
            this.setState({ value: value, familyName: name })
        }
    }

    handleSelectProduct = (productId, name, price, labourPrice, familyId, familyName) => {
        var bill = this.state?.bill

        if (bill === undefined || bill === null) {
            bill = {
                piece: 0,
                total: 0,
                labour: 0,
            }
        }

        if (bill.lines === undefined || bill.lines === null) {
            bill.lines = []
        }

        bill.lines.push({
            name: name,
            price: price,
            familyId: familyId,
            familyName: familyName,
            labourPrice: labourPrice,
        })

        bill.lines.sort(function (a, b) {
            return a.familyName.localeCompare(b.familyName)
        })

        this.calculTotals(bill)

        this.setState({
            productId: productId,
            bill: bill,
        })
    }

    calculTotals(bill) {
        bill.total = 0
        bill.piece = 0
        bill.labour = 0

        bill.lines.forEach(element => {
            bill.total += element.price + element.labourPrice;
            bill.piece += element.price;
            bill.labour += element.labourPrice;
        });
    }

    deleteValue = (index) => {
        var bill = this.state?.bill
        bill.lines.splice(index, 1)
        this.calculTotals(bill)
        this.setState({ bill: bill })
    }

    handleProdDuration = (event) => {
        var duration = this.returnNumberOrUndefined(event.target.value)
        this.setState({ duration: duration })
    }

    handleProdDifficulties = (event) => {
        this.setState({ difficulties: event.target.value })
    }

    handleMechanic = (event) => {
        this.state?.mechanics.forEach(mec => {
            if (mec.id === parseInt(event.target.value)) {
                var bill = this.state?.bill
                bill.mechanic = mec
                this.setState({ bill: bill })
            }
        })
    }

    handleTitle = (event) => {
        var bill = this.state?.bill
        bill.headerTitle = event.target.value;
        this.setState({ bill: bill })
    }

    handleContact = (event) => {
        var bill = this.state?.bill
        bill.headerContact = event.target.value;
        this.setState({ bill: bill })
    }
    handleAdress = (event) => {
        var bill = this.state?.bill
        bill.headerAdresse = event.target.value;
        this.setState({ bill: bill })
    }
    handleCity = (event) => {
        var bill = this.state?.bill
        bill.headerZipCodeCity = event.target.value;
        this.setState({ bill: bill })
    }

    handlePhone = (event) => {
        var bill = this.state?.bill
        bill.headerPhone = event.target.value;
        this.setState({ bill: bill })
    }

    handleMail = (event) => {
        var bill = this.state?.bill
        bill.headerMail = event.target.value;
        this.setState({ bill: bill })
    }

    handleUpdateName = (event, index) => {
        var bill = this.state?.bill;
        bill.lines[index].name = event.target.value
        this.setState({ bill: bill })
    }

    handleUpdatePrice = (event, index) => {
        var bill = this.state?.bill
        bill.lines[index].price = parseFloat(event.target.value)
        this.calculTotals(bill)
        this.setState({ bill: bill })
    }

    handleUpdateLabor = (event, index) => {
        var bill = this.state?.bill
        bill.lines[index].labourPrice = parseFloat(event.target.value)
        this.calculTotals(bill)
        this.setState({ bill: bill })
    }

    returnNumberOrUndefined(number) {
        if (number === "") {
            return undefined
        }
        else {
            return parseFloat(number)
        }
    }

    saveBill = (event) => {
        event.preventDefault()

        if (this.state?.bill?.lines?.length <= 0) {
            alert("Aucune ligne de créer")
        }
        else {
            this.setState({
                showSaveScreenModal: true,
            })
            this.swagger.saveTheBill(this.state.bill, this.state.duration, this.state.difficulties)
                .then(res => this.setState({
                    bill: res.data,
                    showSaveScreenModal: false
                }))
                .catch(error => this.setState({
                    resultSendBill: <Alert variant="danger">{error.message}</Alert>
                }))
        }
    }

    renderBillLine() {
        if (this.state?.bill?.lines?.length > 0) {
            var familyName = ""
            return this.state?.bill?.lines?.map((it, index) => (
                <>
                    {familyName !== it.familyName ?
                        <tr className="table-secondary">
                            <td colSpan="4">
                                <h4>{(familyName = it.familyName).replace("Z-", "")}</h4>
                            </td>
                        </tr> : ""}
                    <tr className={parseFloat(it.price + it.labourPrice) < 0 ? "table-success" : ""}>
                        <td colSpan="3">
                            <Row>
                                <Col lg={8} >
                                    <InputGroup className="mb-1" >
                                        <Button
                                            disabled={this.state?.isDisabled}
                                            type="button"
                                            variant="danger"
                                            onClick={() => this.deleteValue(index)}  >
                                            <img src={trashsvg} alt="supp" />
                                        </Button>
                                        <Form.Control
                                            disabled={this.state?.isDisabled}
                                            value={it?.name}
                                            as="textarea"
                                            onChange={(event) => this.handleUpdateName(event, index)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col style={{ paddingLeft: "0em", paddingRight: "0.3em" }}>
                                    <InputGroup >
                                        <Button disabled={this.state?.isDisabled} variant="info" style={{ width: "50%" }} value={it?.labourPrice - 5} onClick={(event) => this.handleUpdateLabor(event, index)}>-5</Button>
                                        <Button disabled={this.state?.isDisabled} variant="info" style={{ width: "50%" }} value={it?.labourPrice + 5} onClick={(event) => this.handleUpdateLabor(event, index)}>+5</Button>
                                    </InputGroup>
                                    <Form.Control disabled={this.state?.isDisabled} className="text-align-right"
                                        value={parseFloat(it?.labourPrice)}
                                        onChange={(event) => this.handleUpdateLabor(event, index)}
                                    />
                                </Col>
                                <Col style={{ paddingLeft: "0em", paddingRight: "0.3em" }}>
                                    <InputGroup >
                                        <Button disabled={this.state?.isDisabled} variant="info" style={{ width: "50%" }} value={it?.price - 5} onClick={(event) => this.handleUpdatePrice(event, index)}>-5</Button>
                                        <Button disabled={this.state?.isDisabled} variant="info" style={{ width: "50%" }} value={it?.price + 5} onClick={(event) => this.handleUpdatePrice(event, index)}>+5</Button>
                                    </InputGroup>
                                    <Form.Control disabled={this.state?.isDisabled} className="text-align-right"
                                        value={parseFloat(it?.price)}
                                        onChange={(event) => this.handleUpdatePrice(event, index)}
                                    />
                                </Col>
                            </Row>
                        </td>
                    </tr>
                </>
            ))
        }
        else {
            return <tr>
                <td colSpan={3}>
                    <Alert variant="warning">
                        <Alert.Heading>
                            Aucune ligne présente pour ce devis
                        </Alert.Heading>
                        <p>
                            Selectionnez via le module de recherche de pièces et main d'oeuvre pour ajouter une ligne
                        </p>
                    </Alert>
                </td>
            </tr>
        }
    }

    render() {
        return <Container fluid>
            <Row >
                <Col md={5}>
                    <Row style={{
                        position: "fixed", width: "40%"
                    }}>
                        <Col className="small-margin" md={2}>
                            <BackButton
                                url={"/dossier/".concat(this.state?.fileNumber)}
                                name={"Retour au dossier ".concat(this.state?.fileNumber)}
                            />
                        </Col>
                        <Col >
                            <h1>Edition Facture</h1>
                        </Col>
                    </Row>
                    <Card style={{
                        position: "fixed",
                        width: "40%",
                        height: "80%",
                        overflowY: "scroll",
                        marginTop: "4em",
                        visibility: this.state?.isDisabled ? "hidden" : ""
                    }}>
                        <Card.Header>
                            Recherche pièces et main d 'oeuvres
                        </Card.Header>
                        <Card.Body className="no-padding">
                            <BikeSelector
                                value={this.state?.value}
                                onChangeValue={this.handleChangeValue} />
                            <Products
                                idFamily={this.state?.value}
                                familyName={this.state?.familyName}
                                onChangeValue={this.handleSelectProduct} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col md={4}>
                                    Facture en cours
                                </Col>
                                <Col md={8}>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            Par
                                        </InputGroup.Text>
                                        <Form.Select
                                            disabled={this.state?.isDisabled}
                                            onChange={this.handleMechanic}
                                            value={this.state?.bill?.mechanic?.id}>
                                            <option>-- mécaniciens --</option>
                                            {this.state?.mechanics?.map(mecha => (
                                                <option value={mecha.id} disabled={!mecha.active}>{mecha.name} {mecha.surname}</option>
                                            ))}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-3">
                                <Col >
                                    <h2>Informations client</h2>
                                    <div>
                                        <Form.Control value={this.state?.bill?.headerContact} onChange={this.handleContact} />
                                    </div>
                                    <div>
                                        <Form.Control value={this.state?.bill?.headerAdresse} onChange={this.handleAdress} />
                                    </div>
                                    <div>
                                        <Form.Control value={this.state?.bill?.headerZipCodeCity} onChange={this.handleCity} />
                                    </div>
                                    <br />
                                    <div>
                                        <Form.Control value={this.state?.bill?.headerPhone} onChange={this.handlePhone} />
                                    </div>
                                    <div>
                                        <Form.Control value={this.state?.bill?.headerMail} onChange={this.handleMail} />
                                    </div>
                                </Col>
                                <Col>
                                    <h2>Informations NBS</h2>
                                    <div>
                                        Nantes Bike Solutions
                                    </div>
                                    <div>
                                        9 rue du maréchal Joffre
                                    </div>
                                    <div>44000 Nantes</div>
                                    <br />
                                    <div>
                                        nantesbike@gmail.com
                                    </div>
                                    <div>
                                        02-28-30-72-29
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h2>Titre</h2>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <InputGroup>
                                        <Button type="button" variant="info" size="sm"
                                            onClick={() => this.setState({ showPicture: true })}>photo vélo</Button>{" "}
                                        <Form.Control value={this.state?.bill?.headerTitle} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h2>Détails de la Facture</h2>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        Libellé produit ou prestation
                                    </th>
                                    <th className="text-align-right">
                                        MO
                                    </th>
                                    <th className="text-align-right">
                                        Pièces
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderBillLine()}
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>
                                        Totaux
                                    </td>
                                    <td className="text-align-right">
                                        <b>{this.state?.bill?.labour}</b>
                                    </td>
                                    <td className="text-align-right">
                                        <b>{this.state?.bill?.piece}</b>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={2}>
                                        <b>Somme à payer</b>
                                    </td>
                                    <td className="text-align-right">
                                        <b>{this.state?.bill?.total}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <div className="float-end">
                                            <Button disabled={this.state?.isDisabled} type="button" onClick={() => this.handleSelectProduct(undefined, "", 10.0, 0.00, -1, "Z-Autres")}>Ajouter ligne vide</Button>{" "}
                                            <Button disabled={this.state?.isDisabled} type="button" variant="success" onClick={() => this.handleSelectProduct(undefined, "Remise sur prestation", -10.0, 0.00, -2, "Z-Remise")} >Ajouter ligne réduction</Button>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                        <Card.Footer>
                            <Form onSubmit={this.saveBill}>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label>Temps passé sur le vélo <i>(Obligatoire)</i></Form.Label>
                                        <Form.Control
                                            disabled={this.state?.isDisabled}
                                            value={this.state?.duration}
                                            onChange={this.handleProdDuration}
                                            required />
                                        <Form.Label>Difficultées rencontrées</Form.Label>
                                        <Form.Control
                                            disabled={this.state?.isDisabled}
                                            value={this.state?.difficulties}
                                            as="textarea"
                                            onChange={this.handleProdDifficulties} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button type="Submit" className="float-end" variant="success">Enregistrer</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Modal size="lg" show={this.state?.showPicture} onHide={() => this.setState({ showPicture: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Photo du vélo pour le dossier {this.state?.fileNumber}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Picture folderId={this.state?.fileNumber} />
                </Modal.Body>
            </Modal>

            <Modal show={this.state?.showSaveScreenModal}>
                <Modal.Header>
                    Enregistrement en cours
                </Modal.Header>
                <Modal.Body>
                    <img src={BikingGif} alt="Enregistrement en cours..." />
                </Modal.Body>
            </Modal>

        </Container >

    }
}