import { Component } from "react"
import { Table } from "react-bootstrap";

export default class ProductsFullList extends Component {

    state = {
        families: []
    }

    constructor(props) {
        super(props)
        this.state = { families: props.families }
    }

    render() {
        return <Table>
            <thead>
                <tr>
                    <td>Nom du produit</td>
                    <td className="text-align-right">MO</td>
                    <td className="text-align-right">Piece</td>
                </tr>
            </thead>
            <tbody>
            {
                this.state.families?.map(family => (
                    <>
                        <tr>
                            <td colSpan={3} className="bg-info">
                                <b>{family.name}</b>
                            </td>
                        </tr>
                        {family.products?.map(product => (
                            <tr>
                                <td>
                                    {product.name}
                                </td>
                                <td className="text-align-right">
                                    {product.labourPriceSold}
                                </td>
                                <td className="text-align-right">
                                    {product.taxedSoldPrice}
                                </td>
                            </tr>
                        ))}
                    </>
                ))
            }
            </tbody>
        </Table>
    }
}