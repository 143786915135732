import { Component } from "react";
import { Button, Card, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap";
import Mechanic from "./Mechanic";
import ClientBillingApi from "./swagger/ClientBillingApi";

export default class Mechanics extends Component {

    swagger = new ClientBillingApi()

    componentDidMount() {
        this.swagger.retrieveMechanics()
            .then(res => {
                this.setState({ mechanics: res.data, newMechanic: {active : true} })
            })
            .catch(error => alert(error))
    }

    handleSubmit = (event) => {
        event.preventDefault()

        this.swagger.saveMechanic(this.state?.newMechanic)
            .then(res => {
                let mechanics = this.state.mechanics
                mechanics.push(res.data)
                this.setState({
                    mechanics: mechanics,
                    newMechanic: {active : true},
                    showModal: false
                })

            })
            .catch(error => alert(error))
    }

    handleName = (event) => {
        let newMechanic = this.state.newMechanic
        newMechanic.name = event.target.value
        this.setState({ newMechanic: newMechanic })
    }

    handelSurname = (event) => {
        let newMechanic = this.state.newMechanic
        newMechanic.surname = event.target.value
        this.setState({ newMechanic: newMechanic })
    }

    handleActive = (event) => {
        let newMechanic = this.state.newMechanic
        newMechanic.active = event.target.checked
        this.setState({ newMechanic: newMechanic })
    }

    hideModal = () => {
        this.setState({showModal : false})
    }

    render() {
        return (
            <Container>
                <Card>
                    <Card.Header>
                        Nos mécaniciens
                        <Button
                            className="float-end"
                            variant="primary"
                            onClick={() => this.setState({ showModal: true })}>
                            + Ajouter
                        </Button>
                    </Card.Header>
                    <ListGroup>
                        {this.state?.mechanics.map(mec => (
                            <ListGroup.Item>
                                <Mechanic mechanic={mec} />
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card>
                <Modal show={this.state?.showModal} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        Ajouter un mécanicien
                    </Modal.Header>
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Body>
                            <Row className="mb-3">
                                <Col md="6">
                                    <Form.Label>
                                        Prénom
                                    </Form.Label>
                                    <Form.Control
                                        disabled={!this.state?.newMechanic?.active}
                                        value={this.state?.newMechanic?.name}
                                        onChange={this.handleName} />
                                </Col>
                                <Col md="6">
                                    <Form.Label>
                                        Nom
                                    </Form.Label>
                                    <Form.Control
                                        disabled={!this.state?.newMechanic?.active}
                                        value={this.state?.newMechanic?.surname}
                                        onChange={this.handelSurname} />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md="6">
                                    <Form.Group>
                                        <Form.Check
                                            id={"checkbox-new"}
                                            label="Est actif"
                                            checked={this.state?.newMechanic?.active}
                                            onChange={this.handleActive} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit">Enregistrer</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Container>
        )
    }

}