import { Component } from "react"
import { Navbar, Nav } from 'react-bootstrap'
import {
    withRouter,
    NavLink,
} from "react-router-dom";


class Menu extends Component {
    render() {
        return (
            <Navbar collapseOnSelect expand="lg" className="bg-primary-nbs" variant="dark">
                <Navbar.Brand>
                    BILLY
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavLink exact to="/" activeClassName="menu-active">Dossier</NavLink>
                        <NavLink exact to="/client" activeClassName="menu-active">Client</NavLink>
                        <NavLink exact to="/atelier" activeClassName="menu-active">Atelier</NavLink>
                        <NavLink exact to="/statistics" activeClassName="menu-active">Stats</NavLink>
                        |
                        <NavLink exact to="/product-manager" activeClassName="menu-active">Produits</NavLink>
                        <NavLink exact to="/mecaniciens" activeClassName="menu-active">Mécanicens</NavLink>
                        |
                    </Nav>
                </Navbar.Collapse>
            </Navbar >
        )
    }
}
export default withRouter(Menu)