import { Component } from "react"

import { Container, Card, Form, Button, Row, Col } from "react-bootstrap"
import { withRouter } from "react-router-dom";
import FolderDetail from "./FolderDetail";
import ClientBillingApi from "./swagger/ClientBillingApi";
import ClientCustomerApi from "./swagger/ClientCustomerApi";

class FileSearch extends Component {

    swagger = new ClientBillingApi()
    customerApi = new ClientCustomerApi()
    state = {}

    componentDidMount() {
        if (this.props.match.params.fileId !== undefined) {
            this.searchFile(this.props.match.params.fileId)
        }
    }

    componentDidUpdate(previousProps) {
        if (previousProps.match.params.fileId !== this.props.match.params.fileId) {
            this.searchFile(this.props.match.params.fileId)
        }
    }

    submitForm = (event) => {
        event.preventDefault()
        this.props.history.push("/dossier/".concat(this.state?.fileNumber))
    }

    searchFile(fileNumber) {
        this.swagger.getFolderById(fileNumber)
            .then(res => {
                //this.setState({ selectedFolder: res.data })

                this.customerApi.retrieveLastsBikeHistory(res.data.folder.bike.id)
                .then(resBike => {
                    this.setState({
                        fileNumber: fileNumber,
                        selectedFolder: res.data,
                        bikeHistory: resBike.data
                    })
                })
                .catch(error => {
                    this.setState({
                        fileNumber: fileNumber,
                        selectedFolder: res.data,
                        bikeHistory: undefined
                    })
                })
            })
            .catch(error => { alert(error) })
    }

    handleFileNumber = (event) => {
        this.setState({ fileNumber: event.target.value })
    }

    render() {
        return (<Container>
            <Row>
                <Col>
                    <Card>
                        <Form onSubmit={this.submitForm}>
                            <Card.Header>
                                Rechercher un dossier
                            </Card.Header>
                            <Card.Body>
                                <Form.Label>
                                    Numéro de dossier
                                </Form.Label>
                                <Form.Control required value={this.state.fileNumber} onChange={this.handleFileNumber}>

                                </Form.Control>
                            </Card.Body>
                            <Card.Footer>
                                <Button type="submit">
                                    Rechercher
                                </Button>
                            </Card.Footer>
                        </Form>
                    </Card>
                    <Card>
                        <Card.Header>
                            Détails du dossier
                        </Card.Header>
                        <Card.Body>
                            <FolderDetail folder={this.state?.selectedFolder} bikeHistory={this.state?.bikeHistory} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container >)
    }
}
export default withRouter(FileSearch);