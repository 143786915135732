import { Component } from "react";
import { Alert, Carousel } from "react-bootstrap";
import ClientBillingApi from "./swagger/ClientBillingApi";

export default class Picture extends Component {
    swagger = new ClientBillingApi()

    state = {}

    componentDidMount() {
        this.setState(this.props?.images)
    }

    componentWillReceiveProps(nextProps) {
        if (this.state !== nextProps.images) {
            this.setState(nextProps.images)
        }
    }

    render() {

        let items = []

        if (this.state.mainImage !== null) {
            items.push(
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={"data:image/png;base64,".concat(this.state.mainImage?.data)}
                        alt="principale" />

                    <Carousel.Caption>
                        <h3>Photo du vélo</h3>
                        <p>{this.state.mainImage?.name}</p>
                    </Carousel.Caption>
                </Carousel.Item>)
        }

        if (this.state.closeImages?.length > 0) {
            items.push(this.state.closeImages?.map(image => (
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={"data:image/png;base64,".concat(image?.data)}
                        alt="rapprochée" />

                    <Carousel.Caption>
                        <h3>Photo rapprochées</h3>
                        <p>{image?.name}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            )))
        }

        if (this.state.closeImages?.length > 0) {
            items.push(this.state.yellowFile?.map(image => (
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={"data:image/png;base64,".concat(image?.data)}
                        alt="devis papier" />

                    <Carousel.Caption>
                        <h3>Devis papier</h3>
                        <p>{image?.name}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            )))
        }

        if (this.state.repairedImage?.length > 0) {
            items.push(<Carousel.Item>
                <img
                    className="d-block w-100"
                    src={"data:image/png;base64,".concat(this.state.repairedImage?.data)}
                    alt="réparé" />

                <Carousel.Caption>
                    <h3>Vélo réparé</h3>
                </Carousel.Caption>
            </Carousel.Item>)
        }

        let render = items.length > 0 ?
            <Carousel interval={null}>
                {items}
            </Carousel> : <Alert variant="warning">Pas de photos disponibles</Alert>

        return (render)
    }
}