import { Component } from "react";

import { Button, Form, InputGroup, Modal, Row, Col } from "react-bootstrap";

import trashsvg from "../images/trash-fill.svg";
import pensvg from "../images/pen-fill.svg";
import ClientBillingApi from "./swagger/ClientBillingApi";

export default class ProductLine extends Component {

    swagger = new ClientBillingApi();

    state = {
        product: this.props.product,
        modal: {
            show: false,
            message: "",
            title: "",
            modification: false,
        }
    }

    handleUpdate = (event) => {
        event.preventDefault();

        this.swagger.saveProduct(this.state.product)
            .then(res => {
                this.setState({ product: res.data, showModal: false })
            })
            .catch(error => alert(error))
    }

    handleDelete = () => {
        this.swagger.deleteProduct(this.state.product.id)
            .then(() => {
                this.setState({ product: undefined, showModal: false })
            })
            .catch(error => alert(error))
    }

    handleProductCode = (event) => {
        var product = this.state.product
        product.code = event.target.value
        this.setState({ product: product });
    }

    handleProductEan = (event) => {
        var product = this.state.product
        product.barcode = event.target.value
        this.setState({ product: product });
    }

    handleProductName = (event) => {
        var product = this.state.product
        product.name = event.target.value
        this.setState({ product: product });
    }

    handleProductBuyingPrice = (event) => {
        var product = this.state.product
        product.taxedBuyingPrice = parseFloat(event.target.value)
        this.setState({ product: product });
    }

    handleProductSoldPrice = (event) => {
        var product = this.state.product
        product.taxedSoldPrice = parseFloat(event.target.value)
        this.setState({ product: product });
    }

    handleLabourPriceReal = (event) => {
        var product = this.state.product
        product.labourPriceReal = parseFloat(event.target.value)
        this.setState({ product: product });
    }

    handleLabourPriceSold = (event) => {
        var product = this.state.product
        product.labourPriceSold = parseFloat(event.target.value)
        this.setState({ product: product });
    }

    render() {
        if (this.state.product !== undefined) {
            return (
                <tr>
                    {this.props.onChangeValue === undefined ?
                        <td className="text-align-left">
                            {this.state.product?.code}
                        </td> : ""}
                    <td className="text-align-left"
                        style={{ cursor: "pointer" }}
                        onClick={this.props.onChangeValue !== undefined ? () => this.props.onChangeValue(
                            this.state.product?.id,
                            this.state.product?.name,
                            this.state.product?.taxedSoldPrice,
                            this.state.product?.labourPriceSold,
                            this.state.product?.family?.id,
                            this.state.product?.family?.name) : null}
                        dangerouslySetInnerHTML={{ __html: this.state.product?.name.replaceAll("[", "<span class=\"badge bg-secondary\">").replaceAll("]", "</span>") }}>
                    </td>
                    <td className="text-align-right"
                        style={{ cursor: "pointer" }}
                        onClick={this.props.onChangeValue !== undefined ? () => this.props.onChangeValue(
                            this.state.product?.id,
                            this.state.product?.name,
                            this.state.product?.taxedSoldPrice,
                            this.state.product?.labourPriceSold,
                            this.state.product?.family?.id,
                            this.state.product?.family?.name) : null}>
                        <b>{this.state.product?.labourPriceSold}</b>
                    </td>
                    <td className="text-align-right"
                        style={{ cursor: "pointer" }}
                        onClick={this.props.onChangeValue !== undefined ? () => this.props.onChangeValue(
                            this.state.product?.id,
                            this.state.product?.name,
                            this.state.product?.taxedSoldPrice,
                            this.state.product?.labourPriceSold,
                            this.state.product?.family?.id,
                            this.state.product?.family?.name) : null}>
                        <b>{this.state.product?.taxedSoldPrice}</b>
                    </td>
                    {this.props.onChangeValue === undefined ?
                        <td className="text-align-left">
                            {this.state.product?.barcode}
                        </td> : ""}
                    <td style={{ paddingLeft: "0", paddingRight: "0" }}>
                        <InputGroup>
                            <Button type="button" size="sm" variant="warning"
                                onClick={() => this.setState({ showModal: true })}>
                                <img src={pensvg} alt="modif" />
                            </Button>
                            {this.props.onChangeValue !== undefined ?
                                <Button type="button"
                                    size="sm"
                                    variant="success"
                                    onClick={() => this.props.onChangeValue(
                                        this.state.product?.id,
                                        this.state.product?.name,
                                        this.state.product?.taxedSoldPrice,
                                        this.state.product?.labourPriceSold,
                                        this.state.product?.family?.id,
                                        this.state.product?.family?.name)}>+</Button> : ""}
                        </InputGroup>

                        <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
                            <Modal.Header closeButton closeLabel="Fermer">
                                <Modal.Title>Enregistrement d'un produit</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={this.handleUpdate}>
                                    <Row className="mb-2">
                                        <Col>
                                            <Form.Label>
                                                Code produit
                                            </Form.Label>
                                            <Form.Control
                                                value={this.state.product?.code}
                                                onChange={this.handleProductCode} />
                                        </Col>
                                        <Col>
                                            <Form.Label>
                                                Code barre
                                            </Form.Label>
                                            <Form.Control
                                                value={this.state.product?.barcode}
                                                onChange={this.handleProductEan} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <Form.Label>
                                                <b>Libellé du produit</b>
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                value={this.state.product?.name}
                                                onChange={this.handleProductName} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <Form.Label>
                                                Main d'œuvre réelle
                                            </Form.Label><Form.Control
                                                value={this.state.product?.labourPriceReal}
                                                onChange={this.handleLabourPriceReal} />
                                        </Col>
                                        <Col>
                                            <Form.Label>
                                                <b>Main d'œuvre TTC</b>
                                            </Form.Label><Form.Control
                                                value={this.state.product?.labourPriceSold}
                                                onChange={this.handleLabourPriceSold} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <Form.Label>
                                                Prix d'achat TTC
                                            </Form.Label><Form.Control
                                                value={this.state.product?.taxedBuyingPrice}
                                                onChange={this.handleProductBuyingPrice} />
                                        </Col>
                                        <Col>
                                            <Form.Label>
                                                <b>Prix pièce TTC</b>
                                            </Form.Label><Form.Control
                                                value={this.state.product?.taxedSoldPrice}
                                                onChange={this.handleProductSoldPrice} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <br />
                                            <Button type="submit" className="float-end">Enregistrer</Button>

                                            <Button type="button" variant="danger"
                                                onClick={this.handleDelete} >
                                                <img src={trashsvg} alt="supp" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </td>
                </tr>
            )
        }
        else {
            return null
        }
    }
}