import { Component } from "react";
import ClientStatsApi from "../swagger/ClientStatsApi";
import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default class StatisticsCustomers extends Component {

    statsApi = new ClientStatsApi()

    state = {}

    componentDidMount() {
        this.showStats();
    }

    async showStats() {
        await this.statsApi.retrieveBestCustomers()
            .then(res => {
                this.setState({
                    customers: res.data.map(response => {
                        return {
                            id: response.customer.id,
                            name: response.customer.name,
                            surname: response.customer.surname,
                            amount: response.totalAmount
                        }
                    })
                })
            });
    }

    render() {
        return <Card>
            <Card.Header>
                Top 10 clients
            </Card.Header>
            <Card.Body>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Nom
                            </th>
                            <th className="text-align-right table-info" style={{width: "50px"}}>
                                Montant
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state?.customers?.map(customer => <tr>
                            <td>
                                <Link to={"/client/".concat(customer.id)}>{customer.id}</Link>
                            </td>
                            <td>
                                {customer.name} {customer.surname}
                            </td>
                            <td className="text-align-right table-info" style={{width: "50px"}}>
                                {customer.amount}
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    }

}
