import { Component } from "react";
import { Col, Row, Button, Form, InputGroup, Offcanvas } from "react-bootstrap";

import pensvg from "../images/pen-fill.svg";
import trashsvg from "../images/trash-fill.svg";
import arrowUp from "../images/arrow-up-short.svg";
import arrowDown from "../images/arrow-down-short.svg";
import arrowLeft from "../images/arrow-left-short.svg";
import arrowRight from "../images/arrow-right-short.svg";
import ClientBillingApi from "./swagger/ClientBillingApi";

export default class BikeSelector extends Component {

    swagger = new ClientBillingApi()

    state = {
        overlays: []
    }

    componentDidMount() {
        this.swagger.retrieveMainFamilies()
            .then(res => {
                this.setState({ overlays: res.data, value: this.props.value, onChangeValue: this.props.onChangeValue })
            })
            .catch(error => alert(error))
    }

    // MAIN UPDATE //

    handleMainUpdate = (index, mainFamily) => {
        this.setState({
            updateMain: {
                show: true,
                name: mainFamily.name,
                xcoord: mainFamily.xcoord,
                ycoord: mainFamily.ycoord,
                index: index
            }
        })
    }

    handleMainAdd = () => {
        document.body.click()

        var name = ""
        var xcoord = 10
        var ycoord = 10
        var color = "#EF8428"

        this.setState({
            updateMain: {
                show: true,
                name: name,
                xcoord: xcoord,
                ycoord: ycoord,
                color: color,
                index: this.state.overlays.length
            }
        })

        this.state.overlays.push({
            name: name,
            xcoord: xcoord,
            ycoord: ycoord,
            color: color,
            secondFamilies: []
        })
    }

    handleMainDelete = (index, mainFamily) => {

        this.swagger.deleteMainFamily(mainFamily.id)
            .then(() => {
                var overlays = this.state.overlays
                overlays.splice(index, 1)

                this.setState({
                    overlays: overlays,
                })
            })
            .catch(error => alert("la suppression à échouée, il est probable que ce soit parce que des prodtuis sont associés à cette famille."))

    }

    hideModal = () => {
        this.swagger.retrieveMainFamilies()
            .then(res => {
                this.setState({
                    overlays: res.data,
                    updateMain: {
                        show: false
                    },
                    updateSecond: {
                        show: false
                    }
                })
            })
            .catch(error => alert(error))
    }

    handleName = (index, event) => {
        var overlays = this.state.overlays
        overlays[index].name = event.target.value
        this.setState({ overlays: overlays })
    }

    handleX = (index, event) => {
        var overlays = this.state.overlays
        overlays[index].xcoord = parseInt(event.target.value)
        this.setState({ overlays: overlays })
    }

    minusX = (index) => {
        var overlays = this.state.overlays
        overlays[index].xcoord -= 5
        this.setState({ overlays: overlays })
    }

    addX = (index) => {
        var overlays = this.state.overlays
        overlays[index].xcoord += 5
        this.setState({ overlays: overlays })
    }

    handleY = (index, event) => {
        var overlays = this.state.overlays
        overlays[index].ycoord = parseInt(event.target.value)
        this.setState({ overlays: overlays })
    }

    minusY = (index) => {
        var overlays = this.state.overlays
        overlays[index].ycoord -= 5
        this.setState({ overlays: overlays })
    }

    addY = (index) => {
        var overlays = this.state.overlays
        overlays[index].ycoord += 5
        this.setState({ overlays: overlays })
    }

    handleColor = (index, event) => {
        var overlays = this.state.overlays
        overlays[index].color = event.target.value
        this.setState({ overlays: overlays })
    }

    handleSubmit = (index, event) => {
        event.preventDefault();

        var mainFamily = this.state.overlays[index]
        this.swagger.saveMainFamily(mainFamily)
            .then(res => {
                mainFamily.id = res.data.id;
                this.setState({
                    updateMain: {
                        show: false,
                        index: undefined,
                    }
                });
            })
            .catch(error => alert(error))
    }

    handleSelect = (indexFamilly) => {
        var fam = this.state.overlays[indexFamilly];
        this.props.onChangeValue(fam.id, fam.name);

        this.setState({
            updateIndexFamily: indexFamilly
        })
    }

    // -------------------------- //
    // ---------- RENDER -------- //
    // -------------------------- //

    render() {

        const updateButton = this.state.updateIndexFamily !== undefined ?
            <h3 className="small-padding">
                {this.state.overlays[this.state?.updateIndexFamily]?.name}
                <div className="float-end" style={{ maxWidth: "4em" }} >
                    <InputGroup>
                        <Button variant="warning" onClick={() => this.handleMainUpdate(this.state?.updateIndexFamily, this.state?.overlays[this.state?.updateIndexFamily])}>
                            <img src={pensvg} alt="edit" />
                        </Button>
                        <Button variant="danger" onClick={() => this.handleMainDelete(this.state?.updateIndexFamily, this.state?.overlays[this.state?.updateIndexFamily])}>
                            <img src={trashsvg} alt="supp" />
                        </Button>
                    </InputGroup>
                </div>
            </h3>

            : "";

        return (
            <div>
                <div className="svg-container">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 300">
                        <path className="svg-color-primary" d="m1662.29 692.559-126.75-345.34c-8.18-22.317 3.27-47.043 25.59-55.215 22.31-8.188 47.03 3.269 55.21 25.586l127.17 346.492c-4.15-.559-8.4-.852-12.71-.852-7.91 0-15.61.981-22.96 2.817-1.42.348-2.83.73-4.23 1.148a94.535 94.535 0 0 0-21.63 9.582 95.096 95.096 0 0 0-19.69 15.782" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-secondary" d="M1495.13 339.582c-23.8 0-43.09-19.301-43.09-43.098 0-23.8 19.29-43.097 43.09-43.097h157.12c23.8 0 43.1 19.297 43.1 43.097 0 23.797-19.3 43.098-43.1 43.098h-157.12" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m725.082 735.32-191.234 713.71 27.968 7.5 191.243-713.71-27.977-7.5" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m725.137 742.773 191.242 713.717 27.859-7.41-191.234-713.709-27.867 7.402" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="M753.004 735.371 561.762 21.648l-27.86 7.415 191.235 713.71 27.867-7.402" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="M749.242 728.855 226.77 206.387l-20.348 20.429 522.476 522.473 20.344-20.434" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="M742.816 725.09 29.106 533.848l-7.493 27.968 713.711 191.239 7.492-27.965" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="M735.367 725.137 21.657 916.379l7.405 27.871 713.711-191.242-7.406-27.871" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="M728.852 728.895 206.383 1251.37l20.43 20.35 522.472-522.47-20.433-20.355" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m728.898 749.289 522.472 522.471 20.35-20.43-522.478-522.475-20.344 20.434" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m735.324 753.055 713.716 191.242 7.49-27.981L742.816 725.09l-7.492 27.965" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m742.773 753.008 713.707-191.242-7.4-27.871-713.713 191.242 7.406 27.871" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m749.285 749.25 522.475-522.484-20.43-20.34-522.478 522.469 20.433 20.355" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="M753.059 742.82 944.297 29.11l-27.973-7.5-191.242 713.71 27.977 7.5" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="M561.078 1403.35c183.43 49.15 369.426 19.29 521.852-68.7 152.42-88.01 271.27-234.15 320.43-417.58 49.14-183.437 19.28-369.433-68.72-521.855-88-152.422-234.15-271.281-417.574-320.418C733.633 25.645 547.637 55.5 395.215 143.496 242.797 231.504 123.941 377.645 74.789 561.074 25.641 744.512 55.496 930.5 143.496 1082.93c88.004 152.42 234.152 271.27 417.582 320.42zm477.392-145.71c-132.708 76.63-294.661 102.62-454.376 59.82-159.723-42.79-286.981-146.28-363.602-278.98-76.621-132.718-102.609-294.671-59.816-454.386C203.473 424.375 306.957 297.117 439.668 220.5c132.711-76.633 294.656-102.617 454.387-59.824 159.715 42.801 286.975 146.289 363.595 278.992 76.62 132.715 102.62 294.656 59.81 454.387-42.79 159.715-146.27 286.975-278.99 363.585" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-secondary" d="M547.832 1452.78c197.078 52.81 396.918 20.73 560.678-73.82 163.77-94.55 291.47-251.57 344.28-448.647 52.8-197.083 20.73-396.915-73.83-560.684-94.55-163.762-251.57-291.465-448.647-344.27C733.227-27.449 533.395 4.63 369.629 99.18 205.863 193.73 78.168 350.755 25.355 547.833-27.444 744.914 4.63 944.746 99.18 1108.52c94.547 163.76 251.574 291.46 448.652 344.26zm517.598-148.45c-144.664 83.52-321.192 111.85-495.289 65.2-174.102-46.65-312.805-159.45-396.328-304.1-83.52-144.668-111.848-321.192-65.2-495.293 46.653-174.09 159.45-312.801 304.106-396.328 144.66-83.516 321.183-111.844 495.285-65.196 174.096 46.649 312.806 159.453 396.326 304.102 83.52 144.668 111.85 321.191 65.2 495.293-46.65 174.092-159.45 312.802-304.1 396.322" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-fourth" d="M791.945 708.547c7.813 13.523 10.461 30.039 6.098 46.328-4.367 16.289-14.922 29.266-28.441 37.066-13.528 7.817-30.039 10.461-46.336 6.106-16.282-4.367-29.262-14.922-37.071-28.449-7.804-13.524-10.457-30.047-6.097-46.328 4.367-16.29 14.922-29.266 28.449-37.071 13.519-7.812 30.043-10.469 46.328-6.101 16.285 4.367 29.266 14.922 37.07 28.449" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="M2933.34 741.641 2742.1 1455.35l27.98 7.5 191.24-713.709-27.98-7.5" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m2933.39 749.094 191.24 713.716 27.87-7.41-191.24-713.712-27.87 7.406" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="M2961.26 741.688 2770.02 27.977l-27.86 7.406 191.23 713.711 27.87-7.406" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m2957.5 735.176-522.47-522.481-20.35 20.43 522.47 522.48 20.35-20.429" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m2951.07 731.406-713.71-191.238-7.5 27.969 713.71 191.238 7.5-27.969" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m2943.63 731.457-713.72 191.238 7.41 27.86 713.71-191.239-7.4-27.859" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m2937.11 735.215-522.47 522.465 20.43 20.36 522.47-522.474-20.43-20.351" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m2937.15 755.605 522.48 522.465 20.34-20.43-522.47-522.464-20.35 20.429" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m2943.57 759.375 713.72 191.238 7.5-27.976-713.72-191.231-7.5 27.969" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m2951.03 759.316 713.72-191.23-7.42-27.871-713.7 191.242 7.4 27.859" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m2957.54 755.566 522.47-522.48-20.42-20.344-522.48 522.473 20.43 20.351" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="m2961.32 749.141 191.23-713.72-27.97-7.491-191.24 713.711 27.98 7.5" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="M2769.34 1409.67c183.43 49.15 369.42 19.29 521.84-68.71 152.43-88 271.28-234.15 320.43-417.581 49.15-183.43 19.29-369.422-68.71-521.848-88.01-152.422-234.15-271.277-417.58-320.43-183.43-49.148-369.42-19.285-521.85 68.712-152.42 88-271.27 234.152-320.42 417.578-49.15 183.429-19.3 369.425 68.7 521.849 88.01 152.42 234.15 271.28 417.59 320.43zm477.39-145.71c-132.71 76.63-294.66 102.62-454.39 59.83-159.71-42.81-286.97-146.29-363.59-279-76.62-132.708-102.62-294.661-59.81-454.38 42.79-159.726 146.27-286.98 278.99-363.605 132.71-76.621 294.66-102.606 454.37-59.813 159.73 42.805 286.99 146.289 363.61 278.992 76.62 132.715 102.61 294.661 59.81 454.387-42.79 159.719-146.27 286.969-278.99 363.589" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-secondary" d="M2756.09 1459.1c197.09 52.81 396.92 20.73 560.68-73.82 163.77-94.55 291.46-251.57 344.27-448.647 52.81-197.09 20.73-396.926-73.82-560.688-94.54-163.757-251.58-291.464-448.65-344.265-197.09-52.813-396.92-20.735-560.69 73.82-163.76 94.551-291.45 251.57-344.27 448.648-52.8 197.082-20.73 396.915 73.82 560.682 94.55 163.76 251.57 291.46 448.66 344.27zm517.59-148.45c-144.66 83.51-321.19 111.85-495.28 65.2-174.1-46.65-312.81-159.46-396.33-304.11-83.52-144.658-111.86-321.185-65.2-495.283 46.65-174.105 159.45-312.805 304.11-396.328 144.66-83.52 321.18-111.848 495.28-65.199 174.1 46.64 312.81 159.445 396.33 304.101 83.52 144.66 111.84 321.184 65.2 495.285-46.65 174.104-159.45 312.814-304.11 396.334" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-fourth" d="M3000.2 714.863c7.81 13.516 10.46 30.039 6.1 46.328-4.37 16.289-14.92 29.27-28.45 37.071-13.53 7.812-30.04 10.468-46.33 6.093-16.27-4.355-29.26-14.914-37.07-28.437-7.81-13.527-10.45-30.051-6.09-46.328 4.37-16.289 14.91-29.27 28.44-37.078 13.52-7.805 30.05-10.461 46.33-6.094 16.28 4.363 29.26 14.922 37.07 28.445" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-fifth" d="M1265.92 1918.59c-9.11 21.94-34.28 32.34-56.22 23.23-21.94-9.11-32.34-34.29-23.24-56.23l89.83-214.82 376.41-900.243H753.992c-23.797 0-43.094-19.297-43.094-43.097 0-23.797 19.297-43.094 43.094-43.094h962.538a42.79 42.79 0 0 1 17.43 3.34c21.96 9.179 32.32 34.422 23.14 56.379l-.07-.032-401.29 959.747-89.82 214.82" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path style={{ fill: "#353535", "fill-opacity": "1", "fill-rule": "nonzero", stroke: "none" }} d="M1265.92 1918.59c-9.11 21.94-34.28 32.34-56.22 23.23-21.94-9.11-32.34-34.29-23.24-56.23l30.46-72.83 80.51 30.47-31.51 75.36" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-fifth" d="M2543.32 1981.04c-8.09 22.32-32.73 33.84-55.05 25.76-22.31-8.09-33.84-32.74-25.75-55.05l437.23-1208.457c8.08-22.309 32.72-33.84 55.04-25.754 22.31 8.086 33.84 32.734 25.76 55.051l-437.23 1208.45" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-fifth" d="M2612.9 1643.19c-10.32-3.78-18.32 10.44-23.12 1.46.32.39.64.78.98 1.17l.13-.12-.04-.11 3.13-2.7-3.07 1.75c-.35-1.62-1.74-5.78-2.99-9.14 11.15 9.61 69.99-47.95 6.06 7.39l-3.07 1.75c.18.77.11.97-.34.19-.56-.97.04.15.28.76l-.07.05c-.33-.38-.81-.89-1.17-1.32l.17.33-.83-1.06c-80.6-90.45-899.2-883.051-901.57-885.348-17.02-16.554-17.4-43.762-.84-60.769 16.54-17.024 43.75-17.395 60.76-.852 2.38 2.297 818.15 792.169 905.77 889.789.93.89 1.84 1.84 2.71 2.83l-.02.02c4.26 4.89 7.47-12.36 9.55-8.73.46.79.89 1.61 1.3 2.42 2 3.93 3.45 8.23 4.18 12.83l-.17.02.01.06.03-.01c4.32 27.06-6.19 43.19-32.77 48.97-.51.12-1.02.23-1.53.33l-.01-.04c-7.68 1.49-15.69.95-23.45-1.92zm-22.01 2.52-.11.11c.19.22.2.12.11-.11M701.484 779.668c-11.804-20.637-4.644-46.945 15.993-58.75 20.64-11.809 46.949-4.648 58.75 15.996l577.153 938.766c11.81 20.63 4.65 46.94-15.99 58.75-20.64 11.8-46.94 4.64-58.75-16L701.484 779.668" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-fifth" d="M1271.58 1740.15h1341.13l21.56-86.19H1271.58v86.19" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-third" d="M1730.8 547.246c-58.17 0-110.85 23.586-148.99 61.719-38.13 38.125-61.71 90.808-61.71 148.984 0 58.192 23.58 110.867 61.71 149.004 38.14 38.125 90.82 61.719 148.99 61.719 58.19 0 110.87-23.594 149-61.719 38.14-38.137 61.72-90.812 61.72-149.004 0-58.176-23.58-110.859-61.72-148.984-38.13-38.133-90.81-61.719-149-61.719z" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path style={{ fill: "#616161", "fill-opacity": "1", "fill-rule": "evenodd", stroke: "none" }} d="M1756.18 666.672c38.49 10.68 67.14 45.055 69.23 86.433.07 1.602.12 3.223.12 4.844 0 19.719-6.02 38.028-16.33 53.184-10.41 15.301-25.17 27.414-42.52 34.512-11.06 4.531-23.17 7.031-35.88 7.031-4.65 0-9.24-.34-13.71-.988a93.78 93.78 0 0 1-8.02-1.52c-41.84-9.824-72.98-47.387-72.98-92.219 0-21.054 6.87-40.5 18.5-56.23 7.38-10 16.7-18.496 27.39-24.942a94.727 94.727 0 0 1 21.63-9.582c1.4-.418 2.81-.8 4.23-1.148a94.69 94.69 0 0 1 22.96-2.817c8.79 0 17.3 1.204 25.38 3.442" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-primary" d="M1926.07 1161.52c8.19 22.31-3.27 47.03-25.58 55.21s-47.03-3.27-55.21-25.59l-124.42-338.984c3.27.344 2.07-5.047 10.09-5.047 8.03 0 21.51-5.722 32.58-10.254 11.52-6.906 11.03-19.074 37.68-15.546l124.86 340.211" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-secondary" d="M1818.34 1232.89c-23.8 0-43.09-19.29-43.09-43.09 0-23.8 19.29-43.1 43.09-43.1h170.59c23.8 0 43.09 19.3 43.09 43.1s-19.29 43.09-43.09 43.09h-170.59" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path style={{ fill: "#fefefe", "fill-opacity": "1", "fill-rule": "evenodd", stroke: "none" }} d="M1746.2 702.578c23.35 6.477 40.73 27.336 42 52.434.04.972.07 1.961.07 2.949a57.203 57.203 0 0 1-9.91 32.254c-6.31 9.289-15.27 16.633-25.79 20.937a57.409 57.409 0 0 1-21.77 4.27 56.9 56.9 0 0 1-8.32-.609 55.554 55.554 0 0 1-4.86-.918c-25.38-5.957-44.27-28.739-44.27-55.934 0-12.777 4.17-24.582 11.22-34.121a57.607 57.607 0 0 1 16.62-15.129 56.804 56.804 0 0 1 15.69-6.516 57.667 57.667 0 0 1 13.92-1.695c5.34 0 10.5.723 15.4 2.078" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path style={{ fill: "#7e7e7e", "fill-opacity": "1", "fill-rule": "evenodd", stroke: "none" }} d="M3033.63 695.555c12.75 22.074 17.08 49.035 9.96 75.625-7.13 26.582-24.35 47.777-46.43 60.519-22.08 12.742-49.03 17.071-75.62 9.949-26.59-7.128-47.78-24.351-60.52-46.433-12.75-22.07-17.07-49.035-9.95-75.617 7.13-26.59 24.35-47.774 46.43-60.516 22.07-12.754 49.04-17.078 75.63-9.949 26.58 7.117 47.76 24.351 60.5 46.422M825.375 689.238c12.75 22.078 17.082 49.032 9.957 75.625-7.125 26.582-24.355 47.774-46.43 60.52-22.078 12.742-49.035 17.066-75.625 9.949-26.582-7.117-47.769-24.355-60.511-46.437-12.75-22.071-17.078-49.032-9.957-75.614 7.129-26.593 24.355-47.773 46.429-60.519 22.075-12.742 49.043-17.078 75.625-9.949 26.594 7.128 47.774 24.355 60.512 46.425" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path className="svg-color-secondary" d="M2439.56 2015.32c-25.72-6.87-40.99-33.29-34.13-59.01 6.87-25.72 33.3-41 59.02-34.13l333.94 89.48c18.47 4.95 37.25 1.9 52.63-6.97 15.46-8.92 27.52-23.67 32.45-42.1 4.96-18.49 1.95-37.28-6.89-52.67l-.08.04-.04-.06-.09.06c-.43-.73-.83-1.48-1.21-2.22-9.03-14.38-23.23-25.53-40.75-30.23l-90.41-24.22c-25.73-6.88-41.01-33.3-34.14-59.02 6.88-25.72 33.3-41 59.02-34.13l90.42 24.23c42.73 11.45 77.15 38.81 98.55 73.98.74 1.08 1.46 2.19 2.12 3.35l-41.81 24.13 41.68-23.95c21.25 36.82 28.55 81.65 16.77 125.6-11.81 44.08-40.54 79.31-77.32 100.54-36.85 21.28-81.75 28.59-125.79 16.78l-333.94-89.48" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path style={{ fill: "#414141", "fill-opacity": "1", "fill-rule": "evenodd", stroke: "none" }} d="M1266.29 1910.26c63.53 21.38 282.2 20.09 324.01 97.62 4.74 8.78 4.99 11.13 2.62 21.38-17.37 75.28-359.08 11.77-433.19 11.4-31.27-.15-194.667 88.53-190.48-18.04 1.367-34.8 14.395-101.91 90.03-137.32 0 0 90.43-19.97 207.01 24.96" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        <path style={{ fill: "#353535", "fill-opacity": "1", "fill-rule": "evenodd", stroke: "none" }} d="M1266.29 1910.26c11.48 3.87 28.04 6.99 47.69 9.97l-155.55 120.48c-34.21 2.08-193.313 87.09-189.18-18.09 1.367-34.8 14.395-101.91 90.03-137.32 0 0 90.43-19.97 207.01 24.96" transform="matrix(.13333 0 0 -.13333 0 281.387)" />
                        {this.state?.overlays?.map((elemnt, index) => (
                            <circle
                                cx={elemnt.xcoord} cy={elemnt.ycoord} r="20"
                                fill={elemnt.color}
                                onClick={() => this.handleSelect(index)}
                            />
                        ))}
                    </svg>
                </div>
                {updateButton}
                <Button
                    type="button"
                    onClick={this.handleMainAdd}
                    style={{
                        position: "absolute",
                        top: "4em",
                        right: "2em",
                    }}>
                    + Ajouter
                </Button>

                <Offcanvas placement="bottom" scroll show={this.state.updateMain?.show} onHide={this.hideModal} >
                    <Offcanvas.Header closeButton closeLabel="Fermer">
                        <Offcanvas.Title>
                            Modification Grande famille
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Form className="svg-family-update-form" onSubmit={(event) => this.handleSubmit(this.state.updateMain.index, event)}>
                            <Row>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            Nom
                                        </InputGroup.Text>
                                        <Form.Control
                                            value={this.state.overlays[this.state.updateMain?.index]?.name}
                                            onChange={(event) => this.handleName(this.state.updateMain?.index, event)}>
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            Couleur
                                        </InputGroup.Text>
                                        <Form.Select
                                            value={this.state.overlays[this.state.updateMain?.index]?.color}
                                            onChange={(event) => this.handleColor(this.state.updateMain?.index, event)}
                                            style={{ backgroundColor: this.state.overlays[this.state.updateMain?.index]?.color }}>
                                            <option value="#EF8428" style={{ backgroundColor: "#EF8428" }}>Orange (thème)</option>
                                            <option value="#61C3ED" style={{ backgroundColor: "#61C3ED" }}>Bleu (thème)</option>
                                            <option value="#0d6efd" style={{ backgroundColor: "#0d6efd" }}>Bleu</option>
                                            <option value="#dc3545" style={{ backgroundColor: "#dc3545" }}>Rouge</option>
                                            <option value="#ffc107" style={{ backgroundColor: "#ffc107" }}>Jaune</option>
                                            <option value="#198754" style={{ backgroundColor: "#198754" }}>Vert</option>
                                            <option value="#7000cc" style={{ backgroundColor: "#7000cc" }}>Violet</option>
                                            <option value="#f702e7" style={{ backgroundColor: "#f702e7" }}>Rose</option>
                                            <option value="#663400" style={{ backgroundColor: "#663400" }}>Marron</option>
                                            <option value="#78f75b" style={{ backgroundColor: "#78f75b" }}>Vert Pastel</option>
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="small-margin">
                                <Col xs={4}>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            X
                                        </InputGroup.Text>
                                        <Form.Control
                                            value={this.state.overlays[this.state.updateMain?.index]?.xcoord}
                                            onChange={(event) => this.handleX(this.state.updateMain?.index, event)} />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            Y
                                        </InputGroup.Text>
                                        <Form.Control
                                            value={this.state.overlays[this.state.updateMain?.index]?.ycoord}
                                            onChange={(event) => this.handleY(this.state.updateMain?.index, event)} />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Row>
                                        <Col xs={3}></Col>
                                        <Col xs={6}>
                                            <Button type="button" size="sm" onClick={() => this.minusY(this.state.updateMain?.index)}>
                                                <img src={arrowUp} alt="key up" />
                                            </Button>
                                        </Col>
                                        <Col xs={3}></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>
                                            <Button type="button" size="sm" onClick={() => this.minusX(this.state.updateMain?.index)}>
                                                <img src={arrowLeft} alt="key left" />
                                            </Button>
                                        </Col>
                                        <Col xs={6}>
                                            <Button type="button" size="sm" onClick={() => this.addX(this.state.updateMain?.index)}>
                                                <img src={arrowRight} alt="key right" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={3}></Col>
                                        <Col xs={6}>
                                            <Button type="button" size="sm" onClick={() => this.addY(this.state.updateMain?.index)}>
                                                <img src={arrowDown} alt="key down" />
                                            </Button>
                                        </Col>
                                        <Col xs={3}></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="sm-invisible">
                                <Col>
                                    Les coordonnées représentent la position du point sur le dessin du vélo.
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button type="submit" >Enregistrer</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        )
    }

}