import { Component } from "react";
import { withRouter } from "react-router";

import { Alert, Button, InputGroup, Modal, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { SubPdfViewer } from './SubPdfViewer';

import printsvg from "../images/printer-fill.svg";
import filepdfsvg from "../images/file-pdf.svg"
import eyefillsvg from "../images/eye-fill.svg";
import ClientBillingApi from "./swagger/ClientBillingApi";

class FolderDetailAccSub extends Component {

    swagger = new ClientBillingApi();

    state = {
        showModalValidation: false
    }

    componentDidMount() {
        this.setState({
            detail: this.props.detail,
            showModalValidation: false
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            detail: nextProps.detail,
            showModalValidation: false
        })
    }

    validateSubmission = () => {
        this.swagger.validateSubmission(this.state?.detail?.submission)
            .then(res => {
                var detail = this.state.detail
                detail.acceptedSubmission = res.data
                this.setState({ detail: detail })

                this.swagger.saveAcceptedSubmissionAsPdf(this.state?.detail?.folder.id)
                .then((response) => {
                })
                .catch(error => alert("Erreur lors de la création du PDF " + error))

                this.props.handleChangeValue(detail)
            })
            .catch(error => alert(error))
    }

    downlaodAcceptedSubmission = () => {
        this.swagger.saveAcceptedSubmissionAsPdf(this.state?.detail?.folder.id)
            .then((response) => {
                const source = "data:application/pdf;base64,".concat(response.data.second);
                const link = document.createElement('a');
                link.href = source;

                link.download = response.data.first; //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => alert(error))
    }

    showButtons() {
        return <>
            <InputGroup>
                <Button type="button"
                    variant="info"
                    title="Afficher le devis"
                    onClick={() => this.setState({ showModalPdf: true })}>
                    <img src={eyefillsvg} alt="visu" />
                </Button>
                <Button type="button"
                    variant="danger"
                    title="Créer pdf"
                    onClick={this.downlaodAcceptedSubmission} >
                    <img src={filepdfsvg} alt="visu" />

                </Button>
            </InputGroup>

            <Modal size="lg" show={this.state?.showModalPdf} onHide={() => this.setState({ showModalPdf: false })}>
                <Modal.Header closeButton>
                    Impression du devis
                </Modal.Header>
                <Modal.Body>
                    <SubPdfViewer ref={el => (this.componentRef = el)} submission={this.state?.detail?.submission} />
                </Modal.Body>
                <Modal.Footer>
                    <ReactToPrint
                        trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return <Button type="button"
                                title="Imprimer le devis">
                                <img src={printsvg} alt="impr" />
                            </Button>;
                        }}
                        content={() => this.componentRef}
                    />
                </Modal.Footer>
            </Modal>
        </>
    }

    render() {
        return <Table>
            <thead>
                <tr>
                    <th>
                        Etat
                    </th>
                    <th>
                        date validation
                    </th>
                    <th>
                        Mécano
                    </th>
                    <th className="text-align-right table-info" style={{width: "50px"}}>
                        MO
                    </th>
                    <th className="text-align-right table-info" style={{width: "50px"}}>
                        pièce
                    </th>
                    <th className="text-align-right table-info" style={{width: "50px"}}>
                        Total
                    </th>
                    <th className="text-align-right" style={{width: "150px"}}>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {this.state?.detail?.acceptedSubmission !== null ?
                    <tr>
                        <td>
                            {this.state?.detail?.acceptedSubmission?.denied === -1 ? "refusé : " : "accepté"}
                            {this.state?.detail?.acceptedSubmission?.deniedMotive}
                        </td>
                        <td>
                            {this.state?.detail?.acceptedSubmission.date !== undefined ? new Intl.DateTimeFormat("fr-FR", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                            }).format(new Date(this.state.detail?.acceptedSubmission?.date)) : ""}
                        </td>
                        <td>
                            {this.state?.detail?.acceptedSubmission.mechanic?.name}{" "}
                            {this.state?.detail?.acceptedSubmission.mechanic?.surname}
                        </td>
                        <td className="text-align-right table-info">
                            {this.state?.detail?.acceptedSubmission.labour}
                        </td>
                        <td className="text-align-right table-info">
                            {this.state?.detail?.acceptedSubmission.piece}
                        </td>
                        <td className="text-align-right table-info">
                            <b>{this.state?.detail?.acceptedSubmission.total}</b>
                        </td>
                        <td >
                            <div className="float-end">
                                {this.showButtons()}
                            </div>
                        </td>
                    </tr>
                    :
                    <tr>
                        <td colSpan="100">
                            <Alert variant="warning">
                                <p><b>Pas de devis accepté en cours</b>
                                    {this.state?.detail?.submission !== null && this.state?.detail?.submission !== undefined ?
                                        <div className="float-end">
                                            <InputGroup>
                                                <Button
                                                    onClick={() => this.setState({ showModalValidation: true })}
                                                    variant="primary"
                                                    title="Valider le devis" >
                                                    Valider le devis
                                                </Button>
                                                <Button
                                                    onClick={() => alert("à développer")}
                                                    variant="danger"
                                                    title="Refuser le devis" >
                                                    Refus du devis
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        : <><br />Créez un devis avant de le faire accepter par le client</>
                                    }

                                </p>

                            </Alert>

                            <Modal show={this.state?.showModalValidation} onHide={() => this.setState({ showModalValidation: false })}>
                                <Modal.Header closeButton>
                                    Validation du devis
                                </Modal.Header>
                                <Modal.Body>
                                    En effectuant cette action :
                                    <ul>
                                        <li>
                                            Le client valide le devis
                                        </li>
                                        <li>
                                            Le devis ne sera désormais plus modifiable
                                        </li>
                                    </ul>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        type="button"
                                        onClick={this.validateSubmission}
                                    >
                                        Valider le devis
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </td>
                    </tr>}
            </tbody>
        </Table>
    }
}
export default withRouter(FolderDetailAccSub)