import { Component } from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import DateUtils from "../utils/DateUtils";
import envelopefillstacksvg from "../../images/mail-stack.svg";
import ClientBillingApi from "../swagger/ClientBillingApi";
import BillValidator from "./BillValidator";

export default class RSFeedbackHeadCell extends Component {

    DELAY_MIN = 30
    DELAY_MAX = 50

    dateUtils = new DateUtils()
    swagger = new ClientBillingApi()
    billValidator = new BillValidator()

    componentDidMount() {
        this.setState({
            folders: this.props?.folders,
            filter: this.props?.filter
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filter !== this.props?.filter) {
            this.setState({
                folders: this.props?.folders,
                filter: this.props?.filter
            })
        }
    }

    sendMails = () => {

        var messageBillSent = []


        this.state?.folders.forEach((request, index) => {

            if (this.billValidator.canSendTheFeedbackGoogle(request)) {
                messageBillSent.push(<p>Envoie du message à {request.bill.headerContact}</p>)
            }
            else {
                messageBillSent.push(<p>Envoie du message à {request.folder.customer.name} {request.folder.customer.surname} <Badge className="bg-warning">Pas d'envoi</Badge></p>)
            }
        })

        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours : Sortie Vélo",
                body: messageBillSent
            }
        })

        var folders = this.state.folders

        this.state?.folders.forEach((request, index) => {
            if (this.billValidator.canSendTheFeedbackGoogle(request)) {
                this.swagger.sendFeedbackGoogle(request.folder.id)
                    .then(res => {
                        folders[index].folder = res.data
                        messageBillSent[index] = <p>Envoie du message à {folders[index].bill.headerContact} <Badge className="bg-success">Envoyé !!</Badge></p>
                        this.setState({
                            folders: folders,
                            modalDetail: {
                                show: true,
                                title: "Envoie en cours : Sortie Vélo",
                                body: messageBillSent.length === 0 ? "Pas de mail à envoyer" : messageBillSent
                            }
                        })
                    })
                    .catch(error => {
                        messageBillSent[index] = <p>Envoie du message à  {this.state.folders[index].folder.customer.name} {this.state.folders[index].folder.customer.surname} <Badge className="bg-alert"> Erreur !!</Badge></p>
                        this.setState({
                            folders: folders,
                            modalDetail: {
                                show: true,
                                title: "Envoie en cours : Sortie Vélo",
                                body: messageBillSent.length === 0 ? "Pas de mail à envoyer" : messageBillSent
                            }
                        })
                    })
            }
        })
    }


    render() {
        var isDisplayable = this.state?.filter === 10

        return isDisplayable ?
            <>Avis Google
                <div className="float-end">
                    <Button onClick={this.sendMails}>
                        <img width={19} src={envelopefillstacksvg} alt="Envoie tout" />
                    </Button>
                </div>
                <Modal show={this.state?.modalDetail?.show}
                    onHide={() => this.setState({ modalDetail: { show: false } })}>
                    <Modal.Header closeButton>
                        {this.state?.modalDetail?.title}
                    </Modal.Header>
                    <Modal.Body>
                        {this.state?.modalDetail?.body}
                    </Modal.Body>
                </Modal>
            </>
            :
            <>Avis Google</>


    }
}