import { Component } from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ClientCustomerApi from "./swagger/ClientCustomerApi";
import { withRouter } from "react-router-dom";


class CustomerCreation extends Component {

    clientApi = new ClientCustomerApi();

    state = {customer: {
        bikes : []
    }}

    componentDidMount() {}

    handleChangeGender = (event) => {
        let customer = this.state.customer;
        customer.genre = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeName = (event) => {
        let customer = this.state.customer;
        customer.name = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeSurname = (event) => {
        let customer = this.state.customer;
        customer.surname = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeAddress = (event) => {
        let customer = this.state.customer;
        customer.addressFirstLine = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeZipCode = (event) => {
        let customer = this.state.customer;
        customer.zipCode = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeCity = (event) => {
        let customer = this.state.customer;
        customer.city = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeMail = (event) => {
        let customer = this.state.customer;
        customer.mail = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangePhoneNumber = (event) => {
        let customer = this.state.customer;
        customer.phoneNumber = event.target.value;
        this.setState({ customer: customer });
    }

    submitRequest = (event) => {
        event.preventDefault()
        this.clientApi.saveCustomer(this.state.customer)
            .then(res => {
                this.props.history.push("/client/".concat(res.data.id))
            })
            .catch(error => {
                alert("Erreur lors du traitement : " + error.message)
            })

    }

    render() {
        return (
            <Form onSubmit={this.submitRequest}>
                <Row>
                    <Col lg>
                        <Card.Title>Civilité</Card.Title>
                        <Row>
                            <Col className="small-margin-top-bottom">
                                <Form.Control as="select"
                                    className="form-select"
                                    required
                                    value={this.state.customer.genre}
                                    onChange={this.handleChangeGender} >
                                    <option value="1">Monsieur</option>
                                    <option value="2">Madame</option>
                                </Form.Control>
                            </Col>
                            <Col className="small-margin-top-bottom">
                                <Form.Control as="input"
                                    placeholder="prénom"
                                    required
                                    value={this.state.customer.name}
                                    onChange={this.handleChangeName} />
                            </Col>
                            <Col className="small-margin-top-bottom">
                                <Form.Control as="input"
                                    placeholder="nom"
                                    required
                                    value={this.state.customer.surname}
                                    onChange={this.handleChangeSurname} />
                            </Col>
                        </Row>
                        <Card.Title>Adresse</Card.Title>
                        <Row>
                            <Col className="mb-1">
                                <Form.Control as="input"
                                    placeholder="numéro, rue"
                                    required
                                    value={this.state.customer.addressFirstLine}
                                    onChange={this.handleChangeAddress} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-1">
                                <Form.Control as="input"
                                    placeholder="code postal"
                                    required
                                    value={this.state.customer.zipCode}
                                    onChange={this.handleChangeZipCode} />
                            </Col>
                            <Col className="mb-1">
                                <Form.Control as="input"
                                    placeholder="ville"
                                    required
                                    value={this.state.customer.city}
                                    onChange={this.handleChangeCity} />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg >
                        <Card.Title>Contact</Card.Title>
                        <Form.Control as="input"
                            placeholder="e-mail"
                            className="mb-1"
                            required
                            value={this.state.customer.mail}
                            onChange={this.handleChangeMail} />
                        <Form.Control as="input"
                            placeholder="numéro de téléphone"
                            className="mb-1"
                            required
                            value={this.state.customer.phoneNumber}
                            onChange={this.handleChangePhoneNumber} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button type="submit">Créer le nouveau client</Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}
export default withRouter(CustomerCreation);