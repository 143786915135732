import { Component } from "react";
import { Alert, Button, InputGroup, Modal, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { SubPdfViewer } from './SubPdfViewer';

import editFolder from "../images/pen-fill.svg"
import lockfillsvg from "../images/lock-fill.svg";
import printsvg from "../images/printer-fill.svg";
import eyefillsvg from "../images/eye-fill.svg";
import filepdfsvg from "../images/file-pdf.svg"

import { withRouter } from "react-router";
import ClientBillingApi from "./swagger/ClientBillingApi";

class FolderDetailSubmission extends Component {

    swagger = new ClientBillingApi()

    componentDidMount() {
        var totalDeclined = 0
        var totalPieceDeclined = 0
        var totalLabourDeclined = 0

        this.props.detail?.submission?.lines.forEach(productLine => {
            totalDeclined += productLine.labourPrice + productLine.price;
            totalPieceDeclined += productLine.price;
            totalLabourDeclined += productLine.labourPrice;
        });

        this.setState({
            detail: this.props.detail,
            totalDeclined: totalDeclined,
            totalLabourDeclined: totalLabourDeclined,
            totalPieceDeclined: totalPieceDeclined
        })
    }

    componentWillReceiveProps(nextProps) {
        var totalDeclined = 0
        var totalPieceDeclined = 0
        var totalLabourDeclined = 0

        nextProps.detail?.submission?.lines.forEach(productLine => {
            totalDeclined += productLine.labourPrice + productLine.price;
            totalPieceDeclined += productLine.price;
            totalLabourDeclined += productLine.labourPrice;
        });

        this.setState({
            detail: nextProps.detail,
            totalDeclined: totalDeclined,
            totalLabourDeclined: totalLabourDeclined,
            totalPieceDeclined: totalPieceDeclined
        })
    }

    toSubmission = () => {
        this.props.history.push("/submission/".concat(this.state?.detail?.folder.id))
    }

    downlaodTheBill = () => {
        this.swagger.saveSubmissionAsPdf(this.state?.detail?.folder.id)
            .then((response) => {
                const source = "data:application/pdf;base64,".concat(response.data.second);
                const link = document.createElement('a');
                link.href = source;

                link.download = response.data.first; //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => alert(error))
    }

    showTheButtons() {

        if (this.state.detail.acceptedSubmission === undefined || this.state.detail.acceptedSubmission === null) {
            return <InputGroup>
                <Button
                    value={this.state?.detail?.folder.id}
                    onClick={this.toSubmission}
                    variant="warning"
                    title="Modifier le devis">
                    <img src={editFolder} alt="Éditer" />
                </Button>
                <Button type="button"
                    variant="info"
                    title="Afficher le devis"
                    onClick={() => this.setState({ showModalPdf: true })}>
                    <img src={eyefillsvg} alt="visu" />
                </Button>
            </InputGroup>
        } else {
            return <img src={lockfillsvg} alt="verrou" />
        }

    }

    render() {
        return <Table>
            <thead>
                <tr>
                    <th>
                        #
                    </th>
                    <th>
                        date création
                    </th>
                    <th>
                        Mécano
                    </th>
                    <th className="text-align-right table-info" style={{ width: "50px" }}>
                        MO
                    </th>
                    <th className="text-align-right table-info" style={{ width: "50px" }}>
                        pièce
                    </th>
                    <th className="text-align-right table-info" style={{ width: "50px" }}>
                        Total
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {this.state?.detail?.submission !== null && this.state?.detail?.submission !== undefined ?
                    <>
                        <tr>
                            <td>
                                {this.state?.detail?.submission.id}
                            </td>
                            <td>
                                {this.state?.detail?.submission.date !== undefined ? new Intl.DateTimeFormat("fr-FR", {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                }).format(new Date(this.state?.detail?.submission?.date)) : ""}
                            </td>
                            <td>
                                {this.state?.detail?.submission.mechanic?.name}{" "}
                                {this.state?.detail?.submission.mechanic?.surname}
                            </td>
                            <td className="text-align-right table-info">
                                {this.state?.detail?.submission.labour}
                            </td>
                            <td className="text-align-right table-info">
                                {this.state?.detail?.submission.piece}
                            </td>
                            <td className="text-align-right table-info">
                                <b>{this.state?.detail?.submission.total}</b>
                            </td>
                            <td >
                                <div className="float-end">
                                    {this.showTheButtons()}
                                </div>

                                <Modal size="lg" show={this.state?.showModalPdf} onHide={() => this.setState({ showModalPdf: false })}>
                                    <Modal.Header closeButton>
                                        Impression du devis
                                    </Modal.Header>
                                    <Modal.Body>
                                        <SubPdfViewer ref={el => (this.componentRef = el)} submission={this.state?.detail?.submission} />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactToPrint
                                            trigger={() => {
                                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                // to the root node of the returned component as it will be overwritten.
                                                return <Button type="button"
                                                    title="Imprimer le devis">
                                                    <img src={printsvg} alt="impr" />
                                                </Button>;
                                            }}
                                            content={() => this.componentRef}
                                        />
                                    </Modal.Footer>
                                </Modal>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-align-right" colSpan={3}><b>Valeurs de base du devis</b></td>
                            <td className="text-align-right table-warning">
                                {this.state?.totalLabourDeclined}
                            </td>
                            <td className="text-align-right table-warning">
                                {this.state?.totalPieceDeclined}
                            </td>
                            <td className="text-align-right table-warning">
                                <b>{this.state?.totalDeclined}</b>
                            </td>
                            <td>
                                <div className="float-end">
                                    <Button
                                        variant="danger"
                                        onClick={this.downlaodTheBill}
                                        title="Télecharger le PDF"
                                    >
                                        <img src={filepdfsvg} alt="pdf" />
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    </>
                    :
                    <tr>
                        <td colSpan="100">
                            <Alert variant="warning">
                                <p>Pas de devis en cours
                                    <Button
                                        className="float-end"
                                        value={this.state?.detail?.folder.id}
                                        onClick={this.toSubmission}
                                        variant="success"
                                        title="Créer nouveau devis" >
                                        Créer un nouveau devis
                                    </Button>
                                </p>
                            </Alert>
                        </td>
                    </tr>}
            </tbody>
        </Table >
    }
}
export default withRouter(FolderDetailSubmission)