export default class BillValidator {

    canSendTheBill(request) {
        if (request?.cashing?.paymentMethod?.label === "GRATUIT") {
            return false
        }

        if (request?.bill === null || request?.bill === undefined) {
            return false
        }

        if (request?.bill?.sendDate !== undefined && request?.bill?.sendDate !== null) {
            return false
        }

        if ((request?.cashing === null || request?.cashing === undefined)) {
            return false
        }

        return true
    }

    canSendTheFeedbackGoogle(request) {

        if (request?.bill === null || request?.bill === undefined) {
            return false
        }

        if ((request?.cashing === null || request?.cashing === undefined)) {
            return false
        }
        
        if (request?.cashing?.paymentMethod?.label === "GRATUIT") {
            return false
        }

        if (request?.folder?.feedbackGoogleMail !== undefined && request?.folder?.feedbackGoogleMail !== null) {
            return false
        }

        return true
    }
}