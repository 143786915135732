import { Component } from "react";
import { withRouter } from "react-router";

import { Alert, Button, Form, InputGroup, Modal, Table, Row, Col } from "react-bootstrap";

import filepdfsvg from "../images/file-pdf.svg"
import editFolder from "../images/pen-fill.svg"
import envelopefillsvg from "../images/envelope-fill.svg";
import ClientBillingApi from "./swagger/ClientBillingApi";
import BikingGif from "../images/biking.gif";
import trashSvg from "../images/trash-fill.svg";
import Cookies from "universal-cookie";


class FolderDetailBill extends Component {

    swagger = new ClientBillingApi()

    state = {
        showSaveScreenModal: false,
    }

    componentDidMount() {
        var mechanicIdCookie = new Cookies().get("mechanic");
        this.swagger.retrieveMechanic(mechanicIdCookie)
            .then(res => {
                this.setState({
                    detail: this.props.detail,
                    mechanic: res.data
                })
            })
            .catch(error => alert(error))


    }

    componentWillReceiveProps(nextProps) {
        var mechanicIdCookie = new Cookies().get("mechanic");

        var detail = nextProps.detail

        this.setState({ detail: detail })

        if (mechanicIdCookie !== undefined
            && this.state?.mechanic?.id !== parseInt(mechanicIdCookie)) {
            this.swagger.retrieveMechanic(mechanicIdCookie)
                .then(res => {
                    this.setState({
                        detail: detail,
                        mechanic: res.data
                    })
                })
                .catch(error => alert(error))
        }
    }

    createBill = (event) => {
        event.preventDefault()
        this.setState({
            showSaveScreenModal: true
        })

        this.swagger.createTheBill(this.state?.detail?.submission, this.state.prodDuration, this.state.prodDifficulties)
            .then(res => {
                var detail = this.state.detail
                detail.bill = res.data
                this.setState({
                    detail: detail,
                    showSaveScreenModal: false
                })
            })
            .catch(error => alert(error))
    }

    sendTheBill = () => {
        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours",
                body: "..."
            }
        })
        this.swagger.sendTheBill(this.state?.detail?.folder.id)
            .then(res => {
                var detail = this.state.detail
                detail.bill = res.data
                this.setState({ detail: detail, modalDetail: { show: true, title: "Envoie réussie", body: "La facture a été envoyée" } })
            })
            .catch(error => this.setState({ modalDetail: { show: true, title: "Echec de l'envoie", body: error.message } }))
    }

    editTheBill = () => {
        this.props.history.push("/bill/".concat(this.state?.detail?.folder.id))
    }

    downlaodTheBill = () => {
        this.swagger.saveBillAsPdf(this.state?.detail?.folder.id)
            .then((response) => {
                const source = "data:application/pdf;base64,".concat(response.data.second);
                const link = document.createElement('a');
                link.href = source;

                link.download = response.data.first; //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => alert(error))
    }

    handleProdDuration = (event) => {
        var duration = this.returnNumberOrUndefined(event.target.value)
        this.setState({ prodDuration: duration })
    }

    returnNumberOrUndefined(number) {
        if (number === "") {
            return undefined
        }
        else {
            return parseFloat(number)
        }
    }

    handleProdDifficulties = (event) => {
        this.setState({ prodDifficulties: event.target.value })
    }

    alertEncaissement = () => {
        alert("Vous devez faire l'encaissement pour effectuer cette action")
    }

    deleteBill = () => {
        if (window.confirm("Action définitive, êtes vous sûr?")) {
            this.swagger.deleteBill(this.state.detail.bill.id)

            var stateCurrent = this.state
            stateCurrent.detail.bill = null
            this.setState(stateCurrent)
        }
    }

    render() {
        return <Table>
            <thead>
                <tr>
                    <th>
                        #
                    </th>
                    <th>
                        date création
                    </th>
                    <th>
                        date e-mail
                    </th>
                    <th>
                        Mécano
                    </th>
                    <th className="text-align-right table-info" style={{ width: "50px" }}>
                        MO
                    </th>
                    <th className="text-align-right table-info" style={{ width: "50px" }}>
                        pièce
                    </th>
                    <th className="text-align-right table-info" style={{ width: "50px" }}>
                        Total
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                        Actions
                    </th>
                </tr>
            </thead>

            <tbody>
                {this.state?.detail?.bill !== null ?
                    <tr>
                        <td>
                            {this.state?.mechanic?.role === "admin" ?
                                <Button
                                    variant="danger"
                                    onClick={this.deleteBill}
                                    title="Supprimer la facture"
                                >
                                    <img src={trashSvg} alt="Supprimer" />
                                </Button> : <></>}
                            {this.state?.detail?.bill?.id}
                        </td>
                        <td>
                            {this.state?.detail?.bill?.date !== undefined ? new Intl.DateTimeFormat("fr-FR", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                            }).format(new Date(this.state.detail?.bill?.date)) : ""}
                        </td>
                        <td>
                            {this.state?.detail?.bill?.sendDate !== undefined ? new Intl.DateTimeFormat("fr-FR", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                            }).format(new Date(this.state.detail?.bill?.sendDate)) : ""}
                        </td>
                        <td>
                            {this.state?.detail?.bill?.mechanic?.name}{" "}
                            {this.state?.detail?.bill?.mechanic?.surname}
                        </td>
                        <td className="text-align-right table-info">
                            {this.state?.detail?.bill?.labour}
                        </td>
                        <td className="text-align-right table-info">
                            {this.state?.detail?.bill?.piece}
                        </td>
                        <td className="text-align-right table-info">
                            <b>{this.state?.detail?.bill?.total}</b>
                        </td>
                        <td>
                            <div className="float-end">
                                <InputGroup>
                                    <Button
                                        variant={(this.state?.detail?.bill?.sendDate === null
                                            || this.state?.mechanic?.role === "admin") ? "warning" : "secondary"}
                                        onClick={
                                            (this.state?.detail?.bill?.sendDate === null
                                                || this.state?.mechanic?.role === "admin") ? this.editTheBill : () => alert("Facture déjà envoyée, non modifiable")}
                                        title="Editer la facture"
                                    >
                                        <img src={editFolder} alt="edit" />
                                    </Button>
                                    <Button
                                        variant={((this.state?.detail?.cashing !== null
                                            && this.state?.detail?.cashing !== undefined)
                                            || this.state?.mechanic?.role === "admin") ? "danger" : "secondary"}
                                        onClick={
                                            ((this.state?.detail?.cashing !== null && this.state?.detail?.cashing !== undefined)
                                                || this.state?.mechanic?.role === "admin") ? this.downlaodTheBill : this.alertEncaissement
                                        }
                                        title="Créer le PDF"
                                    >
                                        <img src={filepdfsvg} alt="pdf" />
                                    </Button>
                                    <Button
                                        variant={(this.state?.detail?.cashing !== null
                                            && this.state?.detail?.cashing !== undefined) ? "info" : "secondary"}
                                        onClick={
                                            (this.state?.detail?.cashing !== null
                                                && this.state?.detail?.cashing !== undefined) ? this.sendTheBill :
                                                this.alertEncaissement
                                        }
                                        title="Envoyer la facture"
                                    >
                                        <img src={envelopefillsvg} alt="envoyer" />
                                    </Button>
                                </InputGroup>
                                <Modal show={this.state?.modalDetail?.show}
                                    onHide={() => this.setState({ modalDetail: { show: false } })}>
                                    <Modal.Header closeButton>
                                        {this.state?.modalDetail?.title}
                                    </Modal.Header>
                                    <Modal.Body>
                                        {this.state?.modalDetail?.body}
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </td>
                    </tr>
                    :
                    <tr>
                        <td colSpan="100">
                            <Alert variant="warning">
                                <Alert.Heading>
                                    <b>Pas de Facture en cours</b>
                                </Alert.Heading>
                                {this.state?.detail?.acceptedSubmission !== null
                                    && this.state?.detail?.acceptedSubmission !== undefined
                                    && this.state?.detail?.acceptedSubmission?.denied !== -1 ?

                                    <Form onSubmit={this.createBill}>
                                        <Row className="mb-3">
                                            <Col>
                                                <Form.Label>Temps passé sur le vélo <i>(Obligatoire)</i></Form.Label>
                                                <Form.Control
                                                    value={this.state?.prodDuration}
                                                    onChange={this.handleProdDuration}
                                                    required />
                                                <Form.Label>Difficultées rencontrées</Form.Label>
                                                <Form.Control
                                                    value={this.state?.prodDifficulties}
                                                    as="textarea"
                                                    onChange={this.handleProdDifficulties} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col >
                                                <Button
                                                    disabled={this.state.showSaveScreenModal}
                                                    type="submit"
                                                    className="float-end"
                                                    variant="primary"
                                                    title="Créer facture" >
                                                    Créer à partir du "devis validé"
                                                </Button>

                                                <Modal show={this.state.showSaveScreenModal}>
                                                    <Modal.Header>
                                                        Création de la facture en cours
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <img src={BikingGif} alt="Création en cours..." />
                                                    </Modal.Body>
                                                </Modal>

                                            </Col>
                                        </Row>
                                    </Form>

                                    : <><br />Le devis doit être validé par le client pour pouvoir créer la facture</>
                                }

                            </Alert>
                        </td>
                    </tr>}
            </tbody>
        </Table >
    }
}
export default withRouter(FolderDetailBill)