import { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import DateUtils from "../utils/DateUtils";
import envelopefillsvg from "../../images/envelope-fill.svg";
import BillValidator from "./BillValidator";

export default class RSBillCell extends Component {

    dateUtils = new DateUtils()
    billValidator = new BillValidator()

    componentDidMount() {
        this.setState({ 
            request: this.props?.request, 
            index:this.props?.index  
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.request !== this.props.request) {
            this.setState({ 
                request: this.props?.request, 
                index:this.props?.index 
            })
        }
    }

    sendTheBill = (index) => {
        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours",
                body: "..."
            }
        })
        this.swagger.sendTheBill(this.state?.folders[index]?.folder.id)
            .then(res => {
                var folders = this.state.folders
                folders[index].bill = res.data
                this.setState({ folders: folders, modalDetail: { show: true, title: "Envoie réussie", body: "La facture a été envoyée" } })
            })
            .catch(error => this.setState({ modalDetail: { show: true, title: "Echec de l'envoie", body: error.message } }))
    }

    
    alertEncaissement = () => {
        alert("Il manque soit la facture, soit l'encaissement ou alors ce dernier est Gratuit")
    }

    render() {
        var bg = ""
        var request = this.state?.request
        var index = this.state?.index
        var totalBillAmount = request?.bill?.total
        var totalCashingAmount = request?.cashing?.amountCashingLabour + request?.cashing?.amountCashingPieces

        if (totalBillAmount === totalCashingAmount) {
            bg = "bg-success color-white"
        }        

        return <Row>
            <Col xs="6" className={bg}>
                <Row>
                    <Col xs="5">
                        Fact :
                    </Col>
                    <Col xs="7">
                        {totalBillAmount}
                    </Col>
                </Row>
                <Row>
                    <Col xs="5">
                        Enc :
                    </Col>
                    <Col xs="7">
                        {isNaN(totalCashingAmount) ? "" : totalCashingAmount}
                    </Col>
                </Row>
                <Row className={request?.cashing?.paymentMethod?.label === "LIQUIDE" ? "bg-warning text-dark" : ""}>
                    <Col xs="5">

                    </Col>
                    <Col xs="7">
                        {request?.cashing?.paymentMethod?.label}
                    </Col>
                </Row>
            </Col>
            <Col xs="6">
                {this.dateUtils.toReadableDate(request?.bill?.sendDate) !== "" ?
                    this.dateUtils.toReadableDate(request?.bill?.sendDate) :

                    <Button
                        variant={this.billValidator.canSendTheBill(request) ? "info" : "secondary"}
                        onClick={this.billValidator.canSendTheBill(request) ? () => this.sendTheBill(index) : this.alertEncaissement}
                        title={this.billValidator.canSendTheBill(request) ? "Envoyer la facture" : "Pas d'encaissement ou de facture ou GRATUIT"}
                    >
                        <img src={envelopefillsvg} alt="envoyer" />
                    </Button>
                }
            </Col>
        </Row>
    }
}