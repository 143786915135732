import { Component } from "react";
import ProductLine from "./ProductLine";
import ClientBillingApi from "./swagger/ClientBillingApi";
import { Table, Button, Modal, Form, Col, Row, InputGroup } from "react-bootstrap";

export default class Products extends Component {

    swagger = new ClientBillingApi()

    state = {
        showModal: false,
        product: {
            family: {
                id: undefined
            }
        },
        tri: "usedIndex"
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.idFamily !== null && nextProps.idFamily !== this.state.product.family.id) {
            this.swagger.retrieveProductForFamily(nextProps.idFamily, this.state?.tri)
                .then(res => {
                    this.setState({ familyId: nextProps.idFamily, familyName: nextProps.familyName, products: res.data, showModal: false })
                })
                .catch(() => {
                    this.setState({
                        products: undefined,
                        familyId: undefined,
                        familyName: undefined,
                        product: {
                            family: {
                                id: undefined
                            }
                        },
                        showModal: false,
                    })
                })
        }
    }

    handleUpdate = (event) => {
        event.preventDefault();
        var productToSave = this.state.product
        productToSave.family.id = this.state.familyId
        productToSave.family.name = this.state.familyName
        this.swagger.saveProduct(this.state.product)
            .then(res => {
                var products = this.state.products
                products.push(res.data)
                this.setState({
                    products: products,
                    product: {
                        family: {
                            id: undefined
                        }
                    },
                    showModal: false
                })
            })
            .catch(error => alert(error))
    }

    handleProductCode = (event) => {
        var product = this.state.product
        product.code = event.target.value
        this.setState({ product: product });
    }

    handleProductEan = (event) => {
        var product = this.state.product
        product.barcode = event.target.value
        this.setState({ product: product });
    }

    handleProductName = (event) => {
        var product = this.state.product
        product.name = event.target.value
        this.setState({ product: product });
    }

    handleProductBuyingPrice = (event) => {
        var product = this.state.product
        product.taxedBuyingPrice = parseFloat(event.target.value)
        this.setState({ product: product });
    }

    handleProductSoldPrice = (event) => {
        var product = this.state.product
        product.taxedSoldPrice = parseFloat(event.target.value)
        this.setState({ product: product });
    }

    handleLabourPriceReal = (event) => {
        var product = this.state.product
        product.labourPriceReal = parseFloat(event.target.value)
        this.setState({ product: product });
    }

    handleLabourPriceSold = (event) => {
        var product = this.state.product
        product.labourPriceSold = parseFloat(event.target.value)
        this.setState({ product: product });
    }

    handleTri = (event) => {
        this.swagger.retrieveProductForFamily(this.state?.familyId, event.target.value)
            .then(res => {
                this.setState({
                    familyId: this.state?.familyId,
                    familyName: this.state?.familyName,
                    products: res.data,
                    showModal: false,
                    tri: event.target.value,
                })
            })
            .catch(() => {
                this.setState({
                    products: undefined,
                    familyId: undefined,
                    familyName: undefined,
                    product: {
                        family: {
                            id: undefined
                        }
                    },
                    showModal: false,
                })
            })
    }

    render() {
        if (this.state?.products !== undefined) {
            const productLine = this.state?.products?.map((it) => (
                <ProductLine key={it.id} product={it} onChangeValue={this.props.onChangeValue} />
            ));

            const addButton = <Button type="button" onClick={() => this.setState({ showModal: true })}>
                + Nouveau Produit
            </Button>

            return (
                <>
                    <div className="sticky-head">
                        <Row>
                            <Col>
                                {addButton}
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text>
                                        Tri
                                    </InputGroup.Text>
                                    <Form.Select value={this.notState?.tri} onChange={this.handleTri}>
                                        <option value="usedIndex">Top vente</option>
                                        <option value="name">Nom du produit</option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>

                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                {this.props.onChangeValue === undefined ?
                                    <th>
                                        Code produit
                                    </th> : ""}
                                <th>
                                    Nom produit
                                </th>
                                <th className="text-align-right">
                                    MO
                                </th>
                                <th className="text-align-right">
                                    Pièce
                                </th>
                                {this.props.onChangeValue === undefined ?
                                    <th className="text-align-right">
                                        EAN
                                    </th> : ""}
                                <th>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {productLine}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="100">
                                    <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
                                        <Modal.Header closeButton closeLabel="Fermer">
                                            <Modal.Title>Enregistrement d'un produit</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form onSubmit={this.handleUpdate}>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <Form.Label>
                                                            Code produit
                                                        </Form.Label>
                                                        <Form.Control
                                                            value={this.state.product?.code}
                                                            onChange={this.handleProductCode} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>
                                                            Code barre
                                                        </Form.Label>
                                                        <Form.Control
                                                            value={this.state.product?.barcode}
                                                            onChange={this.handleProductEan} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <Form.Label>
                                                            <b>Libellé du produit</b>
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            value={this.state.product?.name}
                                                            onChange={this.handleProductName} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <Form.Label>
                                                            Prix MO réel
                                                        </Form.Label><Form.Control
                                                            value={this.state.product?.labourPriceReal}
                                                            onChange={this.handleLabourPriceReal} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>
                                                            <b>Prix MO TTC</b>
                                                        </Form.Label><Form.Control
                                                            value={this.state.product?.labourPriceSold}
                                                            onChange={this.handleLabourPriceSold} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <Form.Label>
                                                            Prix d'achat TTC
                                                        </Form.Label><Form.Control
                                                            value={this.state.product?.taxedBuyingPrice}
                                                            onChange={this.handleProductBuyingPrice} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>
                                                            <b>Prix pièce TTC</b>
                                                        </Form.Label><Form.Control
                                                            value={this.state.product?.taxedSoldPrice}
                                                            onChange={this.handleProductSoldPrice} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <br />
                                                        <Button type="submit" className="float-end">Enregistrer</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Modal.Body>
                                    </Modal>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </>
            );
        }
        else {
            return "";
        }
    }
}