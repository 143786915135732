import {
    ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
    Tooltip
} from 'chart.js';
import { Component } from "react";
import { Accordion, Button, Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { withRouter } from "react-router-dom";
import CustomerBikeDetails from "./CustomerBikeDetails";
import CustomerCashoutTable from "./CustomerCashoutTable";
import CustomerCreation from "./CustomerCreation";
import CustomerDetails from "./CustomerDetails";
import ClientCustomerApi from "./swagger/ClientCustomerApi";
import DateUtils from "./utils/DateUtils";
ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
class Customer extends Component {

    customerSwagger = new ClientCustomerApi()
    dateUtils = new DateUtils()
    state = {}

    componentDidMount() {
        this.defaultState()
        if (this.props.match.params.customerId !== undefined) {
            this.setState({
                customerId: this.props.match.params.customerId
            })
            this.searchCustomer(this.props.match.params.customerId)
        }
    }

    defaultState() {
        this.options = {
            plugins: {
                title: {
                    display: true,
                    text: 'Encaissements',
                },
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },
        };
        this.labels = [];
        this.datas = {
            labels: this.labels,
            datasets: [],
        };

        this.setState({
            options: this.options,
            labels: this.labels,
            data: this.datas,
            criteria: {}
        })
    }

    componentDidUpdate(previousProp) {
        if (this.props.match.params.customerId !== previousProp.match.params.customerId) {
            this.defaultState()
            this.setState({
                customerId: this.props.match.params.customerId
            })
            this.searchCustomer(this.props.match.params.customerId)
        }
    }

    submitForm = (event) => {
        event.preventDefault()

        if (this.state.customerId !== undefined) {
            this.props.history.push("/client/".concat(this.state.customerId))
        } else if (this.state.criteria !== undefined) {
            this.customerSwagger.retrieveCustomerWithCriteria(this.state.criteria).then(res => {
                if (res.data.length === 1) {
                    this.props.history.push("/client/".concat(res.data[0].id))
                }

                if (res.data.length > 1) {
                    this.setState({
                        multipleCustomersFound: res.data
                    })
                }

                if (res.data.length === 0) {
                    this.setState({
                        noCustomerFounds: true
                    })
                }

            })
        }
    }

    searchCustomer(customerId) {
        this.defaultState()
        this.customerSwagger.retrieveLastsCustomerHistory(customerId)
            .then(res => {

                this.setState({
                    customerId: customerId,
                    customer: res.data?.customer,
                    bikesHistory: res.data?.bikesHistory,
                    multipleCustomersFound: undefined
                })
                this.updateChart(res)


            })
            .catch(error => {
                this.setState({
                    customerId: customerId,
                    customer: undefined,
                    bikesHistory: undefined,
                    multipleCustomersFound: undefined
                })
                alert("Ce client rencontre un pb d'intégrité de données \n" + error.message)
            }
            )
    }

    random_rgba() {
        var o = Math.round, r = Math.random, s = 200;
        return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + 0.8 + ')';
    }

    updateChart(res) {
        var labels = []
        var totalCashingAmount = 0
        var totalCahsoutLabour = 0
        var totalCashoutPieces = 0
        var cashoutCounter = 0
        var cashouts = []

        res.data?.bikesHistory?.forEach(history => {

            history.folders?.forEach((folder) => {
                if (folder?.cashing?.cashingDate !== undefined) {

                    var month = this.dateUtils.toMonthYearDate(folder?.cashing?.cashingDate)
                    var indexAlreadyInIt = -1
                    var totalAmount = folder?.cashing?.amountCashingLabour + folder?.cashing?.amountCashingPieces || 0
                    if (folder?.cashing !== null) {
                        cashouts.push(folder?.cashing)
                        cashoutCounter++
                    }

                    totalCahsoutLabour += folder?.cashing?.amountCashingLabour || 0
                    totalCashoutPieces += folder?.cashing?.amountCashingPieces || 0

                    labels.forEach((label, index) => {
                        if (label.month === month) {
                            indexAlreadyInIt = index
                        }
                    })

                    if (indexAlreadyInIt === -1) {
                        labels.push({
                            month: month,
                            date: folder?.cashing?.cashingDate,
                            totalAmount: totalAmount
                        })
                    }
                    else {
                        labels[indexAlreadyInIt].totalAmount += totalAmount
                    }

                    totalCashingAmount += totalAmount

                    indexAlreadyInIt = -1
                }

            })
        })

        labels = labels.sort((a, b) => new Date(a.date) - new Date(b.date))

        var datasets = res.data.bikesHistory
            .map(bikeHistory => {
                return {
                    label: bikeHistory.bike.id + " : " + bikeHistory.bike.brand + " " + bikeHistory.bike.color,
                    data: labels
                        .map(label => {
                            var valueForThisMonth = 0

                            bikeHistory.folders?.forEach(folder => {
                                var month = this.dateUtils.toMonthYearDate(folder?.cashing?.cashingDate)

                                if (month === label.month) {
                                    valueForThisMonth += folder?.cashing?.amountCashingLabour + folder?.cashing?.amountCashingPieces
                                }

                            })

                            return valueForThisMonth
                        }),
                    backgroundColor: this.random_rgba()
                }
            })


        var folderCounter = this.state?.bikesHistory
            ?.map(history => history.folders.length)
            ?.reduce((accumVariable, curValue) => {
                return accumVariable + curValue
            }, 0)

        var datas = {
            labels: labels.map(label => label.month + " (" + label.totalAmount + "€)"),
            datasets: datasets
        }

        var datasPie = {
            labels: ["Encaissé", "Refus"],
            datasets: [{
                label: "Nb de dossier",
                data: [this.getPercent(cashoutCounter, folderCounter), this.getPercent(folderCounter - cashoutCounter, folderCounter)],
                backgroundColor: [
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                ],
            }]
        }

        this.setState({
            cashoutChart: {
                labels: labels,
                data: datas,
            },
            pieChart: datasPie,
            cashouts: cashouts,
            totalCashingAmount: totalCashingAmount,
            totalCahsoutLabour: totalCahsoutLabour,
            totalCashoutPieces: totalCashoutPieces,
            lastCashoutDate: labels[labels.length - 1]?.date
        })
    }

    getPercent(value, total) {
        return value / total * 100
    }

    render() {
        return <Container>

            <Modal size="xl" show={this.state?.multipleCustomersFound?.length > 1} onHide={() => this.setState({ multipleCustomersFound: undefined })}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.state?.multipleCustomersFound?.length} clients ont été trouvés pour les critères de recherche
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state?.multipleCustomersFound?.map(elt =>
                        <Row>
                            <Col>{elt.name}</Col>
                            <Col>{elt.surname}</Col>
                            <Col>{elt.phoneNumber}</Col>
                            <Col>{elt.mail}</Col>
                            <Col>
                                <Button onClick={() => this.searchCustomer(elt.id)}>Sélectionner</Button>
                            </Col>
                        </Row>)}
                </Modal.Body>
                <Modal.Body>
                    <Modal.Title>
                        C'est un nouveau client?
                    </Modal.Title>
                    <CustomerCreation />
                </Modal.Body>
            </Modal>

            <Modal size="xl" show={this.state?.noCustomerFounds} onHide={() => this.setState({ noCustomerFounds: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Il ne semble pas y avoir de client pour cette recherche
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title>
                        C'est un nouveau client?
                    </Modal.Title>
                    <CustomerCreation />
                </Modal.Body>
            </Modal>

            <Row>
                <Col>
                    <Card>
                        <Form onSubmit={this.submitForm}>
                            <Card.Header>
                                Rechercher un client
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md="2">
                                        <Form.Label>
                                            Numéro du client
                                        </Form.Label>
                                        <Form.Control value={this.state.customerId !== undefined ? this.state.customerId : ""}
                                            onChange={(event) => this.setState({ customerId: event.target.value, criteria: {} })}>

                                        </Form.Control>
                                    </Col>
                                    <Col md="1">Ou</Col>
                                    <Col s="3">
                                        <Form.Label>
                                            Nom
                                        </Form.Label>
                                        <Form.Control
                                            value={this.state?.criteria?.surname !== undefined ? this.state?.criteria?.surname : ""}
                                            onChange={(event) => {
                                                var criteria = this.state.criteria
                                                criteria.phoneNumber = undefined
                                                criteria.surname = event.target.value === "" ? undefined : event.target.value
                                                this.setState({
                                                    customerId: undefined,
                                                    criteria: criteria
                                                })
                                            }}>

                                        </Form.Control>
                                    </Col>
                                    <Col s="3">
                                        <Form.Label>
                                            Prénom
                                        </Form.Label>
                                        <Form.Control
                                            value={this.state?.criteria?.name !== undefined ? this.state?.criteria?.name : ""}
                                            onChange={(event) => {
                                                var criteria = this.state.criteria
                                                criteria.phoneNumber = undefined
                                                criteria.name = event.target.value === "" ? undefined : event.target.value
                                                this.setState({
                                                    customerId: undefined,
                                                    criteria: criteria
                                                })
                                            }}>

                                        </Form.Control>
                                    </Col>
                                    <Col md="1">Ou</Col>
                                    <Col md="2">
                                        <Form.Label>
                                            Téléphone
                                        </Form.Label>
                                        <Form.Control
                                            value={this.state?.criteria?.phoneNumber !== undefined ? this.state?.criteria?.phoneNumber : ""}
                                            onChange={(event) => {
                                                var criteria = this.state.criteria
                                                criteria.name = criteria.surname = undefined
                                                criteria.phoneNumber = event.target.value === "" ? undefined : event.target.value
                                                this.setState({
                                                    customerId: undefined,
                                                    criteria: criteria
                                                })
                                            }}>

                                        </Form.Control>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-align-right">
                                <Button type="submit">
                                    Rechercher
                                </Button>
                            </Card.Footer>
                        </Form>
                    </Card>
                    <CustomerDetails
                        customer={this.state.customer}
                        cashouts={this.state.cashouts}
                        bikesHistory={this.state.bikesHistory}
                        pieChart={this.state.pieChart}
                        totalCashingAmount={this.state.totalCashingAmount}
                        totalCahsoutLabour={this.state.totalCahsoutLabour}
                        totalCashoutPieces={this.state.totalCashoutPieces}
                        lastCashoutDate={this.state.lastCashoutDate}
                    />
                    {this.state.cashouts !== undefined ?
                        <>
                            <Card>
                                <Card.Header>
                                    Encaissements
                                </Card.Header>
                                <Card.Body>
                                    <CustomerCashoutTable
                                        cashouts={this.state.cashouts} />
                                    {this.state?.cashoutChart !== undefined ? <Bar options={this.state?.options} data={this.state?.cashoutChart?.data} /> : <></>}
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>
                                    Encaissements par vélo
                                </Card.Header>
                                <Card.Body>
                                    <Accordion defaultActiveKey={0}>
                                        {this.state?.bikesHistory?.map((history, index) => (
                                            <CustomerBikeDetails bikeHistory={history} index={index} customer={this.state.customer} />
                                        ))}
                                    </Accordion>
                                </Card.Body>
                            </Card>
                        </>
                        : <></>}
                </Col>
            </Row>
        </Container >
    }
}

export default withRouter(Customer);