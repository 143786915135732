import { Component } from "react";
import { Card, Container } from "react-bootstrap";
import StatisticsShop from "./StatisticsShop";
import StatisticsMechanics from "./StatisticsMechanics";
import StatisticsCustomers from "./StatisticsCustomers";

export default class Statistics extends Component {



    render() {
        return <Container>

            <StatisticsShop />

            <StatisticsMechanics />

            <StatisticsCustomers />

        </Container>
    }
}
