import { Component } from "react"
import { Button, Table } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"
import ClientCustomerApi from "./swagger/ClientCustomerApi"
import DateUtils from "./utils/DateUtils"
import filepdfsvg from "../images/file-pdf.svg"

class CustomerCashoutTable extends Component {

    dateUtils = new DateUtils()
    state = {}
    customerSwagger = new ClientCustomerApi()


    componentDidMount() {
        this.setState({ cashouts: this.props.cashouts })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cashouts !== this.props.cashouts) {
            this.setState({ cashouts: this.props.cashouts })
        }
    }

    downlaodThePDF = (cashout) => {
        this.customerSwagger.retrievePDFData(cashout.customerIdNbs, cashout.bikeId, cashout.id)
            .then((response) => {
                const source = "data:application/pdf;base64,".concat(response.data.second);
                const link = document.createElement('a');
                link.href = source;

                link.download = response.data.first; //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => alert(error))
    }

    render() {
        return (
            <Table>
                <thead>
                    <tr>
                        <th style={{ display: this.props.showAll === false ? "none" : "" }}>
                            ID
                        </th>
                        <th>
                            Date
                        </th>
                        <th>
                            N° Dossier
                        </th>
                        <th style={{ display: this.props.showAll === false ? "none" : "" }}>
                            N° Velo
                        </th>
                        <th className="text-align-right table-info">
                            MO
                        </th>
                        <th className="text-align-right table-info">
                            Pieces
                        </th>
                        <th className="text-align-right table-info">
                            Total
                        </th>
                        <th style={{ display: this.props.showAll === false ? "none" : "" }}>
                            Paiement
                        </th>
                        <th style={{ display: this.props.showAll === false ? "none" : "" }}>
                            Gratuite
                        </th>
                        <th>
                            No facture
                        </th>
                        <th>
                            PDF
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state?.cashouts
                            ?.sort((cashout1, cashout2) => new Date(cashout2.cashingDate) - new Date(cashout1.cashingDate))
                            .map(cashout => <tr>
                                <td style={{ display: this.props.showAll === false ? "none" : "" }}>
                                    {cashout?.id}
                                </td>
                                <td>
                                    {this.dateUtils.toReadableDate(cashout?.cashingDate)}
                                </td>
                                <td>
                                    <Link to={"/dossier/".concat(cashout?.folder?.id)}>{cashout?.folder?.id}</Link>
                                </td>
                                <td style={{ display: this.props.showAll === false ? "none" : "" }}>
                                    {cashout?.bikeId}
                                </td>
                                <td className="text-align-right table-info">
                                    {cashout?.amountCashingLabour}
                                </td>
                                <td className="text-align-right table-info">
                                    {cashout?.amountCashingPieces}
                                </td>
                                <td className="text-align-right table-info">
                                    {cashout?.amountCashingLabour + cashout?.amountCashingPieces}
                                </td>
                                <td style={{ display: this.props.showAll === false ? "none" : "" }}>
                                    {cashout?.paymentMethod?.label}
                                </td>
                                <td style={{ display: this.props.showAll === false ? "none" : "" }}>
                                    {cashout?.free === 1 ? <>&#x2713;</> : ""}
                                </td>
                                <td>
                                    {cashout?.customerBillId}
                                </td>
                                <td>
                                    <Button
                                        variant="danger"
                                        onClick={() => this.downlaodThePDF(cashout)}
                                        title="Télecharger le PDF"
                                    >
                                        <img src={filepdfsvg} alt="pdf" />
                                    </Button>
                                </td>
                            </tr>)
                    }
                </tbody>
            </Table>
        )
    }

}
export default withRouter(CustomerCashoutTable)