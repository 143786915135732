import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Cookies from "universal-cookie";
import ClientApi from "./swagger/ClientApi";
import ClientBillingApi from "./swagger/ClientBillingApi";
export default class Login extends Component {
  clientApi = new ClientApi();
  ClientBillingApi = new ClientBillingApi();

  state = {
    login: "",
    password: "",
    error: {
      status: "",
      message: "",
      hidden: true,
    },
  };

  componentDidMount() {
    this.ClientBillingApi.retrieveMechanics()
      .then(res => {
        this.setState({ mechanics: res.data })
      })
      .catch(err => alert(err))
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const cookies = new Cookies();

    this.clientApi
      .login(this.state.login, this.state.password)
      .then((res) => {
        cookies.set("token-billing-nbs", res.data.token);
        cookies.set("mechanic", this.state.mechanicId);
        window.location.href = "/";
      })
      .catch((err) => {
        this.setState({
          error: {
            status: err.response.status,
            message: err.response.data.error,
            hidden: false,
          },
        });
      });
  };

  handleLogin = (event) => {
    this.setState({ login: event.target.value, error: { hidden: true } });
  };

  handlePassword = (event) => {
    this.setState({ password: event.target.value, error: { hidden: true } });
  };

  handleMechanic = (event) => {
    this.setState({ mechanicId : event.target.value})
  }

  render() {
    return (
      <div>
        <nav class="navbar navbar-dark bg-orange">
          <div class="container-fluid">
            <span class="navbar-brand mb-0 h1">
              <h1>NBS : Application de gestion</h1>
            </span>
          </div>
        </nav>

        <div className="container-md login-box">
          <form onSubmit={this.handleSubmit}>
            <div className="card">
              <div className="card-header">Connexion</div>
              <div className="card-body">
                <div className="mb-3">
                  <label>Identifiant</label>
                  <input
                    type="text"
                    required="required"
                    name="identifiant"
                    className="form-control"
                    value={this.state.login}
                    onChange={this.handleLogin}
                  ></input>
                </div>
                <div className="mb-3">
                  <label>Mot de passe</label>
                  <input
                    type="password"
                    name="password"
                    required="required"
                    value={this.state.password}
                    className="form-control"
                    onChange={this.handlePassword}
                  ></input>
                </div>
                <div className="mb-3">
                  <label>Méchacien</label>
                  <Form.Select
                    onChange={this.handleMechanic}
                    value={this.state?.mechanicId}>
                    <option>-- mécaniciens --</option>
                    {this.state?.mechanics?.map(mecha => (
                      <option value={mecha.id} disabled={!mecha.active}>{mecha.name} {mecha.surname}</option>
                    ))}
                  </Form.Select>
                </div>
                <div className="mb-3 float-end">
                  <input
                    type="submit"
                    value="Envoyer"
                    className="btn btn-primary"
                  />
                </div>
              </div>
            </div>
          </form>
          <div
            id="login-alert"
            className="alert alert-danger"
            role="alert"
            hidden={this.state.error.hidden}
          >
            <div>
              <h1>{this.state.error.status}</h1>
              <p>{this.state.error.message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
