import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Svg.css';


import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Menu from './components/Menu';
import Submission from "./components/Submission";
import FileSearch from './components/FileSearch';
import ProductManager from './components/ProductManager';
import Cookies from "universal-cookie";
import Login from "./components/Login";
import { Component } from 'react';
import Mechanics from './components/Mechanics';
import BillEdition from './components/BillEdition';
import RepairShop from './components/repairshop/RepairShop';
import ClientApi from './components/swagger/ClientApi';
import Customer from './components/Customer';
import Statistics from './components/statistics/Statistics';

export default class App extends Component {
  cookies = new Cookies()
  swagger = new ClientApi()
  token = this.cookies.get("token-billing-nbs");
  state = {tokenURL : this.cookies.get("token-url-nbs")}

  componentDidMount() {
    this.swagger.validateUrl().then(() => this.setState({tokenURL : this.cookies.get("token-url-nbs")})
    )
  }

  render() {
    if (this.state.tokenURL === undefined) {
        return <div><h1>Récupération de l'url du serveur en cours...</h1></div>
    } else if (!this.token) {
      return (
        <Login />
      );
    } else {
      return (
        <div className="App">
          <Router>
            <Menu />
            <div className="pva-body">
              <Switch>
                <Route exact path="/product-manager" >
                  <ProductManager />
                </Route>
                <Route exact path="/submission/:fileId" component={Submission} />
                <Route exact path="/bill/:fileId" component={BillEdition} />
                <Route exact path="/mecaniciens" component={Mechanics} />
                <Route exact path="/statistics" component={Statistics} />
                <Route exact path="/dossier/:fileId" component={FileSearch} />
                <Route exact path="/Atelier" component={RepairShop} />
                <Route exact path="/client" component={Customer} />
                <Route exact path="/client/:customerId" component={Customer} />
                <Route exact path="/">
                  <FileSearch />
                </Route>
              </Switch>
            </div>
          </Router>
        </div>
      );
    }
  }
}
