import { Component } from "react"
import { Badge, Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap"
import { Pie } from "react-chartjs-2"
import { withRouter } from "react-router-dom"
import CustomerRequest from "./CustomerRequest"
import ClientCustomerApi from "./swagger/ClientCustomerApi"
import DateUtils from "./utils/DateUtils"

class CustomerDetails extends Component {

    dateUtils = new DateUtils()
    clientApi = new ClientCustomerApi()

    componentDidMount() {
        this.resetSate()
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.resetSate()
        }
    }

    resetSate() {
        var state = {}
        state = {
            customer: this.props.customer,
            bikesHistory: this.props.bikesHistory,
            pieChart: this.props.pieChart,
            cashouts: this.props.cashouts,
            totalCashingAmount: this.props.totalCashingAmount,
            totalCahsoutLabour: this.props.totalCahsoutLabour,
            totalCashoutPieces: this.props.totalCashoutPieces,
            lastCashoutDate: this.props.lastCashoutDate,
            showNewRequestModal: false
        }

        this.setState(state)
    }

    handleChangeGender = (event) => {
        let customer = this.state.customer;
        customer.genre = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeName = (event) => {
        let customer = this.state.customer;
        customer.name = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeSurname = (event) => {
        let customer = this.state.customer;
        customer.surname = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeAddress = (event) => {
        let customer = this.state.customer;
        customer.addressFirstLine = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeZipCode = (event) => {
        let customer = this.state.customer;
        customer.zipCode = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeCity = (event) => {
        let customer = this.state.customer;
        customer.city = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangeMail = (event) => {
        let customer = this.state.customer;
        customer.mail = event.target.value;
        this.setState({ customer: customer });
    }

    handleChangePhoneNumber = (event) => {
        let customer = this.state.customer;
        customer.phoneNumber = event.target.value;
        this.setState({ customer: customer });
    }

    saveCustomer = (event) => {
        event.preventDefault()
        this.clientApi.saveCustomer(this.state.customer)
    }

    newRequest = () => {
        var state = this.state
        state.showNewRequestModal = true
        this.setState(state)
    }

    render() {
        return this.state?.customer !== undefined ?
            <>
                <Form onSubmit={this.saveCustomer}>
                    <Card>
                        <Card.Header>
                            <Badge className="bg-dark">{this.state.customer?.id}</Badge>{" "}Détails du client
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Card.Title>Civilité</Card.Title>
                                            <Row>
                                                <Col className="small-margin-top-bottom">
                                                    <Form.Control as="select"
                                                        className="form-select"
                                                        required
                                                        value={this.state.customer.genre}
                                                        onChange={this.handleChangeGender} >
                                                        <option value="1">Monsieur</option>
                                                        <option value="2">Madame</option>
                                                    </Form.Control>
                                                </Col>
                                                <Col className="small-margin-top-bottom">
                                                    <Form.Control as="input"
                                                        placeholder="prénom"
                                                        required
                                                        value={this.state.customer.name}
                                                        onChange={this.handleChangeName} />
                                                </Col>
                                                <Col className="small-margin-top-bottom">
                                                    <Form.Control as="input"
                                                        placeholder="nom"
                                                        required
                                                        value={this.state.customer.surname}
                                                        onChange={this.handleChangeSurname} />
                                                </Col>
                                            </Row>
                                            <Card.Title>Adresse</Card.Title>
                                            <Row>
                                                <Col className="mb-1">
                                                    <Form.Control as="input"
                                                        placeholder="numéro, rue"
                                                        required
                                                        value={this.state.customer.addressFirstLine}
                                                        onChange={this.handleChangeAddress} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-1">
                                                    <Form.Control as="input"
                                                        placeholder="code postal"
                                                        required
                                                        value={this.state.customer.zipCode}
                                                        onChange={this.handleChangeZipCode} />
                                                </Col>
                                                <Col className="mb-1">
                                                    <Form.Control as="input"
                                                        placeholder="ville"
                                                        required
                                                        value={this.state.customer.city}
                                                        onChange={this.handleChangeCity} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Card.Title>Contact</Card.Title>
                                            <Form.Control as="input"
                                                placeholder="e-mail"
                                                className="mb-1"
                                                required
                                                value={this.state.customer.mail}
                                                onChange={this.handleChangeMail} />
                                            <Form.Control as="input"
                                                placeholder="numéro de téléphone"
                                                className="mb-1"
                                                required
                                                value={this.state.customer.phoneNumber}
                                                onChange={this.handleChangePhoneNumber} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Nb vélos</th>
                                                        <th>Dossiers</th>
                                                        <th>Cumul enc.</th>
                                                        <th>Dernier enc.</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {this.state?.customer?.bikes.length}
                                                        </td>
                                                        <td>
                                                            <Row>
                                                                <Col>Nb dos : </Col>
                                                                <Col className="text-align-right">
                                                                    {this.state?.bikesHistory
                                                                        ?.map(history => history.folders.length)
                                                                        ?.reduce((accumVariable, curValue) => {
                                                                            return accumVariable + curValue
                                                                        }, 0)}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col >Nb Enc : </Col>
                                                                <Col className="text-align-right">{this.state?.cashouts?.length}</Col>
                                                            </Row>
                                                            <Row>
                                                                <Col><b>% Enc : </b></Col>
                                                                <Col className="text-align-right">
                                                                    <b>
                                                                        {this.state?.cashouts?.length / this.state?.bikesHistory
                                                                            ?.map(history => history.folders.length)
                                                                            ?.reduce((accumVariable, curValue) => {
                                                                                return accumVariable + curValue
                                                                            }, 0) * 100}
                                                                    </b>
                                                                </Col>
                                                            </Row>

                                                        </td>
                                                        <td>
                                                            <Row>
                                                                <Col>MO</Col>
                                                                <Col className="text-align-right">{this.state.totalCahsoutLabour}</Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>Pièces</Col>
                                                                <Col className="text-align-right">{this.state.totalCashoutPieces}</Col>
                                                            </Row>
                                                            <Row>
                                                                <Col><b>Total</b></Col>
                                                                <Col className="text-align-right"><b>{this.state.totalCashingAmount}</b></Col>
                                                            </Row>
                                                        </td>
                                                        <td>
                                                            {this.dateUtils.toReadableDate(this.state.lastCashoutDate)}
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </Table>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="3">
                                    {this.state?.pieChart !== undefined ? <Pie data={this.state?.pieChart} /> : <></>}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Liens page photo : <a href={"https://www.nantesbikesolutions.com/galerie/".concat(this.state.customer.personalId)} target="_blank">
                                        {"https://www.nantesbikesolutions.com/galerie/".concat(this.state.customer.personalId)}
                                    </a>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-align-right">
                            <Button type="submit" variant="warning">
                                Enregistrer les infos du client
                            </Button>
                            {" "}
                            <Button type="button" onClick={this.newRequest}>
                                Créer une demande de prise en charge
                            </Button>
                        </Card.Footer>
                    </Card>


                </Form>
                <Modal size="xl" show={this.state.showNewRequestModal} onHide={() => {
                    var state = this.state
                    state.showNewRequestModal = false
                    this.setState(state)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Nouvelle demande
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CustomerRequest customer={this.state.customer} bikes={this.state.bikesHistory} />
                    </Modal.Body>
                </Modal>
            </> : ""
    }

}
export default withRouter(CustomerDetails)
