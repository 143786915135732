import { Component } from "react";
import ClientStatsApi from "../swagger/ClientStatsApi";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import StatisticsHelper from "./StatisticsHelper";
import Cookies from "universal-cookie";
import DateUtils from "../utils/DateUtils";
import ClientBillingApi from "../swagger/ClientBillingApi";

export default class StatisticsMechanics extends Component {

    statsApi = new ClientStatsApi()
    billingApi = new ClientBillingApi()
    dateHelper = new DateUtils()
    mechanicIdCookie = new Cookies().get("mechanic")
    helper = new StatisticsHelper()

    state = {}
    
    options = {
        plugins: {
          title: {
            display: true,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      }

    async componentDidMount() {

        const startDate = new Date(new Date().getFullYear() - 1, 0, 2);

        await this.billingApi.retrieveMechanics()
            .then(res => {
                this.setState({
                    mechanics: res.data,
                    request: {
                        mechanicId: this.mechanicIdCookie,
                        startDate: new Date(startDate).toISOString().slice(0, 10),
                        endDate: new Date().toISOString().slice(0, 10)
                    },
                    requestGeneral: {
                        startDate: new Date(startDate).toISOString().slice(0, 10),
                        endDate: new Date().toISOString().slice(0, 10)
                    }
                })
            })
            .catch(err => alert(err))

        this.showStats(this.state.request, this.state?.interval);
    }

    handleMechanic = (event) => {
        var state = this.state
        state.request.mechanicId = event.target.value
        this.setState(state)
    }

    handleStartDate = (event) => {
        var state = this.state
        state.request.startDate = event.target.value
        this.setState(state)
    }

    handleEndDate = (event) => {
        var state = this.state
        state.request.endDate = event.target.value
        this.setState(state)
    }

    handleInterval = (event) => {
        var state = this.state
        state.interval = event.target.value
        this.setState(state)
    }

    afficherStats = () => {
        this.showStats(this.state.request, this.state?.interval);
    }

    showStats(request, interval) {
        this.statsApi.retrieveStats(request)
            .then(res => {
                const datas = this.helper.aggregatePrices(res.data, interval);
                var futureState = this.state

                futureState.barChart = {
                    labels: datas.map(data => data.interval + "(" + (data.totalPrice1 + data.totalPrice2) + "€)"),
                    datasets: [
                        {
                            label: 'Main Oeuvre',
                            data: datas.map(data => data.totalPrice1),
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                            label: 'Pièces',
                            data: datas.map(data => data.totalPrice2),
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                    ],
                };

                this.setState(futureState);
            });
    }

    render() {
        return <Card>
            <Card.Header>
                <h2>Bilan par mécanicien</h2>
                <Row>
                    <Col>
                        <Form.Select
                            onChange={this.handleInterval}
                            value={this.state?.interval}
                            defaultValue="monthly">
                            <option value="annuel">Annuel</option>
                            <option value="monthly" selected>Mensuel</option>
                            <option value="weekly">Hebomadaire</option>
                            <option value="daily">Journalier</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Select
                            onChange={this.handleMechanic}
                            value={this.state?.request?.mechanicId}>
                            <option value={undefined}></option>
                            {this.state?.mechanics?.map(mecha => (
                                <option value={mecha.id} disabled={!mecha.active} >{mecha.name} {mecha.surname}</option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Control type="date"
                            value={this.state?.request?.startDate}
                            onChange={this.handleStartDate}
                        />
                    </Col>
                    <Col>
                        <Form.Control type="date"
                            value={this.state?.request?.endDate}
                            onChange={this.handleEndDate}
                        />
                    </Col>
                    <Col>
                        <Button type="button" onClick={this.afficherStats}>Afficher</Button>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                {this.state?.barChart !== undefined ? <Bar data={this.state?.barChart} options={this.options} ></Bar> : <></>}
            </Card.Body>
        </Card>
    }

}