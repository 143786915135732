import ClientApi from "./ClientApi";

export default class ClientBillingApi extends ClientApi {
    
  retrieveStats(request) {
    return this.postAxiosWithBody("stats/main", request);
  }

  retrieveBestCustomers() {
    return this.getAxios("stats/best-customers");
  }

}
