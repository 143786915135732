import { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ClientBillingApi from "./swagger/ClientBillingApi";

export default class Mechanic extends Component {

    swagger = new ClientBillingApi()

    componentDidMount() {
        this.setState(this.props.mechanic)
    }

    handleSubmit = (event) => {
        event.preventDefault()

        this.swagger.saveMechanic(this.state)
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col md="4">
                        <Form.Control
                            disabled={!this.state?.active}
                            value={this.state?.name}
                            onChange={(event) => this.setState({ name: event.target.value })} />
                    </Col>
                    <Col md="4">
                        <Form.Control
                            disabled={!this.state?.active}
                            value={this.state?.surname}
                            onChange={(event) => this.setState({ surname: event.target.value })} />
                    </Col>
                    <Col md="2">
                        <Form.Group>
                            <Form.Check
                                id={"checkbox-".concat(this.state?.id)}
                                label="Est actif"
                                checked={this.state?.active}
                                onChange={(event) => this.setState({ active: event.target.checked })} />
                        </Form.Group>
                    </Col>
                    <Col md="2">
                        <Button type="submit">Enregistrer</Button>
                    </Col>
                </Row>
            </Form>
        )
    }
}