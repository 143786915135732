import { Component } from "react";
import ClientStatsApi from "../swagger/ClientStatsApi";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import StatisticsHelper from "./StatisticsHelper";

export default class StatisticsShop extends Component {

    statsApi = new ClientStatsApi()
    helper = new StatisticsHelper()

    options = {
        plugins: {
          title: {
            display: true,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };

    startDate = new Date(new Date().getFullYear() - 1, 0, 2);
    state = {
        requestGeneral: {
            startDate: new Date(this.startDate).toISOString().slice(0, 10),
            endDate: new Date().toISOString().slice(0, 10)
        }
    }

    componentDidMount() {
        this.showStats(this.state.requestGeneral, this.state?.intervalGeneral);
    }

    handleGeneralStartDate = (event) => {
        var state = this.state
        state.requestGeneral.startDate = event.target.value
        this.setState(state)
    }

    handleGeneralEndDate = (event) => {

        var state = this.state
        state.requestGeneral.endDate = event.target.value
        this.setState(state)
    }

    handleGeneralInterval = (event) => {
        var state = this.state
        state.intervalGeneral = event.target.value
        this.setState(state)
    }

    afficherGeneralStats = (event) => {
        this.showStats(this.state.requestGeneral, this.state?.intervalGeneral, "gen");
    }

    async showStats(request, interval) {
        await this.statsApi.retrieveStats(request)
            .then(res => {
                const datas = this.helper.aggregatePrices(res.data, interval);
                var futureState = this.state;

                futureState.barChartGeneral = {
                    labels: datas.map(data => data.interval + "(" + (data.totalPrice1 + data.totalPrice2) + "€)"),
                    datasets: [
                        {
                            label: 'Main Oeuvre',
                            data: datas.map(data => data.totalPrice1),
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                            label: 'Pièces',
                            data: datas.map(data => data.totalPrice2),
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                    ],
                };

                this.setState(futureState);
            });
    }

    render() {
        return <Card>
            <Card.Header>
                <h2>Bilan de l'atelier</h2>
                <Row>
                    <Col>
                        <Form.Select
                            onChange={this.handleGeneralInterval}
                            value={this.state?.generalInterval}>
                            <option value="annuel">Annuel</option>
                            <option value="monthly" selected>Mensuel</option>
                            <option value="weekly">Hebomadaire</option>
                            <option value="daily">Journalier</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Control type="date"
                            value={this.state?.requestGeneral?.startDate}
                            onChange={this.handleGeneralStartDate}
                        />
                    </Col>
                    <Col>
                        <Form.Control type="date"
                            value={this.state?.requestGeneral?.endDate}
                            onChange={this.handleGeneralEndDate}
                        />
                    </Col>
                    <Col>
                        <Button type="button" onClick={this.afficherGeneralStats}>Afficher</Button>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>

                {this.state?.barChartGeneral !== undefined ? <Bar data={this.state?.barChartGeneral} options={this.options} ></Bar> : <></>}

            </Card.Body>
        </Card>
    }

}