import { Component } from "react";
import { Alert, Table } from "react-bootstrap";
import { withRouter } from "react-router";


class FolderDetailCashing extends Component {

    state = {}

    componentDidMount() {
        this.setState({ detail: this.props.detail })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ detail: nextProps.detail })
    }

    render() {
        return <Table>
            <thead>
                <tr>
                    <th>
                        date intervention
                    </th>
                    <th>
                        date encaissement
                    </th>
                    <th>

                    </th>
                    <th className="text-align-right table-info" style={{ width: "50px" }}>
                        MO
                    </th>
                    <th className="text-align-right table-info" style={{ width: "50px" }}>
                        pièce
                    </th>
                    <th className="text-align-right table-info" style={{ width: "50px" }}>
                        Total
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                        Actions
                    </th>
                </tr>
            </thead>

            <tbody>
                {this.state?.detail?.cashing !== null ?
                    <tr>
                        <td>
                            {this.state?.detail?.cashing?.interventionDate !== undefined ? new Intl.DateTimeFormat("fr-FR", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                            }).format(new Date(this.state.detail?.cashing?.interventionDate)) : ""}
                        </td>
                        <td>
                            {this.state?.detail?.cashing?.cashingDate !== undefined ? new Intl.DateTimeFormat("fr-FR", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                            }).format(new Date(this.state.detail?.cashing?.cashingDate)) : ""}
                        </td>
                        <td className="text-align-right">
                        </td>
                        <td className="text-align-right table-info">
                            {this.state.detail?.cashing?.amountCashingLabour}
                        </td>
                        <td className="text-align-right table-info">
                            {this.state.detail?.cashing?.amountCashingPieces}
                        </td>
                        <td className="text-align-right table-info">
                            <b>
                                {this.state.detail?.cashing?.amountCashingPieces + this.state.detail?.cashing?.amountCashingLabour}
                            </b>
                        </td>
                        <td>

                        </td>
                    </tr>
                    :
                    <tr>
                        <td colSpan="100">
                            <Alert variant="warning">
                                <p><b>Pas d'encaissement</b></p>
                            </Alert>
                        </td>
                    </tr>}
            </tbody>
        </Table>

    }

}
export default withRouter(FolderDetailCashing)