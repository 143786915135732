import { Component } from "react";
import { Container, Card, Button, Row, Col, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import BikeSelector from "./BikeSelector";
import Products from "./Products";
import ProductsFullList from "./ProductsFullList";
import ClientBillingApi from "./swagger/ClientBillingApi";

export default class ProductManager extends Component {
    swagger = new ClientBillingApi()

    families = []

    state = {
        value: undefined,
        name: undefined,
        showProductList: false,
        families: []
    }

    componentDidMount() {

        this.swagger.retrieveMainFamilies()
            .then(resFamilies => {
                resFamilies.data.forEach((fam) => {
                    this.swagger.retrieveProductForFamily(fam.id, "name")
                        .then(res => {
                            this.families.push({
                                id: fam.id,
                                name: fam.name,
                                products: res.data
                            })
                        })
                })
            })

    }


    handleChangeValue = (value, name) => {
        this.setState({ value: value, name: name })
    }

    handleShowList = async () => {
        var actualState = this.state
        actualState.showProductList = true
        this.setState(actualState)
    }

    render() {
        return (<>
            <Modal size="lg" show={this.state.showProductList} onHide={() => {
                var state = this.state
                state.showProductList = false
                this.setState(state)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Validation du formulaire</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProductsFullList ref={el => (this.componentRef = el)} families={this.families} />
                </Modal.Body>
                <Modal.Footer>
                    <ReactToPrint
                        trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return <Button type="button"
                                title="Imprimer le devis">
                                Imprimer
                            </Button>;
                        }}
                        content={() => this.componentRef}
                    />
                </Modal.Footer>
            </Modal>


            <Container>


                <h1>Gestion des produits</h1>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            <Row>
                                <Col xs="6">
                                    <h2>Famille de produits</h2>
                                </Col>
                                <Col xs="6">
                                    <Button onClick={this.handleShowList}>Liste</Button>
                                </Col>
                            </Row>
                        </Card.Title>
                    </Card.Header>
                    <BikeSelector
                        value={this.state.value}
                        onChangeValue={this.handleChangeValue}
                    />
                    <Products idFamily={this.state.value} familyName={this.state.name} />
                </Card>
            </Container>
        </>
        );
    }
}
