import { Component } from "react";
import { Badge, Button, Card, Col, Container, Form, InputGroup, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import ClientBillingApi from "../swagger/ClientBillingApi";

import envelopefillsvg from "../../images/envelope-fill.svg";
import envelopefillstacksvg from "../../images/mail-stack.svg";
import DateUtils from "../../components/utils/DateUtils";
import RSBillCell from "./RSBillCell";
import BillValidator from "./BillValidator";
import RSFeedbackCell from "./RSFeedbackCell";
import RSFeedbackHeadCell from "./RSFeedbackHeadCell";


class RepairShop extends Component {

    billValidator = new BillValidator()
    dateUtils = new DateUtils()
    swagger = new ClientBillingApi()
    page = 0
    filter = 0

    state = {
        filters: {
            selectedFilter: 0,
            enableDateSelector: false,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.loadMore);

        this.page = 0
        this.swagger.getPaginatedFoldersFiltered(0, 0)
            .then(res => {
                this.setState({ folders: res.data })
            })
            .catch(error => { alert(error.response.data.message) })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.loadMore);
    }

    loadMore = () => {
        if (window.innerHeight + document.documentElement.scrollTop > document.scrollingElement.scrollHeight - 2) {
            this.page++
            //alert(this.page)
            this.swagger.getPaginatedFoldersFiltered(this.page, this.filter, this.state.filters.startDate, this.state.filters.endDate)
                .then(res => {
                    var folders = this.state.folders
                    folders.push(...res.data)
                    this.setState({ folders: folders })
                })
                .catch(error => { alert(error.response.data.message) })
        }
    }

    showBadge(request) {
        if (request.bill !== undefined && request.bill !== null) {
            return <Badge className="bg-success">Facture</Badge>
        }
        else if (request.acceptedSubmission?.denied === -1) {
            return <Badge className="bg-danger">Refusé</Badge>
        }
        else if (request.acceptedSubmission !== undefined && request.acceptedSubmission !== null) {
            return <Badge className="bg-info">Devis A</Badge>
        }
        else if (request.submission !== undefined && request.submission !== null) {
            return <Badge className="bg-warning">Devis</Badge>
        }
        else {
            return <Badge className="bg-dark">à faire</Badge>
        }
    }

    handleFilterStartDate = (event) => {
        var state = this.state
        state.filters.startDate = event.target.value
        state.filters.endDate = state.filters.startDate

        this.setState(state)
    }

    handleFilterStartDateAddDays = (days) => {
        var state = this.state
        var date = state.filters.startDate !== undefined ? new Date(state.filters.startDate) : new Date()
        date.setHours(3) // pour palier au changement d'heure on défini à 3heures du matin
        date.setDate(date.getDate() + days)
        state.filters.startDate = date.toISOString().substring(0, 10)
        state.filters.endDate = state.filters.startDate

        this.setState(state)
        this.filterTable()
    }

    handleFilterEndDate = (event) => {
        var state = this.state
        state.filters.endDate = event.target.value
        this.setState(state)
    }

    handleFilter = (event) => {

        this.filter = event.target.value

        if (parseInt(event.target.value) === 7) {
            this.setState({
                filters: {
                    enableDateSelector: true
                }
            })
        }
        else {
            this.setState({
                filters: {
                    startDate: "",
                    endDate: "",
                    enableDateSelector: false,
                }
            })
        }

    }

    filterTable = () => {
        this.swagger.getPaginatedFoldersFiltered(0, this.filter, this.state.filters.startDate, this.state.filters.endDate)
            .then(res => {
                var filters = this.state.filters
                filters.selectedFilter = parseInt(this.filter)
                this.setState({ folders: res.data, filters: filters })
            })
            .catch(error => { this.setState({ modalDetail: { show: true, title: "Erreur dans les données retournées", body: error.response.data.message } }) })
    }

    sendMailBikeInReminder = (index) => {
        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours",
                body: "..."
            }
        })
        this.swagger.sendBikeInReminder(this.state?.folders[index]?.folder.id)
            .then(res => {
                var folders = this.state.folders
                folders[index].folder = res.data
                this.setState({ folders: folders, modalDetail: { show: true, title: "Envoie réussie", body: "Le mail a été envoyé" } })
            })
            .catch(error => this.setState({ modalDetail: { show: true, title: "Echec de l'envoie", body: error.message } }))
    }

    sendTheMailBikeIn = (index) => {
        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours",
                body: "..."
            }
        })
        this.swagger.sendBikeIn(this.state?.folders[index]?.folder.id)
            .then(res => {
                var folders = this.state.folders
                folders[index].folder = res.data
                this.setState({ folders: folders, modalDetail: { show: true, title: "Envoie réussie", body: "Le mail a été envoyé" } })
            })
            .catch(error => this.setState({ modalDetail: { show: true, title: "Echec de l'envoie", body: error.message } }))
    }

    sendTheMailBikeOut = (index) => {
        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours",
                body: "..."
            }
        })
        this.swagger.sendBikeOut(this.state?.folders[index]?.folder.id)
            .then(res => {
                var folders = this.state.folders
                folders[index].folder = res.data
                this.setState({ folders: folders, modalDetail: { show: true, title: "Envoie réussie", body: "Le mail a été envoyé" } })
            })
            .catch(error => this.setState({ modalDetail: { show: true, title: "Echec de l'envoie", body: error.message } }))
    }

    sendMailFeedback = (index) => {
        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours",
                body: "..."
            }
        })
        this.swagger.sendFeedback(this.state?.folders[index]?.folder.id)
            .then(res => {
                var folders = this.state.folders
                folders[index].folder = res.data
                this.setState({ folders: folders, modalDetail: { show: true, title: "Envoie réussie", body: "Le mail a été envoyé" } })
            })
            .catch(error => this.setState({ modalDetail: { show: true, title: "Echec de l'envoie", body: error.message } }))
    }

    sendAllSAVMail = () => {
        var message = []

        this.state.folders.forEach((request) => {
            if (this.dateUtils.toReadableDate(request.folder?.feedbackMail) === ""
                && request?.cashing?.paymentMethod?.label !== "GRATUIT"
                && request?.cashing?.cashingDate !== null
                && Date.now() - 10 * 24 * 3600 * 1000 >= new Date(request?.cashing?.cashingDate).getTime()
                && Date.now() - 40 * 24 * 3600 * 1000 <= new Date(request?.cashing?.cashingDate).getTime()) {
                message.push(<p>Envoie du message à {request.folder.customer.name} {request.folder.customer.surname} </p>)
            }
            else {
                message.push(<p>Envoie du message à {request.folder.customer.name} {request.folder.customer.surname} <Badge className="bg-warning">Pas d'envoi</Badge></p>)
            }
        })

        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours",
                body: message
            }
        })
        var folders = this.state.folders

        this.state.folders.forEach((request, index) => {
            if (this.dateUtils.toReadableDate(request.folder?.feedbackMail) === ""
                && request?.cashing?.paymentMethod?.label !== "GRATUIT"
                && request?.cashing?.cashingDate !== null
                && Date.now() - 10 * 24 * 3600 * 1000 >= new Date(request?.cashing?.cashingDate).getTime()
                && Date.now() - 40 * 24 * 3600 * 1000 <= new Date(request?.cashing?.cashingDate).getTime()) {
                this.swagger.sendFeedback(request.folder.id)
                    .then(res => {
                        folders[index].folder = res.data
                        message[index] = <p>Envoie du message à {this.state.folders[index].folder.customer.name} {this.state.folders[index].folder.customer.surname} <Badge className="bg-success">Envoyé !!</Badge></p>
                        this.setState({
                            folders: folders,
                            modalDetail: {
                                show: true,
                                title: "Envoie en cours",
                                body: message.length === 0 ? "Pas de mail à envoyer" : message
                            }
                        })
                    })
                    .catch(error => {
                        message[index] = <p>Envoie du message à  {this.state.folders[index].folder.customer.name} {this.state.folders[index].folder.customer.surname} <Badge className="bg-alert"> Erreur !!</Badge></p>
                        this.setState({
                            folders: folders,
                            modalDetail: {
                                show: true,
                                title: "Envoie en cours",
                                body: message.length === 0 ? "Pas de mail à envoyer" : message
                            }
                        })
                    })
            }
        })

    }

    sendMailFeedbackSixMonth = (index) => {
        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours",
                body: "..."
            }
        })
        this.swagger.sendFeedbackSixMonth(this.state?.folders[index]?.folder.id)
            .then(res => {
                var folders = this.state.folders
                folders[index].folder = res.data
                this.setState({ folders: folders, modalDetail: { show: true, title: "Envoie réussie", body: "Le mail a été envoyé" } })
            })
            .catch(error => this.setState({ modalDetail: { show: true, title: "Echec de l'envoie", body: error.response.data } }))
    }


    sendAllSixMonthMail = () => {
        var message = []

        this.state.folders.forEach((request) => {
            if (this.dateUtils.toReadableDate(request.folder?.feedbackMailSixMonth) === ""
                && request?.cashing?.paymentMethod?.label !== "GRATUIT"
                && request?.cashing?.cashingDate !== null
                && Date.now() - 180 * 24 * 3600 * 1000 >= new Date(request?.cashing?.cashingDate).getTime()
                && Date.now() - 220 * 24 * 3600 * 1000 <= new Date(request?.cashing?.cashingDate).getTime()) {
                message.push(<p>Envoie du message à {request.folder.customer.name} {request.folder.customer.surname} </p>)
            }
            else {
                message.push(<p>Envoie du message à {request.folder.customer.name} {request.folder.customer.surname} <Badge className="bg-warning">Pas d'envoi</Badge></p>)
            }
        })

        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours",
                body: message
            }
        })

        var folders = this.state.folders

        this.state.folders.forEach((request, index) => {
            if (this.dateUtils.toReadableDate(request.folder?.feedbackMailSixMonth) === ""
                && request?.cashing?.paymentMethod?.label !== "GRATUIT"
                && request?.cashing?.cashingDate !== null
                && Date.now() - 180 * 24 * 3600 * 1000 >= new Date(request?.cashing?.cashingDate).getTime()
                && Date.now() - 220 * 24 * 3600 * 1000 <= new Date(request?.cashing?.cashingDate).getTime()) {
                this.swagger.sendFeedbackSixMonth(request.folder.id)
                    .then(res => {
                        folders[index].folder = res.data
                        message[index] = <p>Envoie du message à {this.state.folders[index].folder.customer.name} {this.state.folders[index].folder.customer.surname} <Badge className="bg-success">Envoyé !!</Badge></p>
                        this.setState({
                            folders: folders,
                            modalDetail: {
                                show: true,
                                title: "Envoie en cours",
                                body: message.length === 0 ? "Pas de mail à envoyer" : message
                            }
                        })
                    })
                    .catch(error => {
                        message[index] = <p>Envoie du message à  {this.state.folders[index].folder.customer.name} {this.state.folders[index].folder.customer.surname} <Badge className="bg-alert"> Erreur !!</Badge></p>
                        this.setState({
                            folders: folders,
                            modalDetail: {
                                show: true,
                                title: "Envoie en cours",
                                body: message.length === 0 ? "Pas de mail à envoyer" : message
                            }
                        })
                    })
            }
        })

    }

    sendAllBillMail = () => {

        var messageBillSent = []

        this.state?.folders.forEach((request, index) => {
            if (this.billValidator.canSendTheBill(request)) {
                messageBillSent.push(<p>Envoie du message à {request.bill.headerContact}</p>)
            }
            else {
                messageBillSent.push(<p>Envoie du message à {request.folder.customer.name} {request.folder.customer.surname} <Badge className="bg-warning">Pas d'envoi</Badge></p>)
            }
        })

        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours",
                body: messageBillSent
            }
        })

        var folders = this.state.folders

        this.state?.folders.forEach((request, index) => {
            if (this.billValidator.canSendTheBill(request)) {
                this.swagger.sendTheBill(request.folder.id)
                    .then(res => {
                        folders[index].bill = res.data
                        messageBillSent[index] = <p>Envoie du message à {folders[index].bill.headerContact} <Badge className="bg-success">Envoyé !!</Badge></p>
                        this.setState({
                            folders: folders,
                            modalDetail: {
                                show: true,
                                title: "Envoie en cours",
                                body: messageBillSent.length === 0 ? "Pas de mail à envoyer" : messageBillSent
                            }
                        })
                    })
                    .catch(error => {
                        messageBillSent[index] = <p>Envoie du message à  {this.state.folders[index].folder.customer.name} {this.state.folders[index].folder.customer.surname} <Badge className="bg-alert"> Erreur !!</Badge></p>
                        this.setState({
                            folders: folders,
                            modalDetail: {
                                show: true,
                                title: "Envoie en cours",
                                body: messageBillSent.length === 0 ? "Pas de mail à envoyer" : messageBillSent
                            }
                        })
                    })
            }
        })
    }


    sendAllBikeOutMail = () => {

        var messageBillSent = []

        this.state?.folders.forEach((request, index) => {
            if (request.bill !== null
                && request.bill !== undefined
                && (request.folder?.bikeOut === undefined || request.folder?.bikeOut === null)
                && request.cashing !== null
                && request.cashing !== undefined) {
                messageBillSent.push(<p>Envoie du message à {request.bill.headerContact}</p>)
            }
            else {
                messageBillSent.push(<p>Envoie du message à {request.folder.customer.name} {request.folder.customer.surname} <Badge className="bg-warning">Pas d'envoi</Badge></p>)
            }
        })

        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours : Sortie Vélo",
                body: messageBillSent
            }
        })

        var folders = this.state.folders

        this.state?.folders.forEach((request, index) => {
            if (request.bill !== null
                && request.bill !== undefined
                && (request.folder?.bikeOut === undefined || request.folder?.bikeOut === null)
                && request.cashing !== null
                && request.cashing !== undefined) {
                this.swagger.sendBikeOut(request.folder.id)
                    .then(res => {
                        folders[index].folder = res.data
                        messageBillSent[index] = <p>Envoie du message à {folders[index].bill.headerContact} <Badge className="bg-success">Envoyé !!</Badge></p>
                        this.setState({
                            folders: folders,
                            modalDetail: {
                                show: true,
                                title: "Envoie en cours : Sortie Vélo",
                                body: messageBillSent.length === 0 ? "Pas de mail à envoyer" : messageBillSent
                            }
                        })
                    })
                    .catch(error => {
                        messageBillSent[index] = <p>Envoie du message à  {this.state.folders[index].folder.customer.name} {this.state.folders[index].folder.customer.surname} <Badge className="bg-alert"> Erreur !!</Badge></p>
                        this.setState({
                            folders: folders,
                            modalDetail: {
                                show: true,
                                title: "Envoie en cours : Sortie Vélo",
                                body: messageBillSent.length === 0 ? "Pas de mail à envoyer" : messageBillSent
                            }
                        })
                    })
            }
        })
    }

    renderSubmissionCell(request) {

        var emailSubmissionDate = request.submission?.emailSentDate
        var hasNoValidation = request.acceptedSubmission == null
            && Date.now() - 4 * 3600 * 1000 > new Date(emailSubmissionDate).getTime()

        var bg = hasNoValidation ?
            <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        Envoyé depuis + de 4h
                    </Tooltip>
                }
            >
                <div className="bg-warning">
                    {this.dateUtils.toReadableDate(request.submission?.emailSentDate)}
                </div>
            </OverlayTrigger> : this.dateUtils.toReadableDate(request.submission?.emailSentDate)

        return bg

    }

    renderFeedBackCell(request, index) {
        var cellContent = ""

        var isDisplayable =
            request?.cashing?.paymentMethod?.label !== "GRATUIT"
            && request?.cashing?.cashingDate !== null
            && Date.now() - 10 * 24 * 3600 * 1000 >= new Date(request?.cashing?.cashingDate).getTime()
            && Date.now() - 40 * 24 * 3600 * 1000 <= new Date(request?.cashing?.cashingDate).getTime()

        if (this.dateUtils.toReadableDate(request.folder?.feedbackMail) !== "") {
            cellContent = this.dateUtils.toReadableDate(request.folder?.feedbackMail)
        }
        else if (isDisplayable) {
            cellContent = <Button type="button"
                variant="info"
                onClick={() => this.sendMailFeedback(index)} >
                <img src={envelopefillsvg} alt="envoyer" />
            </Button>
        }

        return cellContent
    }

    renderFeedBackSixMonthCell(request, index) {
        var cellContent = ""

        var isDisplayable = request?.cashing?.paymentMethod?.label !== "GRATUIT"
            && request?.cashing?.cashingDate !== null
            && Date.now() - 180 * 24 * 3600 * 1000 >= new Date(request?.cashing?.cashingDate).getTime()
            && Date.now() - 220 * 24 * 3600 * 1000 <= new Date(request?.cashing?.cashingDate).getTime()

        if (this.dateUtils.toReadableDate(request.folder?.feedbackMailSixMonth) !== "") {
            cellContent = this.dateUtils.toReadableDate(request.folder?.feedbackMailSixMonth)
        }
        else if (isDisplayable) {
            cellContent = <Button type="button"
                variant="info"
                onClick={() => this.sendMailFeedbackSixMonth(index)} >
                <img src={envelopefillsvg} alt="envoyer" />
            </Button>
        }

        return cellContent
    }


    renderBikeOutCell(request, index) {

        return this.dateUtils.toReadableDate(request.folder?.bikeOut) !== "" ?
            this.dateUtils.toReadableDate(request.folder?.bikeOut) :

            <Button
                variant={(request.bill !== null
                    && request.bill !== undefined
                    && request.cashing !== null
                    && request.cashing !== undefined) ? "info" : "secondary"}
                onClick={
                    (request.cashing !== null
                        && request.cashing !== undefined) ? () => this.sendTheMailBikeOut(index) :
                        this.alertEncaissement
                }
                title={(request.bill !== null
                    && request.bill !== undefined
                    && request.cashing !== null
                    && request.cashing !== undefined) ? "Envoyer le mail" : "Pas d'encaissement ou de facture"}
            >
                <img src={envelopefillsvg} alt="envoyer" />
            </Button>

    }

    render() {
        return <Container fluid="fluid">
            <Card>
                <Card.Header>
                    Liste des derniers dossiers
                </Card.Header>

                <Table striped hover>
                    <thead className="sticky-head">
                        <tr>
                            <th colSpan={3}>
                                <Form.Select
                                    onChange={this.handleFilter}
                                >
                                    <option value={0}>-- Pas de filtre --</option>
                                    <option disabled className="bg-info">Filtres avec date</option>
                                    <option value={7}>Encaissement du</option>
                                    <option disabled className="bg-info">Filtres sans date</option>
                                    <option value={9}>Vélo non déposé</option>
                                    <option value={1}>Devis non envoyé</option>
                                    <option value={2}>Devis non validé</option>
                                    <option value={3}>Facture non créée</option>
                                    <option value={4}>Prod non envoyé</option>
                                    <option value={5}>Facture non envoyé</option>
                                    <option value={6}>SAV 10-40 jours non envoyé</option>
                                    <option value={10}>Mail avis google</option>
                                    <option value={8}>Mail 6 mois non envoyé</option>
                                </Form.Select>
                            </th>
                            <th colSpan={2}>
                                <InputGroup>
                                    <Button
                                        disabled={!this.state.filters.enableDateSelector}
                                        onClick={() => this.handleFilterStartDateAddDays(-1)}>-1</Button>
                                    <Form.Control type="date"
                                        disabled={!this.state.filters.enableDateSelector}
                                        value={this.state.filters.startDate}
                                        onChange={this.handleFilterStartDate}
                                    />
                                    <Button
                                        disabled={!this.state.filters.enableDateSelector}
                                        onClick={() => this.handleFilterStartDateAddDays(1)}>+1</Button>
                                </InputGroup>
                            </th>
                            <th colSpan={2}>
                                <Form.Control type="date" disabled={!this.state.filters.enableDateSelector}
                                    value={this.state.filters.endDate}
                                    onChange={this.handleFilterEndDate}
                                />
                            </th>
                            <th colSpan={100}>
                                <Button type="button" onClick={this.filterTable}>Filtrer</Button>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                Client
                            </th>
                            <th>
                                Création dossier
                            </th>
                            <th>
                                Relance dépot vélo
                            </th>
                            <th>
                                Vélo déposé
                            </th>
                            <th>
                                Devis Envoyé
                            </th>
                            <th>
                                Devis validé
                            </th>
                            <th>
                                Facture créée
                            </th>
                            <th>
                                Mail Prod.
                            </th>
                            <th>
                                Encaissement
                            </th>
                            <th>
                                Sortie Vélo
                                {this.state.filters.selectedFilter === 7 ?
                                    <div className="float-end">
                                        <Button onClick={this.sendAllBikeOutMail}>
                                            <img width={19} src={envelopefillstacksvg} alt="Envoie tout" />
                                        </Button>
                                    </div> : <></>}
                            </th>
                            <th className="bg-warning col-2">
                                Mail facture
                                {this.state.filters.selectedFilter === 7 ?
                                    <div className="float-end">
                                        <Button onClick={this.sendAllBillMail}>
                                            <img width={19} src={envelopefillstacksvg} alt="Envoie tout" />
                                        </Button>
                                    </div> : <></>}
                            </th>
                            <th>
                                Mail SAV
                                {this.state.filters.selectedFilter === 6 ?
                                    <div className="float-end">
                                        <Button onClick={this.sendAllSAVMail}>
                                            <img width={19} src={envelopefillstacksvg} alt="Envoie tout" />
                                        </Button>
                                    </div> : <></>}
                            </th>
                            <th>
                               <RSFeedbackHeadCell folders={this.state?.folders} filter={this.state?.filters?.selectedFilter} />
                            </th>
                            <th>
                                {this.state.filters.selectedFilter === 8 ?
                                    <div className="float-end">
                                        <Button onClick={this.sendAllSixMonthMail}>
                                            <img width={19} src={envelopefillstacksvg} alt="Envoie tout" />
                                        </Button>
                                    </div> : <></>}
                                Mail 6 mois
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state?.folders?.map((request, index) => (
                            <tr>
                                <td>
                                    <Row>
                                        <Col md={2}>
                                            <Link to={"/dossier/".concat(request.folder?.id)}>{request.folder?.id}</Link><br />
                                            {this.showBadge(request)}
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <b>{request.folder?.customer?.surname} {request.folder?.customer?.name}</b>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {request.folder?.bike?.type}{" "}
                                                    {request.folder?.bike?.practice}{" "}
                                                    {request.folder?.bike?.brand}{" "}
                                                    {request.folder?.bike?.model}{" "}
                                                    {request.folder?.bike?.color}{" "}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </td>
                                <td>
                                    {this.dateUtils.toReadableDate(request.folder?.date)}
                                </td>
                                <td>
                                    {this.dateUtils.toReadableDate(request.folder?.bikeIn) !== ""
                                        || this.dateUtils.toReadableDate(request.folder?.reminderBikeInMail) !== ""
                                        || this.dateUtils.toReadableDate(request.submission?.emailSentDate) !== "" ?
                                        this.dateUtils.toReadableDate(request.folder?.reminderBikeInMail) :
                                        <Button type="button"
                                            variant="info"
                                            onClick={() => this.sendMailBikeInReminder(index)} >
                                            <img src={envelopefillsvg} alt="envoyer" />
                                        </Button>}
                                </td>
                                <td>
                                    {this.dateUtils.toReadableDate(request.folder?.bikeIn) !== "" ?
                                        this.dateUtils.toReadableDate(request.folder?.bikeIn) :
                                        <Button type="button"
                                            variant="info"
                                            onClick={() => this.sendTheMailBikeIn(index)} >
                                            <img src={envelopefillsvg} alt="envoyer" />
                                        </Button>}
                                </td>
                                <td>
                                    {this.renderSubmissionCell(request)}
                                </td>
                                <td>
                                    {this.dateUtils.toReadableDate(request.acceptedSubmission?.date)}
                                </td>
                                <td>
                                    {this.dateUtils.toReadableDate(request.bill?.date)}
                                </td>
                                <td>
                                    {this.dateUtils.toReadableDate(request.prod?.recoSendDate)}
                                </td>
                                <td>
                                    {this.dateUtils.toReadableDate(request.cashing?.cashingDate)}
                                </td>
                                <td>
                                    {this.renderBikeOutCell(request, index)}
                                </td>
                                <td className="table-warning">
                                    <RSBillCell request={request} index={index} />
                                </td>
                                <td>
                                    {this.renderFeedBackCell(request, index)}
                                </td>
                                <td>
                                    <RSFeedbackCell request={request} index={index} />
                                </td>
                                <td>
                                    {this.renderFeedBackSixMonthCell(request, index)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card>
            <Modal show={this.state?.modalDetail?.show}
                onHide={() => this.setState({ modalDetail: { show: false } })}>
                <Modal.Header closeButton>
                    {this.state?.modalDetail?.title}
                </Modal.Header>
                <Modal.Body>
                    {this.state?.modalDetail?.body}
                </Modal.Body>
            </Modal>
        </Container>
    }
}
export default withRouter(RepairShop)