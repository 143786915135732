import { Component } from "react";
import { Accordion, Alert, Badge, Card, Col, Row } from "react-bootstrap";
import { withRouter } from "react-router";
import Picture from "./Picture";
import ClientBillingApi from "./swagger/ClientBillingApi";
import Compress from "react-image-file-resizer";
import FolderDetailSubmission from "./FolderDetailSubmission";
import FolderDetailAccSub from "./FolderDetailAccSub";
import FolderDetailBill from "./FolderDetailBill";
import FolderDetailCashing from "./FolderDetailCashing";
import { Link } from "react-router-dom";
import CustomerBikeDetails from "./CustomerBikeDetails";


class FolderDetail extends Component {

    swagger = new ClientBillingApi()

    state = {}

    componentWillReceiveProps(nextProps) {

        if (this.state.detail !== nextProps.folder) {

            this.swagger.pictureExistsForFolder(nextProps.folder?.folder?.id).then(res => {
                this.setState({
                    detail: nextProps.folder,
                    bikeHistory: nextProps.bikeHistory,
                    hasPicture: res.data
                })
                if (res.data === true) {
                    this.swagger.getPicturesForFolder(nextProps.folder?.folder?.id)
                        .then(res => {
                            this.setState({
                                detail: nextProps.folder,
                                bikeHistory: nextProps.bikeHistory,
                                images: res.data
                            })
                        })
                        .catch(error => alert(error))
                }
            })


        }
    }

    sendTheBill = () => {
        this.swagger.sendTheBill(this.state?.detail?.folder.id)
            .then(() => alert("Message Envoyé"))
            .catch(error => alert(error.message))
    }

    showBagde() {
        var badge = <Badge className="bg-dark">à faire</Badge>
        if (this.state.detail?.bill !== undefined && this.state.detail?.bill !== null) {
            badge = <Badge className="bg-success">Facture Créée</Badge>
        }
        else if (this.state.detail?.acceptedSubmission?.denied === -1) {
            badge = <Badge className="bg-danger">Devis refusé : {this.state.detail?.acceptedSubmission?.deniedMotive}</Badge>
        }
        else if (this.state.detail?.acceptedSubmission !== undefined && this.state.detail?.acceptedSubmission !== null) {
            badge = <Badge className="bg-info">Devis Accepté</Badge>
        } else if (this.state.detail?.submission !== undefined && this.state.detail?.submission !== null) {
            badge = <Badge className="bg-warning">Devis créé</Badge>
        }

        return badge
    }

    handleChangeValue = (detail) => {
        this.setState({
            detail: detail
        })
    }


    onFileResize = e => {
        const file = e.target.files[0];

        Compress.imageFileResizer(
            file, // the file from input
            480, // width
            480, // height
            "JPEG", // compress format WEBP, JPEG, PNG
            70, // quality
            0, // rotation
            (uri) => {
                console.log(uri);
                uri = uri
                    .replace("data:image/png;base64,", "")
                    .replace("data:image/jpeg;base64,", "")
                var submission = {
                    folderId: this.state?.detail?.folder.id,
                    mainImage: {
                        name: "02_VOTRE-VELO.jpg",
                        data: uri
                    },
                    closeImages: [],
                    yellowFile: [],
                    repairedImage: null
                }

                var details = this.state?.detail;

                this.swagger.createSubmission(submission)
                    .then(() => {
                        this.setState({
                            detail: details,
                            images: submission,
                            hasPicture: true
                        })
                    })
                    .catch(error => { alert("Erreur survenu à l'enregistrement de l'image") })

                // You upload logic goes here
            },
            "base64" // blob or base64 default base64
        );
    }

    render() {
        if (this.state?.hasPicture === undefined) {
            return <Alert variant="info">
                <Alert.Heading>
                    Sélectionner un dossier pour commencer...
                </Alert.Heading>
            </Alert>
        }
        else if (!this.state?.hasPicture) {
            return <Alert variant="warning">
                <Alert.Heading>
                    Ce dossier ne comporte pas de photo !
                </Alert.Heading>
                <p>Avant de démarrer ce dossier, il est impératif de prendre une photo du vélo</p>
                <hr />
                Il est aussi possible d'importer la photo principal du vélo ici
                <input
                    type="file"
                    id="file"
                    accept="image/*"
                    onChange={this.onFileResize}
                />
            </Alert>
        }
        else if (this.state.detail !== undefined) {
            return (
                <>
                    <Row>
                        <Col md={8}>
                            <Card.Title>
                                <h2>Dossier n°{this.state.detail?.folder?.id}{" "}{this.showBagde()}</h2>
                            </Card.Title>
                        </Col>
                        <Col>
                            <div className="float-end">
                                {this.state.detail?.folder?.date !== undefined ? new Intl.DateTimeFormat("fr-FR", {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                }).format(new Date(this.state.detail?.folder?.date)) : ""}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <b>{this.state.detail?.folder?.customer?.surname} {this.state.detail?.folder?.customer?.name}</b>
                            {" "}<Badge className="bg-dark">
                                <Link to={"/client/".concat(this.state.detail?.folder?.customer?.id)}>{this.state.detail?.folder?.customer?.id}</Link>
                            </Badge>
                            <p>{this.state.detail?.folder?.customer?.addressFirstLine}
                                <br />{this.state.detail?.folder?.customer?.zipCode} {this.state.detail?.folder?.customer?.city}</p>
                            <p>{this.state.detail?.folder?.customer?.mail}
                                <br />{this.state.detail?.folder?.customer?.phoneNumber}</p>
                        </Col>
                        <Col>
                            {this.state?.bikeHistory !== undefined ?
                                <>
                                    <Accordion defaultActiveKey={0}>
                                        <CustomerBikeDetails bikeHistory={this.state?.bikeHistory} showChart={false} index={0} />
                                    </Accordion>
                                </> : <Alert variant="danger">Problème sur l'historique</Alert>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card.Title><h3>Devis</h3></Card.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FolderDetailSubmission detail={this.state?.detail} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card.Title><h3>Devis validé par le client</h3></Card.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FolderDetailAccSub detail={this.state?.detail} handleChangeValue={this.handleChangeValue} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card.Title><h3>Facture</h3></Card.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FolderDetailBill detail={this.state?.detail} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card.Title><h3>Encaissement <Badge className="bg-secondary">Logiciel externe</Badge></h3></Card.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FolderDetailCashing detail={this.state?.detail} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card.Title><h3>Photos du dossier</h3></Card.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.state?.images !== undefined ?
                                <Picture images={this.state?.images} /> : ""}
                        </Col>
                    </Row>
                </>
            )
        }
        else {
            return (
                <>
                    Sélectionner un dossier pour commencer
                </>
            )
        }
    }
}
export default withRouter(FolderDetail)