import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import DateUtils from "../utils/DateUtils";
import envelopefillsvg from "../../images/envelope-fill.svg";
import ClientBillingApi from "../swagger/ClientBillingApi";
import BillValidator from "./BillValidator";

export default class RSFeedbackCell extends Component {

    DELAY_MIN = 30
    DELAY_MAX = 50

    dateUtils = new DateUtils()
    swagger = new ClientBillingApi()
    billValidator = new BillValidator()

    componentDidMount() {
        this.setState({
            request: this.props?.request,
            index: this.props?.index
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.request !== this.props?.request) {
            this.setState({
                request: this.props?.request,
                index: this.props?.index
            })
        }
    }

    sendMail = () => {
        this.setState({
            modalDetail: {
                show: true,
                title: "Envoie en cours",
                body: "..."
            }
        })

        this.swagger.sendFeedbackGoogle(this.state?.request?.folder.id)
            .then(res => {
                var request = this.state?.request
                request.folder = res.data 
                this.setState({ request: request, modalDetail: { show: true, title: "Envoie réussie", body: "Le mail a été envoyée" } })
            })
            .catch(error => this.setState({ modalDetail: { show: true, title: "Echec de l'envoie", body: error.message } }))
    }


    render() {
        var request = this.state?.request
        var cellContent = <></>

        var isDisplayable = this.billValidator?.canSendTheFeedbackGoogle(request)
            && this.dateUtils.isDateDelayBetweenMinAndMax(request?.cashing?.cashingDate, this.DELAY_MIN, this.DELAY_MAX)

        if (this.dateUtils.toReadableDate(request?.folder?.feedbackGoogleMail) !== "") {
            cellContent = this.dateUtils.toReadableDate(request?.folder?.feedbackGoogleMail)
        }
        else if (isDisplayable) {
            cellContent = <>
                <Button type="button"
                    variant="info"
                    onClick={() => this.sendMail()} >
                    <img src={envelopefillsvg} alt="envoyer" />
                </Button>
                <Modal show={this.state?.modalDetail?.show}
                    onHide={() => this.setState({ modalDetail: { show: false } })}>
                    <Modal.Header closeButton>
                        {this.state?.modalDetail?.title}
                    </Modal.Header>
                    <Modal.Body>
                        {this.state?.modalDetail?.body}
                    </Modal.Body>
                </Modal>
            </>
        }

        return cellContent
    }
}