import React, { Component } from "react";
import { Card, Container, Row, Col, Table, Button, Modal, Form, InputGroup, Alert } from "react-bootstrap";
import BikeSelector from "./BikeSelector";
import Picture from "./Picture";
import Products from "./Products";

import trashsvg from "../images/trash-fill.svg";
import printsvg from "../images/printer-fill.svg";
import eyesvg from "../images/eye-fill.svg";
import ClientBillingApi from "./swagger/ClientBillingApi";
import BikingGif from "../images/biking.gif";

import ReactToPrint from 'react-to-print';

import { SubPdfViewer } from './SubPdfViewer';
import BackButton from "./utils/BackButton";
import Cookies from "universal-cookie";

// Returns the ISO week of the date.
Date.prototype.getWeek = function () {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
}

export default class Submission extends Component {

    mechanicIdCookie = new Cookies().get("mechanic");

    swagger = new ClientBillingApi()

    state = {
        value: undefined,
        showModalPdf: false,
        showPicture: false,
        showSaveScreenModal: false,
        fileNumber: this.props.match.params.fileId,
        submissionFamilyLines: [],
    }

    componentDidMount() {
        this.swagger.getFolderById(this.state.fileNumber)
            .then(res => {
                let currentDate = new Date();
                var submission = {
                    piece: 0,
                    total: 0,
                    labour: 0,
                    clientId: res.data.folder.customer.id,
                    bikeId: res.data.folder.bike.id,
                    date: currentDate,
                    folder: res.data.folder,
                    duration: 0,
                    weekNumber: currentDate.getWeek(),
                    submissionInfo: {}
                }

                if (res.data.submission !== undefined && res.data.submission !== null) {
                    submission = res.data.submission;
                    submission.clientId = res.data.folder.customer.id;
                    submission.bikeId = res.data.folder.bike.id;

                    submission.lines.sort(function (a, b) {
                        return a.familyName.localeCompare(b.familyName)
                    })

                    if (submission.submissionInfo === undefined || submission.submissionInfo === null) {
                        submission.submissionInfo = {}
                    }
                }

                this.setState({
                    folder: res.data.folder,
                    submission: submission
                })

                this.swagger.retrieveMechanics()
                    .then(res => {

                        res.data.forEach(mec => {
                            if (mec.id === parseInt(this.mechanicIdCookie)
                                && this.submission?.mechanic === undefined) {
                                var submission = this.state.submission
                                submission.mechanic = mec
                                this.setState({ submission: submission })
                            }
                        })

                        this.setState({ mechanics: res.data })
                    })
                    .catch(error => alert(error))
            })
            .catch(error => alert(error))


    }

    handleChangeValue = (value, name) => {
        if (this.state.value !== value || this.state.name !== name) {
            this.setState({ value: value, familyName: name })
        }
    }

    handleSelectProduct = (productId, name, price, labourPrice, familyId, familyName) => {
        var submission = this.state.submission

        if (submission === undefined || submission === null) {
            submission = {
                piece: 0,
                total: 0,
                labour: 0,
            }
        }

        if (submission.lines === undefined || submission.lines === null) {
            submission.lines = []
        }

        submission.lines.push({
            name: name,
            price: price,
            familyId: familyId,
            familyName: familyName,
            labourPrice: labourPrice,
            todo: true
        })

        submission.lines.sort(function (a, b) {
            return a.familyName.localeCompare(b.familyName)
        })

        this.calculTotals(submission)

        this.setState({
            productId: productId,
            submission: submission,
            showModalPdf: false,
        })

        if (productId !== undefined) {
            this.swagger.increaseUsedProductIndex(productId)
                .catch(error => alert(" Index des produits :: " + error))
        }
    }

    calculTotals(submission) {

        submission.total = 0
        submission.piece = 0
        submission.labour = 0

        submission.lines.forEach(element => {
            if (element.todo) {
                submission.total += element.price + element.labourPrice;
                submission.piece += element.price;
                submission.labour += element.labourPrice;
            }
        });
    }

    handleUpdateName = (event, index) => {
        var lines = this.state.submission.lines
        lines[index].name = event.target.value
        this.setState({ submissionLine: lines })
    }

    handleUpdatePrice = (event, index) => {
        var submission = this.state.submission
        submission.lines[index].price = parseFloat(event.target.value)
        this.calculTotals(submission)
        this.setState({ submission: submission })
    }

    handleUpdateLabor = (event, index) => {
        var submission = this.state.submission
        submission.lines[index].labourPrice = this.returnNumberOrUndefined(event.target.value)
        this.calculTotals(submission)
        this.setState({ submission: submission })
    }

    handleCheckTodo = (event, index) => {
        var submission = this.state.submission
        submission.lines[index].todo = event.target.checked
        this.calculTotals(submission)
        this.setState({ submission: submission })
    }

    handleCheckMandatory = (event, index) => {
        var submission = this.state.submission
        submission.lines[index].mandatory = event.target.checked
        this.calculTotals(submission)
        this.setState({ submission: submission })
    }

    handleMechanic = (event) => {
        this.state.mechanics.forEach(mec => {
            if (mec.id === parseInt(event.target.value)) {
                var submission = this.state.submission
                submission.mechanic = mec
                this.setState({ submission: submission })
            }
        })
    }

    handleDuration = (event) => {
        var submission = this.state.submission
        submission.duration = this.returnNumberOrUndefined(event.target.value)
        this.setState({ submission: submission })
    }

    deleteValue = (index) => {
        var submission = this.state.submission
        submission.lines.splice(index, 1)
        this.calculTotals(submission)
        this.setState({ submission: submission })
    }

    saveSubmission = () => {
        this.setState({
            showSaveScreenModal: true,
        })
        this.swagger.saveSubmission(this.state?.submission)
            .then(res => {
                this.setState({
                    submission: res.data,
                    showSaveScreenModal: false,
                });

                this.swagger.saveSubmissionAsPdf(this.state?.fileNumber)
                    .then((response) => {
                        this.props.history.push("/dossier/".concat(this.state?.fileNumber))
                    })
                    .catch(error => alert("Erreur lors de la création du PDF " + error))
            })
            .catch(error => {
                alert(error)
            })
    }

    saveAndQuitSubmission = () => {
        this.setState({
            showSaveScreenModal: true,
        })
        this.swagger.saveSubmission(this.state?.submission)
            .then(res => {
                this.setState({
                    submission: res.data,
                    showSaveScreenModal: false,
                });

                this.swagger.saveSubmissionAsPdf(this.state?.fileNumber)
                    .then((response) => {
                        this.props.history.push("/dossier/".concat(this.state?.fileNumber))
                    })
                    .catch(error => alert("Erreur lors de la création du PDF " + error))
            })
            .catch(error => {
                alert("Erreur lors de l'enregistrement du devis " + error)
            })
    }

    handleInfoObserve = (event) => {
        var submission = this.state.submission
        submission.submissionInfo.observe = event.target.value
        this.setState({ submission: submission })
    }

    handleInfoBikeInfo = (event) => {
        var submission = this.state.submission
        submission.submissionInfo.bikeInfo = event.target.value
        this.setState({ submission: submission })
    }

    handleInfoReadBeforeCall = (event) => {
        var submission = this.state.submission
        submission.submissionInfo.readBeforeCall = event.target.value
        this.setState({ submission: submission })
    }

    returnNumberOrUndefined(number) {
        if (number === "") {
            return undefined
        }
        else {
            return parseFloat(number)
        }
    }

    renderSubmissionLine() {
        if (this.state.submission?.lines?.length > 0) {
            var familyName = ""
            return this.state.submission?.lines?.map((it, index) => (
                <>
                    {familyName !== it.familyName ?
                        <tr className="table-secondary">
                            <td colSpan={100}>
                                <h4>{(familyName = it.familyName).replace("Z-", "")}</h4>
                            </td>
                        </tr> : ""}
                    <tr className={parseFloat(it.price + it.labourPrice) < 0 ? "table-success" : ""}>
                        <td colSpan="3">
                            <Row>
                                <Col lg={8} >
                                    <InputGroup className="mb-1" >
                                        <Button type="button" variant="danger"
                                            onClick={() => this.deleteValue(index)}  >
                                            <img src={trashsvg} alt="supp" />
                                        </Button>
                                        <Form.Control
                                            value={it?.name}
                                            as="textarea"
                                            className={!it?.todo ? "line-through" : ""}
                                            onChange={(event) => this.handleUpdateName(event, index)}

                                        />
                                    </InputGroup>
                                </Col>
                                <Col style={{ paddingLeft: "0em", paddingRight: "0.3em" }}>
                                    <InputGroup >
                                        <Button variant="info" style={{ width: "50%" }} value={it?.labourPrice - 5} onClick={(event) => this.handleUpdateLabor(event, index)}>-5</Button>
                                        <Button variant="info" style={{ width: "50%" }} value={it?.labourPrice + 5} onClick={(event) => this.handleUpdateLabor(event, index)}>+5</Button>
                                    </InputGroup>
                                    <Form.Control
                                        value={it?.labourPrice}
                                        className={!it?.todo ? "text-align-right line-through" : "text-align-right"}
                                        onChange={(event) => this.handleUpdateLabor(event, index)}
                                    />
                                </Col>
                                <Col style={{ paddingLeft: "0em", paddingRight: "0.3em" }}>
                                    <InputGroup >
                                        <Button variant="info" style={{ width: "50%" }} value={it?.price - 5} onClick={(event) => this.handleUpdatePrice(event, index)}>-5</Button>
                                        <Button variant="info" style={{ width: "50%" }} value={it?.price + 5} onClick={(event) => this.handleUpdatePrice(event, index)}>+5</Button>
                                    </InputGroup>
                                    <Form.Control
                                        value={it?.price}
                                        className={!it?.todo ? "text-align-right line-through" : "text-align-right"}
                                        onChange={(event) => this.handleUpdatePrice(event, index)}
                                    />
                                </Col>
                            </Row>
                        </td>
                        <td className="table-danger">
                            <Form.Check
                                variant="danger"
                                checked={it?.mandatory}
                                onChange={(event) => this.handleCheckMandatory(event, index)} />
                        </td>
                        <td className="text-align-right">
                            <Form.Check
                                checked={it?.todo}
                                onChange={(event) => this.handleCheckTodo(event, index)} />
                        </td>
                    </tr>
                </>
            ))
        }
        else {
            return <tr>
                <td colSpan={100}>
                    <Alert variant="warning">
                        <Alert.Heading>
                            Aucune ligne présente pour ce devis
                        </Alert.Heading>
                        <p>
                            Selectionnez via le module de recherche de pièces et main d'oeuvre pour ajouter une ligne
                        </p>
                    </Alert>
                </td>
            </tr>
        }
    }

    render() {
        return (<Container fluid>
            <Row>
                <Col md={5}>
                    <Row style={{
                        position: "fixed", width: "40%"
                    }}>
                        <Col className="small-margin" md={2}>
                            <BackButton
                                url={"/dossier/".concat(this.state?.fileNumber)}
                                name={"Retour au dossier ".concat(this.state?.fileNumber)}
                            />
                        </Col>
                        <Col >
                            <h1>Edition du Devis</h1>
                        </Col>
                    </Row>
                    <Card style={{
                        position: "fixed",
                        width: "40%",
                        height: "80%",
                        overflowY: "scroll",
                        marginTop: "4em"
                    }}>
                        <Card.Header>
                            Recherche pièces et main d 'oeuvres
                        </Card.Header>
                        <Card.Body className="no-padding">
                            <BikeSelector
                                value={this.state.value}
                                onChangeValue={this.handleChangeValue} />
                            <Products
                                idFamily={this.state.value}
                                familyName={this.state.familyName}
                                onChangeValue={this.handleSelectProduct} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>
                                    Devis en cours
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-3">
                                <Col >
                                    <h2>Informations client</h2>

                                    <div>
                                        {this.state.folder?.customer?.name} {this.state.folder?.customer?.surname}
                                    </div>
                                    <div>
                                        {this.state.folder?.customer?.addressFirstLine}
                                    </div>
                                    <div>{this.state.folder?.customer?.zipCode} {this.state.folder?.customer?.city}</div>
                                    <br />
                                    <div>
                                        {this.state.folder?.customer?.mail}
                                    </div>
                                    <div>
                                        {this.state.folder?.customer?.phoneNumber}
                                    </div>
                                </Col>
                                <Col>
                                    <h2>Informations NBS</h2>
                                    <div>
                                        Nantes Bike Solutions
                                    </div>
                                    <div>
                                        9 rue du maréchal Joffre
                                    </div>
                                    <div>44000 Nantes</div>
                                    <br />
                                    <div>
                                        nantesbike@gmail.com
                                    </div>
                                    <div>
                                        02-28-30-72-29
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h2>Titre</h2>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <InputGroup>
                                        <Button type="button" variant="info" size="sm"
                                            onClick={() => this.setState({ showPicture: true })}>photo vélo</Button>{" "}
                                        <Form.Control value={this.state.folder?.bike?.type + " " +
                                            (this.state.folder?.bike?.ebike ? "électrique" : "") + " " +
                                            this.state.folder?.bike?.gender + " " +
                                            this.state.folder?.bike?.practice + " " +
                                            this.state.folder?.bike?.brand + " " +
                                            this.state.folder?.bike?.model + " " +
                                            this.state.folder?.bike?.color} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h2>Détails du devis technique</h2>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col md={6}>
                                    <Form.Label>À surveiller</Form.Label>
                                    <Form.Control as="textarea"
                                        value={this.state?.submission?.submissionInfo?.observe}
                                        onChange={this.handleInfoObserve}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Infos sur le vélo</Form.Label>
                                    <Form.Control as="textarea"
                                        value={this.state?.submission?.submissionInfo?.bikeInfo}
                                        onChange={this.handleInfoBikeInfo}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Form.Label>À lire avant d'appeler l'atelier</Form.Label>
                                    <Form.Control as="textarea"
                                        value={this.state?.submission?.submissionInfo?.readBeforeCall}
                                        onChange={this.handleInfoReadBeforeCall}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        Libellé produit ou prestation
                                    </th>
                                    <th className="text-align-right">
                                        MO
                                    </th>
                                    <th className="text-align-right">
                                        Pièces
                                    </th>
                                    <th className="text-align-right table-danger">
                                        Obl
                                    </th>
                                    <th className="text-align-right">
                                        TAF
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderSubmissionLine()}
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>
                                        Totaux
                                    </td>
                                    <td className="text-align-right">
                                        <b>{this.state?.submission?.labour}</b>
                                    </td>
                                    <td className="text-align-right">
                                        <b>{this.state?.submission?.piece}</b>
                                    </td>
                                    <td colSpan={2} className="text-align-right">

                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={2}>
                                        <b>Somme à payer</b>
                                    </td>
                                    <td className="text-align-right">
                                        <b>{this.state?.submission?.total}</b>
                                    </td>
                                    <td colSpan={2} className="text-align-right">

                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={100}>
                                        <div className="float-end">
                                            <Button type="button" onClick={() => this.handleSelectProduct(undefined, "", 10.0, 0.00, -1, "Z-Autres")}>Ajouter ligne vide</Button>{" "}
                                            <Button type="button" variant="success" onClick={() => this.handleSelectProduct(undefined, "Remise sur prestation", -10.0, 0.00, -2, "Z-Remise")} >Ajouter ligne réduction</Button>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                        <Card.Footer>
                            <Row>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            Tps
                                        </InputGroup.Text>
                                        <Form.Control
                                            onChange={this.handleDuration}
                                            value={this.state.submission?.duration}
                                            style={{ maxWidth: "50px" }} />
                                        <InputGroup.Text>
                                            Par
                                        </InputGroup.Text>
                                        <Form.Select
                                            onChange={this.handleMechanic}
                                            value={this.state.submission?.mechanic?.id}>
                                            <option>-- mécaniciens --</option>
                                            {this.state.mechanics?.map(mecha => (
                                                <option value={mecha.id} disabled={!mecha.active}>{mecha.name} {mecha.surname}</option>
                                            ))}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col md={1}>
                                    <Button type="button" variant="info" onClick={() => this.setState({ showModalPdf: true })}>
                                        <img src={eyesvg} alt="impr" />
                                    </Button>
                                </Col>
                                <Col md={5}>
                                    <InputGroup>
                                        <Button type="button" variant="success" onClick={this.saveSubmission}>Enregistrer</Button>
                                        <Button type="button" variant="warning" onClick={this.saveAndQuitSubmission}>Enreg. quitter</Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            <Modal size="lg" show={this.state.showModalPdf} onHide={() => this.setState({ showModalPdf: false })}>
                <Modal.Header closeButton>
                    Impression du devis
                </Modal.Header>
                <Modal.Body>
                    <SubPdfViewer ref={el => (this.componentRef = el)} submission={this.state?.submission} />
                </Modal.Body>
                <Modal.Footer>
                    <ReactToPrint
                        trigger={() => {
                            return <Button type="button">
                                <img src={printsvg} alt="Imprimer" />
                            </Button>;
                        }}
                        content={() => this.componentRef}
                    />
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={this.state.showPicture} onHide={() => this.setState({ showPicture: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Photo du vélo pour le dossier {this.state.fileNumber}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Picture folderId={this.state.fileNumber} />
                </Modal.Body>
            </Modal>

            <Modal show={this.state.showSaveScreenModal}>
                <Modal.Header>
                    Enregistrement en cours
                </Modal.Header>
                <Modal.Body>
                    <img src={BikingGif} alt="Enregistrement en cours..." />
                </Modal.Body>
            </Modal>

        </Container >
        )
    }
}