import { Component } from "react"
import { Accordion, Badge, Button, Card, Col, Form, ListGroup, Modal, Row, Table } from "react-bootstrap"
import { withRouter } from "react-router-dom"
import ClientCustomerApi from "./swagger/ClientCustomerApi"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import DateUtils from "./utils/DateUtils";
import CustomerCashoutTable from "./CustomerCashoutTable";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class CustomerBikeDetails extends Component {

  dateUtils = new DateUtils()

  options = {
    plugins: {
      title: {
        display: true,
        text: 'Encaissements',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  labels = [];
  datas = {
    labels: this.labels,
    datasets: [],
  };

  state = {
    options: this.options,
    labels: this.labels,
    data: this.datas
  }
  customerSwagger = new ClientCustomerApi()

  componentDidMount() {
    this.setState({ index: this.props.index })
    this.updateChart(this.props.bikeHistory, this.props.customer)
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ index: this.props.index })
      this.updateChart(this.props.bikeHistory, this.props.customer)
    }
  }

  updateChart(bikeHistory, customer) {
    var labels = []

    bikeHistory?.folders
      .forEach((folder) => {
        if (folder?.cashing?.cashingDate !== undefined) {

          var month = this.dateUtils.toMonthYearDate(folder?.cashing?.cashingDate)
          var indexAlreadyInIt = -1

          labels.forEach((label, index) => {
            if (label.month === month) {
              indexAlreadyInIt = index
            }
          })

          if (indexAlreadyInIt !== -1) {
            var moAmount = labels[indexAlreadyInIt].cashout.mo + folder?.cashing?.amountCashingLabour
            var piAmount = labels[indexAlreadyInIt].cashout.pi + folder?.cashing?.amountCashingPieces
            labels[indexAlreadyInIt] = {
              date: folder?.cashing?.cashingDate,
              month: month,
              cashout: {
                mo: moAmount,
                pi: piAmount
              },
              totalAmount: moAmount + piAmount
            }
          }
          else {
            labels.push({
              date: folder?.cashing?.cashingDate,
              month: month,
              cashout: {
                mo: folder?.cashing?.amountCashingLabour,
                pi: folder?.cashing?.amountCashingPieces
              },
              totalAmount: folder?.cashing?.amountCashingLabour + folder?.cashing?.amountCashingPieces
            })
          }

          indexAlreadyInIt = -1
        }

      })

    labels = labels.sort((l1, l2) => new Date(l1.date) - new Date(l2.date))

    var datas = {
      labels: labels.map(label => label.month + " (" + label.totalAmount + "€)"),
      datasets: [
        {
          label: 'MO',
          data: labels.map((label) => label.cashout.mo),
          backgroundColor: 'rgb(255, 99, 132)',
        },
        {
          label: 'Pièces',
          data: labels.map((label) => label.cashout.pi),
          backgroundColor: 'rgb(75, 192, 192)',
        }
      ],
    };

    this.setState({
      customer: customer,
      bikeId: this.props.bikeId,
      bikeHistory: bikeHistory,
      labels: labels,
      data: datas
    })
  }

  createNewFolder = () => {
    this.setState({
      createFolderFromBike: { showModal: true }
    })
  }

  valider = (event) => {
    event.preventDefault()

    var request = {
      customer: this.state.customer,
      bike: this.state.bikeHistory.bike,
      bikeDysfunction: this.state?.createFolderFromBike?.bikeDysfunction,
      dropInfo: this.state?.createFolderFromBike?.dropInfo,
    }

    this.customerSwagger.submitPrefilledForm(request)
    .then(() => {
      var tempState = this.state
      tempState.createFolderFromBike = {showModal : false}
      this.setState(tempState)
    })
    .catch(error => alert(error))
  }

  render() {

    return <Accordion.Item eventKey={this.state?.index}>
      <Accordion.Header>
        <Row>
          <Col>
            <Badge className="bg-dark">{this.state?.bikeHistory?.bike?.id}</Badge>{" "}
            {this.state?.bikeHistory?.bike?.type}{" "}
            {this.state?.bikeHistory?.bike?.ebike ? "électrique " : ""}{" "}
            {this.state?.bikeHistory?.bike?.practice}{" "}
            {this.state?.bikeHistory?.bike?.gender}{" "}
            {this.state?.bikeHistory?.bike?.brand}{" "}
            {this.state?.bikeHistory?.bike?.model}{" "}
            {this.state?.bikeHistory?.bike?.color}{" "}
          </Col>
        </Row>

      </Accordion.Header>
      <Accordion.Body>

        <Modal size="lg" show={this.state?.createFolderFromBike?.showModal} onHide={() => this.setState({ createFolderFromBike: { showModal: false } })}>
          <Form onSubmit={this.valider}>
            <Modal.Header closeButton>
              <Modal.Title>
                Création d'un nouveau dossier pour le vélo {this.state?.bikeHistory?.bike?.id}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <Row>
                    <Col lg>
                      <Card.Title>Quand déposerais-je mon vélo ?</Card.Title>
                      <Form.Control as="select"
                        className="form-select"
                        required
                        value={this.state?.createFolderFromBike?.dropInfo}
                        onChange={(event) => {
                          var createFolderFromBike = this.state?.createFolderFromBike
                          createFolderFromBike.dropInfo = event.target.value
                          this.setState({ createFolderFromBike: createFolderFromBike })
                        }}>

                        <option defaultValue value="">
                          - Sélectionner -
                        </option>
                        <option value="le vélo est déjà à l'atelier">le vélo est déjà à l&apos;atelier</option>
                        <option value="je dépose le vélo aujourd'hui">je dépose le vélo aujourd&apos;hui</option>
                        <option value="je dépose le vélo demain">je dépose le vélo demain</option>
                        <option value="je dépose le vélo dans la semaine">je dépose le vélo dans la semaine</option>
                      </Form.Control>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Card.Title>Dysfonctionnements constatés</Card.Title>
                  <Form.Control
                    as="textarea"
                    required
                    value={this.state?.createFolderFromBike?.bikeDysfunction}
                    onChange={(event) => {
                      var createFolderFromBike = this.state?.createFolderFromBike
                      createFolderFromBike.bikeDysfunction = event.target.value
                      this.setState({ createFolderFromBike: createFolderFromBike })
                    }} />
                </ListGroup.Item>
              </ListGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">Valider</Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Row style={{ display: this.props.showChart === false ? "none" : "" }}>
          <Col md={6}>
            Nombre de passage à l'atelier : {this.state?.bikeHistory?.folders?.length}
          </Col>
          <Col md={6}>
            <Table>
              <tr>
                <td>
                  Dernier encaissement
                </td>
                <td>
                  {this.dateUtils.toReadableDate(this.state?.bikeHistory?.folders[0]?.cashing?.cashingDate)}
                </td>
              </tr>
              <tr>
                <td>
                  Montant Main Oeuvre
                </td>
                <td>
                  {this.state?.bikeHistory?.folders[0]?.cashing?.amountCashingLabour}
                </td>
              </tr>
              <tr>
                <td>
                  Montant Pièces
                </td>
                <td>
                  {this.state?.bikeHistory?.folders[0]?.cashing?.amountCashingPieces}
                </td>
              </tr>
              <tr>
                <td>
                  Montant Total
                </td>
                <td>
                  {this.state?.bikeHistory?.folders[0]?.cashing?.amountCashingLabour + this.state?.bikeHistory?.folders[0]?.cashing?.amountCashingPieces || 0}
                </td>
              </tr>
            </Table>
          </Col>
        </Row>

        <Row className="text-align-right" style={{ display: this.props.showChart === false ? "none" : "" }}>
          <Col>
            <Button type="button" onClick={this.createNewFolder}>
              Créer une demande pour ce vélo
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <CustomerCashoutTable
              cashouts={this.state?.bikeHistory?.folders.filter(folder => folder.cashing !== null).map(folder => folder.cashing)} showAll={this.props.showChart} />
          </Col>
        </Row>


        <Row style={{ display: this.props.showChart === false ? "none" : "" }}>
          <Col>
            {this.state?.data !== undefined ? <Bar options={this.state?.options} data={this.state?.data} /> : <></>}
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>

  }

}
export default withRouter(CustomerBikeDetails)