import { Component } from "react";
import { withRouter } from "react-router";
import backSvg from "../../images/arrow-left-short.svg";
import { Link } from "react-router-dom";

class BackButton extends Component {

    componentWillReceiveProps(nextProps) {

        if (nextProps.url !== this.state?.url) {
            this.setState({
                url: nextProps.url,
                buttonName: nextProps.name
            })
        }
    }

    render() {
        return <Link className="btn btn-primary btn-back" to={this.state?.url}>
            <img src={backSvg} alt="<" />
            <span>
                {this.state?.buttonName}
            </span>
        </Link>
    }
}
export default withRouter(BackButton)