import ClientApi from "./ClientApi";

export default class ClientCustomerApi extends ClientApi {
    retrieveLastsCustomerReceipts(customerId) {
        return this.getAxios("stats/customer-receipts/"
            .concat(customerId))
    }

    retrieveLastsCustomerHistory(customerId) {
        return this.getAxios("stats/customer-history/"
            .concat(customerId))
    }

    retrieveLastsBikeHistory(bikeId) {
        return this.getAxios("stats/bike-history/"
            .concat(bikeId))
    }

    retrievePDFData(customerId, bikeId, cashoutId) {
        return this.getAxios(`billing/archive/scan/${customerId}/${bikeId}/${cashoutId}`)
    }

    retrieveCustomerWithCriteria(request) {
        return this.postAxiosWithBody("stats/customers/", request)
    }

    saveCustomer(customer) {
        return this.postAxiosWithBody("billing/customer/save/", customer)
    }

    saveBike(bike) {
        return this.postAxiosWithBody("billing/bike/save/", bike)
    }

    submitPrefilledForm(request) {
        return this.postAxiosWithBody("billing/customer/form/submit", request)
    }
}