import ClientApi from "./ClientApi";

export default class ClientBillingApi extends ClientApi {
    
  retrieveMainFamilies() {
    return this.getAxios("billing/main-families");
  }

  saveMainFamily(mainFamily) {
    return this.postAxiosWithBody("billing/edit/main-family/save", mainFamily);
  }

  deleteMainFamily(idFamily) {
    return this.postAxiosNoBody("billing/edit/main-family/delete/".concat(idFamily))
  }

  retrieveProductForFamily(familyId, sortColumn) {
    return this.getAxios("billing/products/"
      .concat(familyId)
      .concat("/sort/")
      .concat(sortColumn))
  }

  saveProduct(product) {
    return this.postAxiosWithBody("billing/edit/product/save", product)
  }

  deleteProduct(productId) {
    return this.postAxiosNoBody("billing/edit/product/delete/".concat(productId))
  }

  pictureExistsForFolder(folderId) {
    return this.getAxios("validate/exist/".concat(folderId))
  }

  getPicturesForFolder(folderId) {
    return this.getAxios("validate/".concat(folderId))
  }

  getPaginatedFoldersFiltered(pageNumber, filter, startDate, endDate) {

    var request = "billing/folders/".concat(pageNumber)
      .concat("/filtered/").concat(filter)

    if (startDate !== undefined && startDate !== "") {
      request = request.concat("/date-debut/").concat(startDate)
    }

    if (endDate !== undefined && startDate !== "") {
      request = request.concat("/date-fin/").concat(endDate)
    }

    return this.getAxios(request)
  }

  getFolderById(folderId) {
    return this.getAxios("billing/folders-id/".concat(folderId))
  }

  retrieveMechanics() {
    return this.getAxiosNoHeaders("mechanics/")
  }

  saveMechanic(mechanic) {
    return this.postAxiosWithBody("mechanic/save/", mechanic)
  }

  retrieveMechanic(mechanicId) {
    return this.getAxios("mechanic/".concat(mechanicId))
  }

  saveSubmission(submissionRequest) {
    return this.postAxiosWithBody("billing/submission/save/", submissionRequest)
  }

  deleteSubmissionLine(lineId) {
    return this.postAxiosNoBody("billing/submission/delete-line/".concat(lineId))
  }

  validateSubmission(submissionRequest) {
    return this.postAxiosWithBody("billing/submission/accepted/", submissionRequest)
  }

  createTheBill(submission, duration, difficulties) {
    var request = {
      submission: submission,
      duration: duration,
      encounteredDifficulties: difficulties
    }
    return this.postAxiosWithBody("billing/bill/save/", request)
  }

  saveTheBill(bill, duration, difficulties) {
    var request = {
      bill: bill,
      duration: duration,
      encounteredDifficulties: difficulties
    }
    return this.postAxiosWithBody("billing/bill/update/", request)
  }

  saveBillAsPdf(folderId) {
    return this.postAxiosNoBody("billing/bill/pdf/".concat(folderId))
  }

  saveSubmissionAsPdf(folderId) {
    return this.postAxiosNoBody("billing/submission/pdf/".concat(folderId))
  }

  saveAcceptedSubmissionAsPdf(folderId) {
    return this.postAxiosNoBody("billing/validate-submission/pdf/".concat(folderId))
  }

  sendTheBill(folderId) {
    return this.postAxiosNoBody("billing/bill/send-mail/".concat(folderId))
  }

  sendBikeInReminder(folderId) {
    return this.postAxiosNoBody("billing/bill/mail-relance-depose-velo/".concat(folderId))
  }

  sendBikeIn(folderId) {
    return this.postAxiosNoBody("billing/bill/mail-depose-velo/".concat(folderId))
  }

  sendBikeOut(folderId) {
    return this.postAxiosNoBody("billing/bill/mail-retrait-velo/".concat(folderId))
  }

  sendFeedback(folderId) {
    return this.postAxiosNoBody("billing/bill/mail-sav/".concat(folderId))
  }
  
  sendFeedbackGoogle(folderId) {
    return this.postAxiosNoBody("billing/bill/mail-feedback/".concat(folderId))
  }

  sendFeedbackSixMonth(folderId) {
    return this.postAxiosNoBody("billing/bill/mail-sav-sixmois/".concat(folderId))
  }

  increaseUsedProductIndex(productId) {
    return this.postAxiosNoBody("billing/edit/product/increase/".concat(productId))
  }

  createSubmission(submission) {
    return this.postAxiosWithBody("createSubmission/", submission)
  }

  deleteBill(billId) {
    return this.postAxiosNoBody("billing/bill/delete/".concat(billId))
  }

  deleteCashout(cashoutId) {
    return this.postAxiosNoBody("billing/cahsout/delete/".concat(cashoutId))
  }
}