import React from "react";
import { Alert, Col, Form, Row, Table } from "react-bootstrap";

import petitLogo from "../images/petit-logo.png";

export class SubPdfViewer extends React.PureComponent {


    constructor(props) {
        super(props)
        this.state = { submission: props.submission }
    }

    componentDidMount() {
        
        this.setState({ submisison: this.props.submission })
    }

    renderSubmissionLine() {
        if (this.state?.submission?.lines?.length > 0) {
            var familyName = ""

            this.state.submission?.lines.sort(function (a, b) {
                return a.familyName.localeCompare(b.familyName)
            })

            return this.state?.submission?.lines?.map((it, index) => (
                <>
                    {familyName !== it.familyName ?
                        <tr className="table-secondary">
                            <td colSpan="100">
                                <b>{(familyName = it.familyName).replace("Z-", "")}</b>
                            </td>
                        </tr> : ""}
                    <tr className={parseFloat(it.price + it.labourPrice) < 0 ? "table-success " : " ".concat(!it.todo ? "line-through " : " ")}>
                        <td>
                            {it?.name}
                        </td>
                        <td className="text-align-right">
                            {parseFloat(it?.labourPrice) !== 0 ? parseFloat(it?.labourPrice) : ""}
                        </td>
                        <td className="text-align-right">
                            {parseFloat(it?.price) !== 0 ? parseFloat(it?.price) : ""}
                        </td >
                        <td className="text-align-right">
                            {it?.mandatory? <>&#x2612;</> : <>&#x2610;</>}
                        </td>
                        <td className="text-align-right">
                            &#x2610;
                        </td>
                    </tr >
                </>
            ))
        }
        else {
            return <tr>
                <td colSpan={3}>
                    <Alert variant="warning">
                        <Alert.Heading>
                            Aucune ligne présente pour ce devis
                        </Alert.Heading>
                        <p>
                            Selectionnez via le module de recherche de pièces et main d'oeuvre pour ajouter une ligne
                        </p>
                    </Alert>
                </td>
            </tr>
        }
    }

    rendreTable() {
        if (!(!this.state?.submission?.submissionInfo?.observe && 
            !this.state?.submission?.submissionInfo?.bikeInfo && 
            !this.state?.submission?.submissionInfo?.readBeforeCall)) {
            return <Row className="mb-3">
            <Col>
                <Table bordered size="sm">
                    <thead>
                        <tr className="table-warning">
                            <td style={{ textAlign: "center", width: "50%" }}>
                                <b>À surveiller</b>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <b>Informations vélo</b>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {this.state?.submission?.submissionInfo?.observe}
                            </td>
                            <td>
                                {this.state?.submission?.submissionInfo?.bikeInfo}
                            </td>
                        </tr>

                    </tbody>
                    <thead>
                        <tr className="table-warning">
                            <td colSpan={2} style={{ textAlign: "center" }}>
                                <b>À lire avant d'appeler l'atelier</b>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                {this.state?.submission?.submissionInfo?.readBeforeCall}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
        }
        else {
            return <></>
        }
    }

    render() {
        return (
            <div className="print-container" style={
                {
                    paddingTop: "2em",
                    paddingRight: "1em",
                    paddingLeft: "1em",
                    paddingBottom: "2em",
                    height: "100%",
                    backgroundColor: "white"
                }
            }>
                <Row className="mb-3">
                    <Col>
                        <img src={petitLogo} width="200px" alt="petit-logo"></img>
                    </Col>
                    <Col>
                        SIRET : 819 734 732 00020<br />
                        RNA : 4540Z<br />
                        Nº TVA : FR68819734732
                    </Col>
                    <Col>
                        <div>
                            <b>Nantes Bike Solutions</b>
                        </div>
                        <div>
                            9 rue du maréchal Joffre
                        </div>
                        <div>44000 Nantes</div>
                        <div>
                            nantesbike@gmail.com
                        </div>
                        <div>
                            02-28-30-72-29
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={7}>
                        <Table borderless size="sm">
                            <tbody>
                                <tr>
                                    <td className="text-align-right" style={{ width: "50%" }}>
                                        Devis technique
                                    </td>
                                    <td style={{ fontWeight: "bold" }}>
                                        {this.state?.submission?.id}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-align-right">
                                        Dossier
                                    </td>
                                    <td style={{ fontWeight: "bold" }}>
                                        {this.state?.submission?.folder?.id}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-align-right">
                                        Client
                                    </td>
                                    <td style={{ fontWeight: "bold" }}>
                                        {this.state?.submission?.folder?.customer?.id}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-align-right">
                                        Vélo
                                    </td>
                                    <td style={{ fontWeight: "bold" }}>
                                        {this.state?.submission?.folder?.bike?.id}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col >
                        <div>
                            <b>{this.state?.submission?.folder?.customer?.name} {this.state?.submission?.folder?.customer?.surname}</b>
                        </div>
                        <div>
                            {this.state?.submission?.folder?.customer?.addressFirstLine}
                        </div>
                        <div>{this.state?.submission?.folder?.customer?.zipCode} {this.state?.submission?.folder?.customer?.city}</div>
                        <div>
                            {this.state?.submission?.folder?.customer?.mail}
                        </div>
                        <div>
                            {this.state?.submission?.folder?.customer?.phoneNumber}
                        </div>
                        <br />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <h2>{this.state?.submission?.folder?.bike?.type + " " +
                            (this.state?.submission?.folder?.bike?.ebike ? "électrique " : " ") +
                            this.state?.submission?.folder?.bike?.gender + " " +
                            this.state?.submission?.folder?.bike?.practice + " " +
                            this.state?.submission?.folder?.bike?.brand + " " +
                            this.state?.submission?.folder?.bike?.model + " " +
                            this.state?.submission?.folder?.bike?.color}
                        </h2>
                    </Col>
                </Row>
               {this.rendreTable()}
                <Row className="mb-3">
                    <Col>
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{ width: "60%" }}>Libellé pièces et prestations</th>
                                    <th className="text-align-right">Prix MO</th>
                                    <th className="text-align-right">Prix Pièces</th>
                                    <th className="text-align-right">Obligatoire</th>
                                    <th className="text-align-right">Fait</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderSubmissionLine()}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td className="text-align-right">
                                        <b>{this.state?.submission?.labour}</b>
                                    </td>
                                    <td className="text-align-right">
                                        <b>{this.state?.submission?.piece}</b>
                                    </td>
                                    <td colSpan={2}></td>
                                </tr>
                                <tr style={{ fontWeight: "bolder" }}>
                                    <td>Montant total</td>
                                    <td></td>
                                    <td className="text-align-right">
                                        <b>{this.state?.submission?.total}</b>
                                    </td>
                                    <td colSpan={2}></td>
                                </tr>

                            </tfoot>
                        </Table>
                    </Col>
                </Row>

            </div>
        );
    }
}