export default class DateUtils {

    
    aggregatePrices(data, interval) {
        const totalsByInterval = new Map();

        data.forEach(item => {
            const date = new Date(item.date);
            let intervalKey;

            switch (interval) {

                case 'daily':
                    intervalKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
                    break;
                case 'weekly':
                    const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay() + 1)); // Adjust to start of the week (Monday)
                    intervalKey = `${startOfWeek.getFullYear()}-${String(startOfWeek.getMonth() + 1).padStart(2, '0')}-${String(startOfWeek.getDate()).padStart(2, '0')}`;
                    break;
                case 'annually':
                    intervalKey = `${date.getFullYear()}`;
                    break;
                case 'monthly':
                default:
                    intervalKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
                    break;

            }

            if (!totalsByInterval.has(intervalKey)) {
                totalsByInterval.set(intervalKey, { totalPrice1: 0, totalPrice2: 0 });
            }

            const totals = totalsByInterval.get(intervalKey);
            totals.totalPrice1 += item.labourAmount;
            totals.totalPrice2 += item.piecesAmount;
        });

        // Convert the map to an array
        const resultArray = Array.from(totalsByInterval, ([interval, totals]) => ({
            interval,
            totalPrice1: totals.totalPrice1,
            totalPrice2: totals.totalPrice2,
        }));

        return resultArray;
    }


}